import { FormattedMessage } from 'react-intl'
import React from 'react'

export const DayTranslate = ({ day, short }) => {
  if (short) {
    if (day === 'Mon') { return <FormattedMessage id="Mon" defaultMessage="Mon"/> }
    if (day === 'Tue') { return <FormattedMessage id="tue" defaultMessage="Tue"/> }
    if (day === 'Wed') { return <FormattedMessage id="tue" defaultMessage="Wed"/> }
    if (day === 'Thu') { return <FormattedMessage id="tue" defaultMessage="Thu"/> }
    if (day === 'Fri') { return <FormattedMessage id="tue" defaultMessage="Fri"/> }
    if (day === 'Sat') { return <FormattedMessage id="tue" defaultMessage="Sat"/> }
    if (day === 'Sun') { return <FormattedMessage id="tue" defaultMessage="Sun"/> }
  } else {
    if (day === 'Mon') { return <FormattedMessage id="Mon" defaultMessage="Monday"/> }
    if (day === 'Tue') { return <FormattedMessage id="tue" defaultMessage="Tuesday"/> }
    if (day === 'Wed') { return <FormattedMessage id="tue" defaultMessage="Wednesday"/> }
    if (day === 'Thu') { return <FormattedMessage id="tue" defaultMessage="Thursday"/> }
    if (day === 'Fri') { return <FormattedMessage id="tue" defaultMessage="Friday"/> }
    if (day === 'Sat') { return <FormattedMessage id="tue" defaultMessage="Saturday"/> }
    if (day === 'Sun') { return <FormattedMessage id="tue" defaultMessage="Sunday"/> }
  }
}
export const MonthTranslate = ({ month }) => {
  const monthTranslated = month.split(' ')[0]
  if (monthTranslated === 'Jan') { return <FormattedMessage id="Jan" defaultMessage="Jan"/> }
  if (monthTranslated === 'Feb') { return <FormattedMessage id="Feb" defaultMessage="Feb"/> }
  if (monthTranslated === 'Apr') { return <FormattedMessage id="Apr" defaultMessage="Apr"/> }
  if (monthTranslated === 'May') { return <FormattedMessage id="May" defaultMessage="May"/> }
  if (monthTranslated === 'June') { return <FormattedMessage id="June" defaultMessage="Jun"/> }
  if (monthTranslated === 'July') { return <FormattedMessage id="July" defaultMessage="Jul"/> }
  if (monthTranslated === 'Aug') { return <FormattedMessage id="Aug" defaultMessage="Aug"/> }
  if (monthTranslated === 'Sep') { return <FormattedMessage id="Sep" defaultMessage="Sep"/> }
  if (monthTranslated === 'Oct') { return <FormattedMessage id="Oct" defaultMessage="Oct"/> }
  if (monthTranslated === 'Nov') { return <FormattedMessage id="Nov" defaultMessage="Nov"/> }
  if (monthTranslated === 'Dec') { return <FormattedMessage id="Dec" defaultMessage="Dec"/> }
}
