import React, { useEffect, useState } from 'react'

// src
import { getSponsorAdvertisement } from '../../Pages/Posters/action'

const Sponsor = ({ refreshEvent }) => {
  const updateSponsor = process.env.REACT_APP_TIME_FOR_UPDATE_SPONSOR
  const url = window.location.pathname.split('/')
  const eventId = url[2]

  const [sponsors, setSponsors] = useState()
  const [countSponsor, setCountSponsor] = useState(0)

  useEffect(() => {
    if (countSponsor >= sponsors?.meta?.total_count) {
      setCountSponsor(0)
    }
  }, [countSponsor])
  useEffect(() => {
    getSponsorAdvertisement(eventId).then(value => {
      setSponsors(value.data)
    })
  }, [eventId])

  useEffect(() => {
    if (sponsors && !refreshEvent?.sponsor) {
      const intervalId = setInterval(() => {
        if (countSponsor >= sponsors?.items.length - 1) {
          setCountSponsor(0)
        } else {
          setCountSponsor((prevState) =>
            prevState + 1
          )
        }
      }, updateSponsor)

      return () => {
        clearInterval(intervalId)
      }
    }

    if (countSponsor >= sponsors?.meta?.total_count) {
      setCountSponsor(0)
    }
  }, [sponsors, refreshEvent?.sponsor])
  return (
        <div className="sponsor-logo-add">

            {/* <div className="circle-with-exclamation-mark"> */}
            {/*    <div className="line-sponsor-add"/> */}
            {/*    <div className="circle-sponsor-add"/> */}
            {/* </div> */}

            <div className="sponsor-add-wrap">
                <div

                    onClick={() => {
                      const element = document.createElement('a')
                      element.href = refreshEvent?.sponsor ? refreshEvent.sponsor.url : sponsors?.items[countSponsor]?.url
                      document.body.appendChild(element)
                      element.click()
                    }}
                className="sponsor-header">

                    {/* <div className="text-add"> */}
                    {/*    <div className="sponsor-img-div"> */}
                            <img src={refreshEvent?.sponsor ? refreshEvent?.sponsor.download_url : sponsors?.items[countSponsor]?.image?.download_url}
                                 alt={refreshEvent?.sponsor ? refreshEvent?.sponsor.title : sponsors?.items[countSponsor]?.title}/>
                        {/* </div> */}
                    {/* </div> */}

                    {/* <h3 className="name-add">{sponsors?.items[countSponsor]?.title}</h3> */}
                </div>
                {/* <Button */}
                {/*    onClick={() => { */}
                {/*      const element = document.createElement('a') */}
                {/*      element.href = sponsors?.items[countSponsor]?.url */}
                {/*      document.body.appendChild(element) */}
                {/*      element.click() */}
                {/*    }} */}
                {/*    sx={{ */}
                {/*      width: '160px', */}
                {/*      height: '33px', */}
                {/*      color: 'white', */}
                {/*      borderRadius: '16.5px', */}
                {/*      padding: '11px 18px 8px', */}
                {/*      margin: '18px 0 14px 0', */}
                {/*      background: '#1FD662' */}
                {/*    }} */}
                {/* > */}
                {/*    Visit */}
                {/* </Button> */}
            </div>

        </div>
  )
}

export default Sponsor
