import Axios from '../../auth'

const BASE_URL = process.env.REACT_APP_BASE_URL
const BASE_URI = process.env.REACT_APP_BASE_URI

export const getDataUser = async () => {
  const response = await Axios.get(`${BASE_URL + BASE_URI}/auth/user/detail/`)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data.Error
      }
    })
  return response
}
export const putDataUser = async (user) => {
  if (user.country === 'country') {
    user.country = ''
  }
  const response = await Axios.put(`${BASE_URL + BASE_URI}/auth/user/detail/`, {
    institution: user.institution,
    avatar_id: user.avatar_id,
    job_title: user.job_title,
    biography: user.biography,
    country: user.country,
    title: user.title,
    attendee_type: user.attendee_type,
    first_name: user.first_name,
    last_name: user.last_name,
    city: user.city
  })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data.Error
      }
    })
  return response
}

export const getCountries = async () => {
  const response = await Axios.get(`${BASE_URL + BASE_URI}/base/choices/countries/`)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data.Error
      }
    })
  return response
}
export const getAttendeeTypes = async () => {
  const response = await Axios.get(`${BASE_URL + BASE_URI}/base/choices/attendee_types/`)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data.Error
      }
    })
  return response
}

export const addAvatarUser = async (avatar) => {
  const response = await Axios.post(`${BASE_URL + BASE_URI}/auth/user/avatar/add/`, { file: avatar }, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data.Error
      }
    })
  return response
}
export const changeAvatarUser = async (avatar) => {
  const response = await Axios.put(`${BASE_URL + BASE_URI}/auth/user/avatar/`, { file: avatar }, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      if (error.response) {
        return error?.response?.data?.Error
      }
    })
  return response
}
