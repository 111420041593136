import React from 'react'
import colors from '../Colors.scss'

const MarkerSVG = ({ width = '22', height = '24', color = colors.color_blue }) => {
  return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4 10.3178C4 5.71789 7.84388 2 12.4934 2C17.1561 2 21 5.71789 21 10.3178C21 12.6357 20.157 14.7876 18.7695 16.6116C17.2388 18.6235 15.3522 20.3765 13.2285 21.7524C12.7425 22.0704 12.3039 22.0944 11.7704 21.7524C9.63474 20.3765 7.74809 18.6235 6.2305 16.6116C4.84198 14.7876 4 12.6357 4 10.3178ZM9.69423 10.5768C9.69423 12.1177 10.9517 13.3297 12.4934 13.3297C14.0362 13.3297 15.3058 12.1177 15.3058 10.5768C15.3058 9.0478 14.0362 7.77683 12.4934 7.77683C10.9517 7.77683 9.69423 9.0478 9.69423 10.5768Z"
                fill={color}
            />
        </svg>
  )
}

export default MarkerSVG
