import { Route, Routes } from 'react-router-dom'
import Layout from './components/Layout'
import HomePage from './Pages/HomePage/HomePage'
import { Suspense, useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import { TailSpin } from 'react-loader-spinner'

// src
import SignInPage from './Pages/SignInPage'
import SignUpPage from './Pages/SignUpPage'
import InboxPage from './Pages/InboxPage/InboxPage'
import LivePollPage from './Pages/LivePollPage/LivePollPage'
import AskQuestion from './Pages/AskQuestionPage/AskQuestion'
import Participants from './Pages/ParticipantsPage/Participants'
import EventAgenda from './Pages/EventAgendaPage/EventAgenda'
import ParticipantProfile from './Pages/ParticipantsPage/ParticipantProfile/ParticipantProfile'
import ForgetPassword from './Pages/SignUpPage/ForgetPassword'
import setAuthToken from './auth/SetAuthToken'
import ProtectedRoute from './auth/ProtectedRoute'
import ResetPassword from './Pages/SignUpPage/ResetPassword'
import PrivateChat from './Pages/InboxPage/PrivateChat/PrivateChat'
import Exhibition from './Pages/Exhibition/Exhibition'
import Posters from './Pages/Posters/Posters'
import EditingProfilePage from './Pages/EditingProfilePage/EditingProfilePage'
import EventAgendaDetail from './Pages/EventAgendaPage/EventAgendaDetail/EventAgendaDetail'
import PublicChat from './Pages/InboxPage/PublicChat/PublicChat'
import Register from './Pages/SignUpPage/Register'

function App () {
  const TRACKING_ID = 'UA-55102476-3'
  ReactGA.initialize(TRACKING_ID)
  const token = localStorage.getItem('token')
  if (token) { setAuthToken(token) }

  const [refreshEvent, setRefreshEvent] = useState(false)
  const [refreshNearEvent, setRefreshNearEvent] = useState(false)
  const [sendBird, setSendBird] = useState(null)
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])
  return (
        <Layout refreshEvent={refreshEvent} setSendBird={setSendBird} sendBird={sendBird} refreshNearEvent={refreshNearEvent} setRefreshEvent={setRefreshEvent}>
            <Suspense
                fallback={<TailSpin color="#00BFFF" height={80} width={80}/>}
            />
            <Routes>
                <Route
                    path="/events/:id/:slug/inbox/"
                    element={<ProtectedRoute redirect="/sign-in"/>}
                >
                    <Route path="/events/:id/:slug/inbox/" element={<InboxPage/>}/>
                </Route>
                <Route
                    path="/events/:id/:slug/polls/"
                    element={<ProtectedRoute redirect="/sign-in"/>}
                >
                    <Route path="/events/:id/:slug/polls/" element={<LivePollPage/>}/>
                </Route>
                <Route
                    path="/events/:id/:slug/questions/"
                    element={<ProtectedRoute redirect="/sign-in"/>}
                >
                    <Route
                        path="/events/:id/:slug/questions/"
                        element={<AskQuestion/>}
                    />
                </Route>
                <Route
                    path="/events/:id/:slug/participants/"
                    element={<ProtectedRoute redirect="/sign-in"/>}
                >
                    <Route
                        path="/events/:id/:slug/participants/"
                        element={<Participants/>}
                    />
                </Route>
                <Route
                    path="/events/:id/:slug/exhibition/"
                    element={<ProtectedRoute redirect="/sign-in"/>}
                >
                    <Route
                        path="/events/:id/:slug/exhibition/"
                        element={<Exhibition/>}
                    />
                </Route>
                <Route
                    path="/events/:id/:slug/posters/"
                    element={<ProtectedRoute redirect="/sign-in"/>}
                >
                    <Route
                        path="/events/:id/:slug/posters/"
                        element={<Posters/>}
                    />
                </Route>
                <Route
                    path="/events/:id/:slug/posters/:postersId/details/"
                    element={<ProtectedRoute redirect="/sign-in"/>}
                >
                    <Route
                        path="/events/:id/:slug/posters/:postersId/details/"
                        element={<Posters/>}
                    />
                </Route>
                <Route
                    path="/events/:id/:slug/:agendaId/video/"
                    element={<ProtectedRoute redirect="/sign-in"/>}
                >
                    <Route
                        path="/events/:id/:slug/:agendaId/video/"
                        element={<EventAgendaDetail/>}
                    />
                </Route>
                <Route
                    path="/events/:id/:slug/participants/profile/"
                    element={<ProtectedRoute redirect="/sign-in"/>}
                >
                    <Route
                        path="/events/:id/:slug/participants/profile/"
                        element={<ParticipantProfile/>}
                    />
                </Route>
                <Route
                    path="/events/:id/:slug/participants/chat/"
                    element={<ProtectedRoute redirect="/sign-in"/>}
                >
                    <Route
                        path="/events/:id/:slug/participants/chat/:userId"
                        element={<PrivateChat/>}
                    />
                </Route>
                <Route
                    path="/events/:id/:slug/hotline/"
                    element={<ProtectedRoute redirect="/sign-in"/>}
                >
                    <Route
                        path="/events/:id/:slug/hotline/"
                        element={<PublicChat sb={sendBird} /> }
                    />
                </Route>
                <Route
                    path="/events/:id/:slug/agenda/"
                    element={<ProtectedRoute redirect="/sign-in"/>}
                >
                    <Route path="/events/:id/:slug/agenda/" element={<EventAgenda setRefreshEvent={setRefreshEvent} setRefreshNearEvent={setRefreshNearEvent} refreshNearEvent={refreshNearEvent}/>}/>
                </Route>
                <Route
                    path="/events/:id/:slug/agenda/:agendaId/:agendaTitle"
                    element={<ProtectedRoute redirect="/sign-in"/>}
                >
                    <Route
                        path="/events/:id/:slug/agenda/:agendaId/:agendaTitle"
                        element={<EventAgendaDetail/>}
                    />
                </Route>
                {/* <Route */}
                {/*    path="/user/complete-profile/:key" */}
                {/*    element={<ProtectedRoute redirect="/sign-in"/>} */}
                {/* > */}
                {/*    <Route path="/user/complete-profile/:key" element={<SignUpPage/>}/> */}
                {/* </Route> */}
                <Route
                    path="/events/participants/chat/:key"
                    element={<ProtectedRoute redirect="/sign-in"/>}
                >
                    <Route path="/events/participants/chat/:key" element={<SignUpPage/>}/>
                </Route>

                <Route path="/user/editing-profile" element={<EditingProfilePage/>}/>

                <Route path="/" element={<HomePage/>}/>
                <Route path="/sign-in" element={<SignInPage/>}/>
                <Route path="/sign-up" element={<SignUpPage/>}/>
                <Route path="/user/complete-profile/:key" element={<SignUpPage/>}/>
                <Route path="/password/forgot" element={<ForgetPassword/>}/>
                <Route path="/user/register" element={<Register/>}/>
                <Route path="/password/reset/key/:key" element={<ResetPassword/>}/>
                <Route path="*" element={<>No Page Found</>}/>
            </Routes>
        </Layout>
  )
}

export default App
