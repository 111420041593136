import React, { useEffect, useRef, useState } from 'react'
import { GroupChannelHandler } from '@sendbird/chat/groupChannel'
import EmojiEmotionsOutlinedIcon from '@mui/icons-material/EmojiEmotionsOutlined'
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined'
import SendOutlinedIcon from '@mui/icons-material/SendOutlined'
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined'
import { useNavigate } from 'react-router-dom'
import { InputBase } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import DeleteIcon from '@mui/icons-material/Delete'
import Box from '@mui/material/Box'
import EditIcon from '@mui/icons-material/Edit'
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown'
import CloseIcon from '@mui/icons-material/Close'
import AttachEmailIcon from '@mui/icons-material/AttachEmail'
import AddReactionIcon from '@mui/icons-material/AddReaction'
import { toast, ToastContainer } from 'react-toastify'
import { FormattedMessage } from 'react-intl'
import { Button } from '@material-ui/core'

// src ...
import { connectToSB, DeleteMessage, previousMessage, userIsOnline } from '../SendBird/action'
import './PrivatChat.scss'
import '../../../assets/scss/Global.scss'
import { getAttendeeProfile } from '../../ParticipantsPage/action'
import Emoji from '../../../components/Emoji/Emoji'
import noAvatar from '../../../images/noAvatar.png'
import { getReaction } from '../action'

const PrivateChat = () => {
  const navigate = useNavigate()

  const href = document.location.href.split('/')
  const userId = href[href.length - 1]
  const url = window.location.pathname.split('/')
  const evId = url[2]
  const slug = url[3]

  const en = localStorage.getItem('en')
  const userDetail = JSON.parse(localStorage.getItem('userDetail'))

  const [sendBird, setSendBird] = useState()
  const [channel, setChannel] = useState()
  const [refreshMessage, setRefreshMessage] = useState(false)
  const [messagesFromDb, setMessageFromDb] = useState([])
  const [user, setUser] = useState()
  const [user2, setUser2] = useState()
  const [user2FromDb, setUser2FormDb] = useState()
  const [isOnline, setIsOnline] = useState([])
  const [showMsgDetail, setShowMsgDetail] = useState(false)
  const [msgId, setMsgId] = useState()
  const [updateMsg, setUpdateMsg] = useState(false)

  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [file, setFile] = useState(false)
  const [msgToSend, setMsgToSend] = useState('')
  const [msgToCheck, setMsgToCheck] = useState('')
  const [msgEmotion, setMsgEmotion] = useState()
  const [scrollBottom, setScrollBottom] = useState(false)
  const [scrollBottomOneTime, setScrollBottomOneTime] = useState('')
  const [limit] = useState(50)

  // Emoji
  const [emojiReaction, setEmojiReaction] = useState([])
  const [showEmoji, setShowEmoji] = useState(false)
  const [chosenEmoji, setChosenEmoji] = useState(null)
  const [showEmojiEmotion, setShowEmojiEmotion] = useState(false)

  useEffect(() => {
    if (chosenEmoji) {
      setMsgToSend(msgToSend + chosenEmoji.emoji)
    }
  }, [chosenEmoji])

  useEffect(() => {
    if (userId && !user && userDetail && !user2) {
      connectToSB().then(async value => {
        setSendBird(value)
        const { data } = await getAttendeeProfile(userId)
        setUser2(data)
        await getAttendeeProfile(userId).then(value => setUser2FormDb(value.data))
        await getAttendeeProfile(userDetail?.user.pk)
          .then(async (value) => {
            const connected = await sendBird?.connect(value.data?.sendbird_user_id, value.data?.sendbird_access_token)

            setUser(connected)
          })
      })
    }
  }, [sendBird, userId, userDetail])

  const GroupChannelCreateParams = async () => {
    try {
      if (user2?.sendbird_user_id !== user?.userId) {
        const params = { // OpenChannelCreateParams can be imported from @sendbird/chat/openChannel.
          name: `${user?.userId}_chat_${user2?.sendbird_user_id}`,
          invitedUserIds: [user?.userId, user2?.sendbird_user_id],
          isDistinct: true
        }
        const channel = await sendBird?.groupChannel.createChannel(params)
        setChannel(channel)
        return channel
      } else {
        navigate(`/events/${evId}/farm-life-online/inbox/`)
      }
    } catch (err) {
      console.log(' Handle error', err)
      // Handle error.
    }
  }

  const channelHandler = new GroupChannelHandler({
    onMessageUpdated: (channel, message) => {
      setRefreshMessage(message)
    },

    onMessageReceived: (channel, message) => {
      setRefreshMessage(message)
    },
    onMessageDeleted: (channel, message) => {
      setRefreshMessage(message)
      toast(<FormattedMessage id="toast-message-deleted" defaultMessage="Message was deleted"/>, {
        position: 'top-right',
        toastId: 'toastPrivat'
      })
    },

    onReactionUpdated: (channel, reactionEvent) => {
      // ...

      // If there is a message with the reactionEvent.messageId,
      // you can apply the reaction change to the message by calling the applyReactionEvent() method.
      (async () => {
        const params = {
          messageId: reactionEvent.messageId,
          channelType: 'group',
          channelUrl: channel.url
        }
        const msg = await sendBird?.message.getMessage(params)
        msg.applyReactionEvent(reactionEvent)

        setRefreshMessage(reactionEvent)
      })()
    }
  }
  )

  useEffect(() => {
    if (channel) {
      (async () => {
        await channel?.markAsRead()
      })()
      if (msgId) {

        // getReactionMsg('group_channels',channel?.url,msgId).then(value => console.log(value))
      }
      getReaction().then(value => setEmojiReaction(value.emoji_categories[0].emojis))
    }
  }, [channel, refreshMessage, msgId])

  useEffect(() => {
    if (user && user2 && sendBird) {
      GroupChannelCreateParams().then((channel) => {
        setIsLoading(true)

        previousMessage(channel, limit).then(value => {
          setMessageFromDb(value)

          if (!scrollBottomOneTime) {
            setScrollBottomOneTime('bottom')
          }
        }).finally(() => {
          setIsLoading(false)
        })
      })
      sendBird?.groupChannel.addGroupChannelHandler(`${user?.userId} + ${user2?.sendbird_user_id} `, channelHandler)
      setRefreshMessage(!refreshMessage)
    }
  }, [sendBird, user, user2])

  const SubmitHandler = async (e) => {
    e.preventDefault()
    if (updateMsg) {
      const params = { // UserMessageCreateParams can be imported from @sendbird/chat/message.
        message: msgToSend

      }
      if (params.message !== msgToCheck) {
        const refreshUpdate = await channel?.updateUserMessage(msgId, params)
        if (refreshUpdate.updatedAt) {
          setRefreshMessage(!refreshMessage)
          setShowEmoji(false)
          setMsgToSend('')
          setUpdateMsg(false)
        }
      } else {
        setUpdateMsg(false)
        setShowEmoji(false)
        setMsgToSend('')
      }
    }

    const file = e.target.file.files[0]
    if (!!file && msgToSend.length === 0 && !updateMsg) {
      const params = {
        file,
        fileName: file.name,
        fileSize: file.size
      }
      await channel?.sendFileMessage(params)

        .onSucceeded(async (message) => {
          // The message is successfully sent to the channel.
          // The current user can receive messages from other users through the onMessageReceived() method of an event handler.
          await setRefreshMessage(!refreshMessage)
          e.target.file.value = ''
          setFile('')
          setShowEmoji(false)
          setScrollBottom(true)
        })

      e.target.file.value = ''
    }

    if (msgToSend.length > 0 && !updateMsg && !file) {
      const params = { // UserMessageCreateParams can be imported from @sendbird/chat/message.
        message: msgToSend

      }
      await channel?.sendUserMessage(params)
        .onFailed((err, message) => {
          console.log('Handle error', err, message)
          // Handle error.
        })
        .onPending((value) => {
        })

        .onSucceeded(async (message) => {
          // The message is successfully sent to the channel.
          // The current user can receive messages from other users through the onMessageReceived() method of an event handler.

          await setRefreshMessage(!refreshMessage)
          setMsgToSend('')
          setScrollBottom(true)
          setTimeout(() => { setLoading(true) }, 500)

          // ...
          setShowEmoji(false)
        })
    }

    if (!!file && msgToSend.length > 0 && !updateMsg) {
      const paramsFile = {
        file,
        fileName: file.name,
        fileSize: file.size
      }
      await channel?.sendFileMessage(paramsFile)
        .onFailed((err, message) => {
          console.log('Handle error', err, message)
          // Handle error.
        })
        .onPending((value) => {
        })

        .onSucceeded(async (message) => {
          // The message is successfully sent to the channel.
          // The current user can receive messages from other users through the onMessageReceived() method of an event handler.
          e.target.file.value = ''
          setFile('')
          const paramsMsg = { // UserMessageCreateParams can be imported from @sendbird/chat/message.
            message: msgToSend
          }
          await channel?.sendUserMessage(paramsMsg)
            .onFailed((err, message) => {
              console.log('Handle error', err, message)
              // Handle error.
            })
            .onSucceeded((message) => {
              // The message is successfully sent to the channel.
              // The current user can receive messages from other users through the onMessageReceived() method of an event handler.

              setRefreshMessage(!refreshMessage)
              setMsgToSend('')
              setScrollBottom(true)
              setShowEmoji(false)
            })
        })

      e.target.file.value = ''
    }
  }

  useEffect(() => {
    previousMessage(channel, limit).then(msg => {
      setIsLoading(true)
      setMessageFromDb(msg)
      if (!scrollBottomOneTime) {
        setScrollBottomOneTime('bottom')
      }
    }).finally(() => {
      setIsLoading(false)
    })
  }, [refreshMessage])

  // scroll to last message
  function useChatScroll (dev, dev2, dev3, dev4, loading) {
    const ref = useRef()
    useEffect(() => {
      if (dev3 === 'bottom' && !!dev4) {
        ref.current.scrollTop = ref.current.scrollHeight
        setScrollBottomOneTime('close')
      }
      if (dev && loading) {
        ref.current.scrollTop = ref.current.scrollHeight
        setScrollBottom(false)
        setLoading(false)
      }
    }, [dev, dev2, dev3, dev4, loading])
    return ref
  }

  const showReactionEmoji = () => {
    setShowEmojiEmotion(!showEmojiEmotion)
  }

  const ref = useChatScroll(scrollBottom, refreshMessage, scrollBottomOneTime, messagesFromDb, loading)

  useEffect(() => {
    if (user2) userIsOnline(sendBird, [user2?.sendbird_user_id]).then(value => setIsOnline(value))
  }, [user2])
  return (
        <>
            <ToastContainer/>

            <div className="chat-header">
                <ArrowBackOutlinedIcon className="cursor-pointer arrow" onClick={() => navigate(-1)}/>
                <div className="chat-header-avatar"
                     style={{ cursor: 'pointer' }}
                     onClick={() => {
                       navigate(`/events/${evId}/${slug}/participants/profile/`,
                         {
                           state: userId
                         })
                     }}
                >
                    <img
                        style={{

                          height: '48px',
                          width: '48px'
                        }}
                        src={user2?.avatar?.meta?.download_url ? user2?.avatar?.meta?.download_url : noAvatar}

                        alt={user2?.full_name}
                    />
                    {!!isOnline && isOnline.map((value, index) => {
                      return (
                        value?.userId === user2?.sendbird_user_id
                          ? <p key={index} className="online-circle"/>
                          : <p key={index} className="offline-circle" />

                      )
                    })}
                </div>
                <div className="user-detail-chat">

                    <h4 className='user-name-near-avatar'
                        style={{ display: 'flex', alignItems: 'center' }}>{user2?.full_name}

                        {isOnline[0] !== 'false' && isOnline?.length >= 1 && <p className="user-online user-status"><p
                            className="status-circle status-circle-online"/><FormattedMessage id="online"
                                                                                              defaultMessage="Online"/>
                        </p>}
                    </h4>

                    <p className='userEmail'>{user2?.job_title}</p>
                </div>
            </div>

            <div className="chat-main-private"
                 ref={ref}
                 id="chat-main-scroll"
            >

                {messagesFromDb && messagesFromDb.map((msg) => {
                  return (
                        <div
                            key={msg?.messageId} className="msg-div" style={{
                              alignItems: msg?.sender.userId === user?.userId ? 'flex-end' : 'flex-start'
                            }}>

                            <div className="msg-time"
                                 style={{ flexDirection: msg?.sender.userId === user?.userId ? 'row' : 'row-reverse' }}>
                                {!!msg.updatedAt &&
                                    msg?.sender.userId === user?.userId &&
                                    (
                                        <p style={{
                                          position: 'absolute',
                                          bottom: '10px',
                                          left: en ? '-35px' : '-55px',
                                          fontSize: 11,
                                          color: 'darkgray'
                                        }}><FormattedMessage id="edited" defaultMessage="Edited"/></p>
                                    )}
                                {!!msg.updatedAt &&
                                    msg?.sender.userId !== user?.userId && (
                                        <p style={{
                                          position: 'absolute',
                                          bottom: '30px',
                                          right: en ? '-35px' : '-55px',
                                          fontSize: 11,
                                          color: 'darkgray'
                                        }}><FormattedMessage id="edited" defaultMessage="Edited"/></p>
                                )}
                                <p>{new Date(msg?.createdAt).getHours()}:{new Date(msg?.createdAt).getMinutes() < 10
                                  ? '0' + new Date(msg?.createdAt).getMinutes()
                                  : new Date(msg?.createdAt).getMinutes()}</p>

                                <h4 >{msg?.sender.userId === user?.userId
                                  ? <FormattedMessage id="you" defaultMessage="You"/>
                                  : `${user2?.full_name}`}</h4>
                                <div className="chat-header-avatar"
                                     style={{ cursor: 'pointer' }}
                                     onClick={() => {
                                       navigate(`/events/${evId}/${slug}/participants/profile/`,
                                         {
                                           state: msg?.sender.metaData.django_id
                                         })
                                     }}
                                >

                                    {
                                        msg?.sender.userId === user?.userId
                                        // <img src={msg?.sender?.plainProfileUrl ? msg?.sender?.plainProfileUrl : noAvatar}
                                          // alt={msg?.sender.nickname}
                                          //   />
                                          ? ''
                                          : <img src={user2FromDb?.avatar?.meta?.download_url ? user2FromDb?.avatar?.meta?.download_url : noAvatar}
                                                 alt={msg?.sender.nickname}/>}
                                </div>
                                {showMsgDetail && msg?.messageId === msgId
                                  ? <CloseIcon style={{ alignSelf: ' center' }}
                                                                                        onClick={() => {
                                                                                          setShowMsgDetail(!showMsgDetail)
                                                                                          setMsgId(msg?.messageId)
                                                                                        }} cursor="pointer"/>

                                  : msg?.sender.userId === user?.userId
                                    ? <MoreVertIcon
                                            style={{ alignSelf: ' center' }}
                                            onClick={() => {
                                              setShowMsgDetail(!showMsgDetail)
                                              setMsgId(msg?.messageId)
                                            }} cursor="pointer"/>
                                    : msg.type
                                      ? <MoreVertIcon
                                                style={{ alignSelf: ' center' }}
                                                onClick={() => {
                                                  setShowMsgDetail(!showMsgDetail)
                                                  setMsgId(msg?.messageId)
                                                }} cursor="pointer"/>
                                      : ''

                                }</div>

                            <div
                                style={{ padding: '14px 16px 26px' }}
                                className={msg?.sender.userId === user?.userId ? 'my-msg msg' : 'not-my-msg msg'}
                            >

                                {msg?.type === 'application/pdf' || msg?.type === 'text/plain'
                                  ? <a style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: 10
                                  }}
                                         className={msg?.sender.userId === user?.userId
                                           ? 'chat-file-text-my-msg'
                                           : 'chat-file-text-not-my-msg'}
                                         href={msg?.url} target="_blank" rel="noreferrer"> {msg?.sender.userId === user?.userId
                                           ? <>{msg?.name} <AttachEmailIcon/></>
                                           : <> <AttachEmailIcon/> {msg?.name} </>}
                                    </a>
                                  : msg?.type === 'image/jpeg' || msg?.type === 'image/png'
                                    ? <div className="chat-msg-img"><img src={msg?.url}
                                                                             alt=""/>
                                        </div>
                                    : msg?.message?.includes('http') || msg?.message?.includes('www')
                                      ? (<a target="_blank"
                                                  style={{ color: msg?.sender.userId === user?.userId ? 'white' : 'black' }}
                                                  href={msg?.message} rel="noreferrer">{msg?.message}</a>)
                                      : msg?.message}

                                {showMsgDetail && msg?.messageId === msgId && <div
                                    style={{ borderRadius: msg?.sender.userId === user?.userId ? ' 20px 0 20px 20px' : '0 20px 20px 20px' }}
                                    className="show-msg-detail">

                                    {msg?.sender.userId === user?.userId &&
                                        <>
                                            <Button
                                                style={{ justifyContent: 'space-between', color: 'black' }}
                                                className="msg-detail-div"
                                                onClick={() => {
                                                  setMsgToSend(msg.message)
                                                  setMsgToCheck(msg.message)
                                                  setShowMsgDetail(!showMsgDetail)
                                                  setUpdateMsg(true)
                                                }}>

                                                <p><FormattedMessage id="edit" defaultMessage="Edit"/></p>

                                                <EditIcon/>
                                            </Button>

                                            <Button
                                                style={{ justifyContent: 'space-between', color: 'black' }}
                                                className="msg-detail-div"
                                                onClick={() => {
                                                  DeleteMessage(channel, msg)
                                                  setShowMsgDetail(!showMsgDetail)
                                                }}>
                                                <p>

                                                    <FormattedMessage id="delete" defaultMessage="Delete"/>
                                                </p>
                                                <DeleteIcon/>
                                            </Button>

                                        </>

                                    }
                                    {!!msg?.type && (
                                        <Button className="msg-detail-div"
                                                style={{ justifyContent: 'space-between' }}

                                                onClick={() => {
                                                  const element = document.createElement('a')
                                                  element.href = msg?.url
                                                  document.body.appendChild(element)
                                                  element.click()
                                                }}
                                        >
                                            <p>
                                                <FormattedMessage id="download" defaultMessage="Download"/>
                                            </p>
                                            <ArrowCircleDownIcon/>
                                        </Button>
                                    )}

                                </div>}
                                {msg?.reactions[0]
                                  ? (

                                        <div
                                            className={msg?.sender.userId === user?.userId ? 'my-reaction' : 'not-my-reaction'}
                                        >

                                            {emojiReaction && emojiReaction?.map((emoji) => {
                                              if (emoji?.key === msg?.reactions[0]?.key) {
                                                return (
                                                        <img key={emoji?.key} style={{ width: '100%' }} src={emoji?.url}
                                                             alt={emoji?.key}/>
                                                )
                                              }
                                            })}

                                        </div>)
                                  : msg?.sender.userId === user?.userId
                                    ? <div>

                                            <AddReactionIcon
                                                onClick={() => {
                                                  setMsgId(msg.messageId)
                                                  setMsgEmotion(msg)
                                                  showReactionEmoji()
                                                }}

                                                style={{ position: 'absolute', bottom: 0, left: 0, cursor: 'pointer' }}
                                            />

                                        </div>
                                    : <AddReactionIcon
                                            onClick={() => {
                                              setMsgId(msg.messageId)
                                              setMsgEmotion(msg)
                                              showReactionEmoji()
                                            }}

                                            style={{ position: 'absolute', bottom: 0, right: 0, cursor: 'pointer' }}
                                        />}

                            </div>

                            {showEmojiEmotion && msg?.messageId === msgId &&
                                <div style={{
                                  width: 20,
                                  height: 20,
                                  display: 'flex',
                                  position: 'relative',
                                  left: msg?.sender.userId === user?.userId ? -170 : 0,
                                  gap: 5,
                                  top: -20
                                }}>

                                    {emojiReaction?.map((emoji) =>

                                        <img
                                            onClick={async () => {
                                              try {
                                                const emojiKey = emoji.key
                                                await channel?.addReaction(msgEmotion, emojiKey)
                                                showReactionEmoji()
                                              } catch (e) {
                                                console.log(e)
                                              }
                                            }}
                                            key={emoji.id}
                                            style={{ width: '100%', cursor: 'pointer' }} src={emoji.url}
                                            alt={emoji.key}/>
                                    )}
                                </div>

                            }
                        </div>
                  )
                })}

            </div>

            {showEmoji && <Emoji setChosenEmoji={setChosenEmoji} updateMsg={updateMsg}/>}

            <form onSubmit={SubmitHandler} className="form-chat">

                {
                    updateMsg && (
                        <div className="show-msg-to-edit">
                            <EditIcon/>
                            <div>
                                <p><FormattedMessage id="edit-message" defaultMessage="Edit message"/></p>
                                <p style={{
                                  marginTop: '5px',
                                  lineHeight: '20px'
                                }}>{msgToCheck.length > 22 ? msgToCheck?.slice(0, 22) + '...' : msgToCheck}</p>
                                <CloseIcon
                                    onClick={() => {
                                      setMsgToSend('')
                                      setUpdateMsg(false)
                                    }}
                                    style={{ position: 'absolute', right: '5px', top: '5px', cursor: 'pointer' }}
                                />
                            </div>
                        </div>

                    )
                }

                {/*
                   todo letter
                   {scrollToLastMsg && (
                    <Button className="go-to-last-msg"
                            onClick={() => {
                                setScrollBottom(true)
                            }}
                    >
                        <ArrowCircleDownIcon height={40} width={40}/>
                    </Button>
                )} */}

                <Box className="send-msg">
                    {/* {chosenEmoji ? ( */}
                    {/*    <span>You chose: {chosenEmoji.emoji}</span> */}
                    {/* ) : ( */}
                    {/*    <span>No emoji Chosen</span> */}
                    {/* )} */}
                    <div className="send-msg-input-flex-center" style={{ width: '100%' }}>

                        <EmojiEmotionsOutlinedIcon

                            onClick={() => {
                              setShowEmoji(!showEmoji)
                            }}
                            sx={{ color: 'action.active', mr: 1, my: 0.5, cursor: 'pointer' }}/>
                        <input type="file"
                               name="file"
                               id="fileMsg" style={{ display: 'none' }}
                               hidden
                               onChange={(e) => {
                                 setFile(e.target.files[0])
                               }}/>
                        <AttachFileOutlinedIcon
                            className="file-chose-chat-icon"
                            onClick={() => {
                              document.getElementById('fileMsg').click()
                            }}
                            sx={{ color: 'action.active', mr: 1, my: 0.5 }}/>
                        {!!file && (
                            <>
                                <p>{file.name}</p>
                                <p className="delete-file-chat-icon"
                                   onClick={() => {
                                     const fileInput = document.getElementById('fileMsg')

                                     fileInput.value = null
                                     setFile('')
                                   }}><RemoveCircleIcon/></p>
                            </>)}
                        <FormattedMessage id="placeholder-send-message" defaultMessage="Send a message...">
                            {
                                placeholder =>
                                    <InputBase style={{ width: '100%' }} name="message" value={msgToSend}
                                               placeholder={`${placeholder}`}
                                               id="input-with-sx"
                                               hidden
                                               variant="standard"
                                               onChange={(e) => {
                                                 setMsgToSend(e.target.value)
                                               }}
                                    />
                            }
                        </FormattedMessage>

                    </div>
                    <div className="send-msg-input-flex-center">
                        <button className="btn-reset-style">
                            <SendOutlinedIcon color="primary"/>
                        </button>
                    </div>
                </Box>
                {/* <input type="text" name="message"/> */}
                {/* <button>Send</button> */}
            </form>

        </>

  )
}

export default PrivateChat
