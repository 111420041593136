import axios from 'axios'

export { default } from './InboxPage'

const API_TOKEN = process.env.REACT_APP_SENDBIRD_API_TOKEN
const APPLICATION_ID = process.env.REACT_APP_SENDBIRD_APP_ID

// export const getGroupList = async () => {
//     const {data} = await axios.get('https://api-${application_id}.sendbird.com/v3/users/9/my_group_channels', {
//         headers: {
//             "Api-Token": api_token
//         }
//     })
//     return data
// }

export const getReactionMsg = async (channel_type, channel_url, message_id) => {
  const { data } = await axios.get(`https://api-${APPLICATION_ID}.sendbird.com/v3/${channel_type}/${channel_url}/messages/${message_id}/reactions`, {
    headers: {
      'Api-Token': API_TOKEN
    }
  })
  return data
}
export const getReaction = async (channel_type, channel_url, message_id) => {
  const { data } = await axios.get(`https://api-${APPLICATION_ID}.sendbird.com/v3/emoji_categories`, {
    headers: {
      'Api-Token': API_TOKEN
    }
  })
  return data
}
