import { InputBase } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import EmojiEmotionsOutlinedIcon from '@mui/icons-material/EmojiEmotionsOutlined'
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import SendOutlinedIcon from '@mui/icons-material/SendOutlined'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown'
import CloseIcon from '@mui/icons-material/Close'
import { OpenChannelHandler } from '@sendbird/chat/openChannel'
import AttachEmailIcon from '@mui/icons-material/AttachEmail'
import { FormattedMessage } from 'react-intl'
import { toast, ToastContainer } from 'react-toastify'

// src
import './PublicChat.scss'
import '../PrivateChat/PrivatChat.scss'
import '../../../assets/scss/Global.scss'
import { EventDesp } from '../../../components/Layout/action'
import { connectToSB, ConnectUserToSendBird, DeleteMessage, previousMessage, userIsOnline } from '../SendBird/action'
import Emoji from '../../../components/Emoji/Emoji'
import { getAttendeeProfile } from '../../ParticipantsPage/action'
import noAvatar from '../../../images/noAvatar.png'
import Hotline from '../../../SVG/Hotline'
import Questions from '../../../SVG/Questions'
import ContextHelp from '../../../components/ContextHelp/ContextHelp'
import adminImg from '../../../images/admin.png'
import colors from '../../../Colors.scss'

export default function PublicChat ({ sb }) {
  const en = localStorage.getItem('en')
  const userDetail = JSON.parse(localStorage.getItem('userDetail'))
  const url = window.location.href
  const navigate = useNavigate()

  const url1 = window.location.pathname.split('/')
  const evId = url1[2]
  const slug = url1[3]

  const BASE_URL = process.env.REACT_APP_BASE_URL
  const BASE_URI = process.env.REACT_APP_BASE_URI

  const token = localStorage.getItem('token')

  const [messagesFromDb, setMessageFromDb] = useState([])
  const [channel, setChannel] = useState()
  const [isOnline, setIsOnline] = useState([])
  const [senderId, setSenderId] = useState([])

  const [limit] = useState(50)
  const [refreshMessage, setRefreshMessage] = useState(false)
  const [showMsgDetail, setShowMsgDetail] = useState(false)
  const [msgId, setMsgId] = useState()
  const [updateMsg, setUpdateMsg] = useState()
  const [setIsLoading] = useState(false)
  const [file, setFile] = useState(false)
  const [msgToSend, setMsgToSend] = useState('')
  const [msgToCheck, setMsgToCheck] = useState('')
  const [scrollBottom, setScrollBottom] = useState(false)
  const [scrollBottomOneTime, setScrollBottomOneTime] = useState('')
  const [operator, setOperator] = useState(false)
  const [showEmoji, setShowEmoji] = useState(false)
  const [chosenEmoji, setChosenEmoji] = useState(null)
  const [user, setUser] = useState(false)
  const [sendBird, setSendBird] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!!sb && !user) {
      (async () => {
        await getAttendeeProfile(userDetail?.user.pk).then(value => {
          ConnectUserToSendBird(value.data, sb).then(value => {
            setUser(value)
          })
          setSendBird(sb)
        }
        )
      })()
    } else {
      if (!!userDetail.user.pk && !user && sendBird) {
        connectToSB().then(async value => {
          setSendBird(value)
          await getAttendeeProfile(userDetail?.user?.pk).then(async (value) => {
            const connected = await sendBird?.connect(value.data?.sendbird_user_id, value.data?.sendbird_access_token)
            setUser(connected)
            localStorage.setItem('userSb', JSON.stringify(connected))
          })
        })
      }
    }
  }, [sendBird, userDetail])

  const channelHandler = new OpenChannelHandler({
    onMessageUpdated: (channel, message) => {
      setRefreshMessage(message)
    },
    onMessageReceived: (channel, message) => {
      setRefreshMessage(message)
    },
    onMessageDeleted: (channel, message) => {
      setRefreshMessage(message)
      toast(<FormattedMessage id="toast-message-deleted" defaultMessage="Message was deleted"/>, {
        position: 'top-right',
        toastId: 'successId'
      })
    }
  })
  useEffect(() => {
    if (sendBird && !!channel) {
      sendBird?.openChannel.addOpenChannelHandler(channel?.url, channelHandler)
    }
  }, [sendBird, channel])

  useEffect(() => {
    if (chosenEmoji) {
      setMsgToSend(msgToSend + chosenEmoji.emoji)
    }
  }, [chosenEmoji])

  const connect = async ({ chatUrl }) => {
    try {
      const isChanel = await sendBird?.openChannel.getChannel(chatUrl)
      if (isChanel && sendBird) {
        previousMessage(isChanel, limit).then(value => {
          if (!scrollBottomOneTime) {
            setScrollBottomOneTime('bottom')
          }
          setMessageFromDb(value)
        })

        setChannel(isChanel)
        await isChanel.enter().then(value => {
          setRefreshMessage(value)
        })

        const isOperator = isChanel.operators.filter((operator) => {
          return operator?.userId === user?.userId
        })
        if (isOperator[0]) setOperator(true)
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (sendBird) {
      EventDesp(evId).then(async ({ data }) => {
        if (url.includes('hotline')) {
          await connect({ chatUrl: data?.sendbird_channel_url_hotline })
        } else {
          await connect({ chatUrl: data?.sendbird_channel_url })
        }
      })
    }
  }, [url, sendBird])

  const SubmitHandler = async (e) => {
    e.preventDefault()
    if (updateMsg) {
      const params = { // UserMessageCreateParams can be imported from @sendbird/chat/message.
        message: msgToSend
      }
      if (params.message !== msgToCheck) {
        const refreshUpdate = await channel?.updateUserMessage(msgId, params)
        if (refreshUpdate.updatedAt) {
          setRefreshMessage(!refreshMessage)
          setMsgToSend('')
          setUpdateMsg(false)
          setShowEmoji(false)
        }
      } else {
        setUpdateMsg(false)
        setShowEmoji(false)
        setMsgToSend('')
      }
    }

    const file = e?.target?.file?.files[0]
    if (!!file && msgToSend.length === 0 && !updateMsg) {
      const params = {
        file,
        fileName: file.name,
        fileSize: file.size
      }
      await channel?.sendFileMessage(params)
        .onPending((value) => {
          // setIsLoading(true)
        })

        .onSucceeded((message) => {
          // The message is successfully sent to the channel.
          // The current user can receive messages from other users through the onMessageReceived() method of an event handler.
          setRefreshMessage(!refreshMessage)
          e.target.file.value = ''
          setIsLoading(false)
          setFile('')
          setScrollBottom(!scrollBottom)
          setShowEmoji(false)

          // ...
        })
      e.target.file.value = ''
    }
    if (msgToSend.length > 0 && !updateMsg && !file) {
      const params = { // UserMessageCreateParams can be imported from @sendbird/chat/message.
        message: msgToSend
      }

      channel?.sendUserMessage(params)
        .onFailed((err, message) => {
          console.log('Handle error', err, message)

          toast(<FormattedMessage id="toast-message-freeze-chat" defaultMessage="Sending messages is currently disabled"/>, {
            position: 'top-right'
          })

          // Handle error.
        })
        .onPending((value) => {
        })

        .onSucceeded((message) => {
          // The message is successfully sent to the channel.
          // The current user can receive messages from other users through the onMessageReceived() method of an event handler.
          setRefreshMessage(!refreshMessage)
          setMsgToSend('')
          setScrollBottom(!scrollBottom)
          setShowEmoji(false)
          setTimeout(() => { setLoading(true) }, 500)
        })
    }
    if (!!file && msgToSend.length > 0 && !updateMsg) {
      const paramsFile = {
        file,
        fileName: file.name,
        fileSize: file.size
      }
      await channel?.sendFileMessage(paramsFile)
        .onFailed((err, message) => {
          console.log('Handle error', err, message)
          // Handle error.
        })
        .onPending((value) => {
        })

        .onSucceeded(async (message) => {
          // The message is successfully sent to the channel.
          // The current user can receive messages from other users through the onMessageReceived() method of an event handler.
          e.target.file.value = ''
          setFile('')
          const paramsMsg = { // UserMessageCreateParams can be imported from @sendbird/chat/message.
            message: msgToSend
          }
          await channel?.sendUserMessage(paramsMsg)
            .onFailed((err, message) => {
              console.log('Handle error', err, message)
              // Handle error.
            })
            .onSucceeded((message) => {
              // The message is successfully sent to the channel.
              // The current user can receive messages from other users through the onMessageReceived() method of an event handler.

              setRefreshMessage(!refreshMessage)
              setMsgToSend('')
              setScrollBottom(true)
              setShowEmoji(false)
            })
        })
      e.target.file.value = ''
    }
  }

  // scroll to last message
  function useChatScroll (dev, dev2, dev3, dev4, loading) {
    const ref = useRef()
    useEffect(() => {
      if (dev3 === 'bottom' && !!dev4) {
        ref.current.scrollTop = ref.current.scrollHeight
        setScrollBottomOneTime('close')
      }

      if (dev && loading) {
        ref.current.scrollTop = ref.current.scrollHeight
        setScrollBottom(false)
        setLoading(false)
      }
    }, [dev, dev2, dev3, dev4, loading])
    return ref
  }

  const ref = useChatScroll(scrollBottom, refreshMessage, scrollBottomOneTime, messagesFromDb, loading)

  useEffect(() => {
    previousMessage(channel, limit).then(value => {
      if (!scrollBottomOneTime) {
        setScrollBottomOneTime('bottom')
      }
      setScrollBottom(true)
      setLoading(true)
      setMessageFromDb(value)
    })
  }, [refreshMessage])

  useEffect(() => {
    if (sendBird) {
      const ids = senderId.filter(value => value !== undefined)
      if (!ids.includes(undefined)) {
        userIsOnline(sendBird, ids).then(value => {
          setIsOnline(value)
        })
      }
    }
  }, [senderId, sendBird])

  const [showModalHelper, setShowModalHelper] = useState({
    open: false,
    event: {}
  })

  return (
        < >
            <ToastContainer/>
          {url.includes('hotline') && <div className='title-main'>
            <div className='title'>
              <Hotline width='30' height='30'/><p><FormattedMessage id='hotline' defaultMessage='Hotline'/>&nbsp;
                            <span
                        onClick={(e) => {
                          setShowModalHelper({
                            open: !showModalHelper.open,
                            event: e.currentTarget
                          })
                        }}>
                    <Questions/>
                              {showModalHelper && <ContextHelp helpText={{ id: 4, what: 'Solves problems at the congress regardless whether they are of a purely technical or organisational nature.' }} open={showModalHelper.open} anchorEl={showModalHelper.event} setShowModalHelper={setShowModalHelper}/>}

                  </span>
            </p>
            </div>
          </div>}
            <div className={`chat-main-public ${url.includes('hotline') ? 'chat-main-public-hotline ' : ''}` }
                 ref={ref}
                 id="chat-main-scroll">
              {url.includes('hotline') && <span className='chat-main-public-hotline-helper-mobile'
                     onClick={(e) => {
                       setShowModalHelper({
                         open: !showModalHelper.open,
                         event: e.currentTarget
                       })
                     }}>
                    <Questions height='25' width='25' color={colors.color_grey_shade}/>
                {showModalHelper && <ContextHelp helpText={{
                  id: 4,
                  what: 'Solves problems at the congress regardless whether they are of a purely technical or organisational nature.'
                }} open={showModalHelper.open} anchorEl={showModalHelper.event}
                                                 setShowModalHelper={setShowModalHelper}/>}

                  </span>}
                {messagesFromDb && messagesFromDb.map((msg) => {
                  if (!senderId.includes(msg.sender?.userId) && msg?.sender?.userId !== user?.userId) setSenderId(prevArray => [...prevArray, msg.sender?.userId])
                  let imageUrl = ''

                  if (msg?.messageType === 'admin') {
                    imageUrl = adminImg
                  } else if (msg?.isOperatorMessage) {
                    imageUrl = noAvatar
                  } else {
                    if (msg?.sender?.plainProfileUrl.includes('http')) {
                      imageUrl = `${BASE_URL + BASE_URI}/user/${msg?.sender?.metaData.django_id}/avatar/?auth_token=${token}`
                    } else {
                      imageUrl = noAvatar
                    }
                  }

                  return (
                        <div key={msg?.createdAt} className="msg-div"
                             style={{
                               alignItems: msg?.sender?.userId === user?.userId ? 'flex-end' : 'flex-start'
                             }}>

                            <div className="msg-time"
                                 style={{ flexDirection: msg?.sender?.userId === user?.userId ? 'row' : 'row-reverse' }}>
                                {!!msg.updatedAt &&
                                    msg?.sender?.userId === user?.userId &&
                                    (
                                        <p style={{
                                          position: 'absolute',
                                          bottom: '10px',
                                          left: en ? '-35px' : '-55px',
                                          fontSize: 11,
                                          color: 'darkgray'
                                        }}><FormattedMessage id="edited" defaultMessage="Edited"/></p>
                                    )}
                                {!!msg.updatedAt &&
                                    msg?.sender?.userId !== user?.userId && (
                                        <p style={{
                                          position: 'absolute',
                                          bottom: '30px',
                                          right: en ? '-35px' : '-55px',
                                          fontSize: 11,
                                          color: 'darkgray'
                                        }}><FormattedMessage id="edited" defaultMessage="Edited"/></p>
                                )}
                                <p>{new Date(msg?.createdAt).getHours()}:{new Date(msg?.createdAt).getMinutes() < 10
                                  ? '0' + new Date(msg?.createdAt).getMinutes()
                                  : new Date(msg?.createdAt).getMinutes()}</p>

                                <h4>{ msg?.messageType === 'admin' ? <FormattedMessage id="admin-message" defaultMessage="Admin message"/> : msg?.sender?.userId === user?.userId
                                  ? <FormattedMessage id="you" defaultMessage="You"/>
                                  : `${msg?.sender?.nickname}`}</h4>
                                <div className="chat-header-avatar"
                                     style={{ cursor: 'pointer' }}
                                     onClick={() => {
                                       navigate(`/events/${evId}/${slug}/participants/profile/`,
                                         {
                                           state: msg?.sender.metaData.django_id
                                         })
                                     }}
                                >
                                    <div className="chat-header-avatar">
                                    {!!isOnline && msg?.sender?.userId !== user?.userId && isOnline.map((value, index) => {
                                      return (
                                            <div key={new Date().getTime() + msg?.messageId + index }>
                                                {value?.userId === msg.sender?.userId
                                                  ? <p className="online-circle" style={{ height: 8, width: 8, bottom: 3 }}/>
                                                  : <p className="offline-circle" style={{ height: 8, width: 8, bottom: 3 }}/>}
                                            </div>

                                      )
                                    })}
                                      {msg?.sender?.userId !== user?.userId && <img style={{ width: 40, height: 40, borderRadius: '50%' }}
                                            src={imageUrl }
                                            alt={msg?.sender?.nickname}/>}
                                        </div>
                                </div>

                                {operator
                                  ? <MoreVertIcon
                                        style={{ alignSelf: ' center' }}
                                        onClick={() => {
                                          setShowMsgDetail(!showMsgDetail)
                                          setMsgId(msg?.messageId)
                                        }} cursor="pointer"/>
                                  : msg?.sender?.userId === user?.userId
                                    ? <MoreVertIcon
                                            style={{ alignSelf: ' center' }}
                                            onClick={() => {
                                              setShowMsgDetail(!showMsgDetail)
                                              setMsgId(msg?.messageId)
                                            }} cursor="pointer"/>

                                    : msg.type
                                      ? <MoreVertIcon
                                                style={{ alignSelf: ' center' }}
                                                onClick={() => {
                                                  setShowMsgDetail(!showMsgDetail)
                                                  setMsgId(msg?.messageId)
                                                }} cursor="pointer"/>
                                      : ''

                                }</div>

                            <div
                                style={{
                                  height: msg?.type === 'image/jpeg' || msg?.type === 'image/png' && 125,
                                  padding: '14px 16px 14px'
                                }}
                                className={msg?.sender?.userId === user?.userId ? 'my-msg msg' : msg?.messageType === 'admin' ? 'not-my-msg msg admin-msg' : 'not-my-msg msg' }
                            >

                                {msg?.type === 'application/pdf' || msg?.type === 'text/plain'
                                  ? <a style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: 10
                                  }}
                                         className={msg?.sender?.userId === user?.userId
                                           ? 'chat-file-text-my-msg'
                                           : 'chat-file-text-not-my-msg'}
                                         href={msg?.url} target="_blank" rel="noreferrer"> {msg?.sender?.userId === user?.userId
                                           ? <>{msg?.name} <AttachEmailIcon/></>
                                           : <> <AttachEmailIcon/> {msg?.name} </>}
                                    </a>
                                  : msg?.type === 'image/jpeg' || msg?.type === 'image/png' || msg?.type === 'image/svg+xml'
                                    ? <div className="chat-msg-img"><img src={msg?.url}
                                                                             alt=""/>
                                        </div>
                                    : msg?.message.includes('http')
                                      ? (<a target="_blank"
                                                  style={{ color: msg?.sender?.userId === user?.userId ? 'white' : 'black' }}
                                                  href={msg?.message} rel="noreferrer">{msg?.message}</a>)
                                      : msg?.message}

                                {showMsgDetail && msg?.messageId === msgId && <div
                                    style={{ borderRadius: msg?.sender?.userId === user?.userId ? ' 20px 0 20px 20px' : '0 20px 20px 20px' }}
                                    className="show-msg-detail">

                                    {operator
                                      ? <>
                                          {msg?.type !== 'image/jpeg' && msg?.type !== 'image/png' && msg?.type !== 'image/svg+xml' && <Button
                                              style={{ justifyContent: 'space-between', color: 'black' }}
                                              className="msg-detail-div"
                                              onClick={() => {
                                                setMsgToSend(msg.message)
                                                setMsgToCheck(msg.message)
                                                setShowMsgDetail(!showMsgDetail)

                                                setUpdateMsg(true)
                                              }}>

                                            <p><FormattedMessage id="edit" defaultMessage="Edit"/></p>

                                            <EditIcon/>
                                          </Button>}

                                            <Button
                                                style={{ justifyContent: 'space-between', color: 'black' }}
                                                className="msg-detail-div"
                                                onClick={() => {
                                                  DeleteMessage(channel, msg).then((value) => {
                                                    toast(<FormattedMessage id="toast-message-deleted" defaultMessage="Message was deleted"/>, {
                                                      position: 'top-right'
                                                    })
                                                    setShowMsgDetail(!showMsgDetail)
                                                  }
                                                  )
                                                }}>
                                                <p>
                                                    <FormattedMessage id="delete" defaultMessage="Delete"/>
                                                </p>
                                                <DeleteIcon/>
                                            </Button>
                                        </>
                                      : msg?.sender?.userId === user?.userId &&
                                        <>
                                        {msg?.type !== 'image/jpeg' && msg?.type !== 'image/png' && msg?.type !== 'image/svg+xml' && <Button
                                                style={{ justifyContent: 'space-between', color: 'black' }}
                                                className="msg-detail-div"
                                                onClick={() => {
                                                  setMsgToSend(msg.message)
                                                  setMsgToCheck(msg.message)
                                                  setShowMsgDetail(!showMsgDetail)

                                                  setUpdateMsg(true)
                                                }}>

                                                <p><FormattedMessage id="edit" defaultMessage="Edit"/></p>

                                                <EditIcon/>
                                            </Button>}

                                            <Button
                                                style={{ justifyContent: 'space-between', color: 'black' }}
                                                className="msg-detail-div"
                                                onClick={() => {
                                                  DeleteMessage(channel, msg)
                                                  setShowMsgDetail(!showMsgDetail)
                                                }}>
                                                <p>

                                                    <FormattedMessage id="delete" defaultMessage={'Delete'}/>
                                                </p>
                                                <DeleteIcon/>
                                            </Button>
                                        </>

                                    }
                                    {!!msg?.type && (
                                        <Button className="msg-detail-div"
                                                style={{ justifyContent: 'space-between', color: 'black' }}

                                                onClick={(e) => {
                                                  const element = document.createElement('a')
                                                  element.href = msg?.url
                                                  document.body.appendChild(element)
                                                  element.click()
                                                }}
                                        >
                                            <p>
                                                <FormattedMessage id="download" defaultMessage="Download"/>
                                            </p>
                                            <ArrowCircleDownIcon/>
                                        </Button>
                                    )}

                                </div>}</div>
                        </div>
                  )
                })}

            </div>
            {showEmoji && <Emoji setChosenEmoji={setChosenEmoji} updateMsg={updateMsg}/>}

            <form onSubmit={SubmitHandler} className="form-chat">
                {
                    updateMsg && (
                        <div className="show-msg-to-edit">
                            <EditIcon/>
                            <div>
                                <p><b><FormattedMessage id="edit-message" defaultMessage="Edit message"/></b></p>
                                <p style={{
                                  marginTop: '5px',
                                  lineHeight: '20px'
                                }}>{msgToCheck.length > 22 ? msgToCheck.slice(0, 22) + '...' : msgToCheck}</p>
                                <CloseIcon
                                    onClick={() => {
                                      setMsgToSend('')
                                      setUpdateMsg(false)
                                    }}
                                    style={{ position: 'absolute', right: '5px', top: '5px', cursor: 'pointer' }}
                                />
                            </div>
                        </div>

                    )
                }
                <Box className="send-msg">
                    <div className="send-msg-input-flex-center" style={{ width: '100%' }}>

                        <EmojiEmotionsOutlinedIcon
                            onClick={() => {
                              setShowEmoji(!showEmoji)
                            }}
                            sx={{ color: 'action.active', mr: 1, my: 0.5, cursor: 'pointer' }}/>
                        <input type="file"
                               name="file"
                               id="fileMsg"
                               style={{ display: 'none' }}
                               hidden
                               onChange={(e) => {
                                 setFile(e.target.files[0])
                               }}/>
                        <AttachFileOutlinedIcon
                            className="file-chose-chat-icon"
                            onClick={() => {
                              document.getElementById('fileMsg').click()
                            }}
                            sx={{ color: 'action.active', mr: 1, my: 0.5 }}/>
                        {!!file && (
                            <>
                                <p>{file.name}</p>
                                <p
                                    className="delete-file-chat-icon"
                                    onClick={() => {
                                      const fileInput = document.getElementById('fileMsg')

                                      fileInput.value = null
                                      setFile('')
                                    }}><RemoveCircleIcon/></p>
                            </>)}
                        <FormattedMessage id="placeholder-send-message" defaultMessage="Send a message...">
                            {
                                placeholder =>
                                    <InputBase style={{ width: '100%' }} name="message" value={msgToSend}
                                               placeholder={`${placeholder}`}
                                               id="input-with-sx"
                                               variant="standard"
                                               onChange={(e) => {
                                                 setMsgToSend(e.target.value)
                                               }}
                                    />
                            }
                        </FormattedMessage>

                    </div>
                    <div className="send-msg-input-flex-center">
                        <button className="btn-reset-style">
                            <SendOutlinedIcon color="primary"/>
                        </button>
                    </div>
                </Box>
                {/* <input type="text" name="message"/> */}
                {/* <button>Send</button> */}
            </form>

        </>
  )
}

// endpoit має відавати бінарний код
