import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'

// src
import { GradiantBtn } from '../../../assets/muiStyles/Button-style'
import './PostersDetails.scss'
import Folder from '../../../SVG/Folder'
import { getPostersDetails } from '../action'
import ReactPlayer from 'react-player'
import parse from 'html-react-parser'

const PostersDetails = () => {
  const url = window.location.pathname.split('/')
  const eventId = url[2]
  const slug = url[3]
  const isPosterDetail = window.location.href.includes('posters') && window.location.href.includes('posters')

  const [posterDetail, setPosterDetail] = useState(null)
  const [attachments, setAttachments] = useState('')

  const [title] = useState()
  const posterId = window.location.href.split('/').slice(-3)[0].toString()

  useEffect(() => {
    getPostersDetails(eventId, posterId).then(data => {
      setPosterDetail(data)
    })
  }, [posterId, eventId])

  const navigate = useNavigate()

  const handleChange = (event) => {
    if (event.target.value) {
      setAttachments(event.target.value)
    }
  }
  if (isPosterDetail && window.screen.width < 1024) {
    return <DescriptionVideo posterDetail={posterDetail}/>
  } else {
    return (
        <div className="poster-description">
            {
                !!posterDetail &&

                <div
                    className="poster-detail-container"
                >
                    <div className="video-with-title-container">

                        <div className="poster-desc-header">
                            {!!posterDetail?.title && <h3>{posterDetail.title}</h3>}
                            {!!posterDetail?.institution &&
                                <h3 className="poster-clinic">{posterDetail.institution}</h3>}
                            {!!posterDetail?.authors && <p>
                                <strong>Authors:</strong> <span>{posterDetail.authors}</span>
                            </p>}
                        </div>
                        <div className="video-desc-container">
                            <div className="hls-player-poster ">
                                {posterDetail?.video && posterDetail?.video.embed_code
                                  ? <>
                                        {parse(
                                            `<iframe height="100%" width="100%" className="responsive-iframe" src=${posterDetail?.video.embed_code}  allowfullscreen frameborder="0" marginwidth="0" marginheight="0" scrolling="no" allowtransparency="true"></iframe>`
                                        )}
                                    </>
                                  : <ReactPlayer
                                        url={posterDetail?.video?.hls_url}
                                        controls={true}
                                        width="100%"
                                        height="50%"
                                    />}
                                <div className="qsk-container">
                                    <p><span className="span-poster-desc"><strong><FormattedMessage
                                        defaultMessage="Post a question to the author "
                                        id="post-question-text"/></strong></span>
                                        <br/>
                                        <br/>
                                        <FormattedMessage
                                            id="question-text"
                                            defaultMessage="Do you want to ask an author a question? Fell free to pose your question"/>
                                    </p>
                                    <GradiantBtn
                                        sx={{ alignSelf: 'end' }}
                                        onClick={() =>
                                          navigate(`/events/${eventId}/${slug}/questions/`, {
                                            state: { id: posterDetail.id, referrer: 'poster' }
                                          })
                                        }
                                    >
                                        <FormattedMessage id="ask-a-question" defaultMessage="Ask a Question"/>
                                    </GradiantBtn>
                                </div>

                            </div>
                            <div className="video-desc-with-download">

                                <div className="video-desc">
                                    {title && <h4>{title}</h4>}
                                    {posterDetail?.body && posterDetail?.body.map((desc, index) => {
                                      return (
                                                  <p key={index}>{parse(desc?.value)}</p>
                                      )
                                    })}
                                </div>
                               {posterDetail?.attachments.length > 0 &&
                                <div className="download-pdf-container">
                                    <div>

                                        <Folder/>
                                        {posterDetail?.attachments.length > 1
                                          ? <FormControl variant="standard">
                                                <InputLabel
                                                    id="demo-simple-select-standard-label">Attachments</InputLabel>
                                                <Select
                                                    sx={{ mb: 2, minWidth: '120px', width: 'auto' }}
                                                    labelId="demo-simple-select-standard-label"
                                                    id="demo-simple-select-standard"
                                                    value={`${attachments}`}
                                                    onChange={(event) => {
                                                      handleChange(event)
                                                    }}
                                                    label="Attachments"
                                                >
                                                    {posterDetail?.attachments.map((attachment) => {
                                                      return (
                                                            <MenuItem key={attachment.id}
                                                                      value={attachment?.meta?.download_url}>{attachment.title}</MenuItem>
                                                      )
                                                    })
                                                    })
                                                </Select>
                                            </FormControl>
                                          : <p>{posterDetail.attachments[0]?.title}</p>
                                        }
                                    </div>

                                    <GradiantBtn
                                        onClick={() => {
                                          const element = document.createElement('a')
                                          if (posterDetail?.attachments.length === 1) {
                                            element.href = posterDetail?.attachments[0]?.meta?.download_url
                                          } else {
                                            element.href = attachments
                                          }
                                          element.setAttribute('target', '_blank')
                                          document.body.appendChild(element)
                                          element.click()
                                        }}
                                    >

                                        <FormattedMessage id="download"
                                                          defaultMessage="Download"/>
                                    </GradiantBtn>
                                </div>
                               }
                            </div>
                        </div>
                    </div>

                </div>
            }

        </div>
    )
  }
}

export const DescriptionVideo = ({ posterDetail }) => {
  const [orientation, setOrientation] = useState(window?.orientation)
  const [openModal, setOpenModal] = useState(false)
  useEffect(() => {
    window.addEventListener('orientationchange', () => {
      if (window.outerHeight > window.outerWidth) {
        return setTimeout(() => {
          setOrientation(90)
          setOpenModal(true)
        })
      } else {
        return setTimeout(() => {
          setOrientation(0)
          setOpenModal(false)
        })
      }
    })
  }, [])
  return (
        <div className={`${posterDetail?.video && 'hls-player-poster-container'}`}>
            {posterDetail?.video.hls_url
              ? <div className={` ${orientation === 90 && openModal ? 'full-screen' : 'responsive-iframe h100'}`}>
              <ReactPlayer
              url={posterDetail?.video.hls_url}
              controls={true}
              width="100%"
              height="100%"
              />
              </div>
              : <div className={` ${orientation === 90 && openModal ? 'full-screen' : 'responsive-iframe h100'}`}>
                  {parse(
                      `<iframe height="100%"   className=${orientation !== 90 && !openModal && 'responsive-iframe'} src=${posterDetail?.video?.embed_code}  allowfullscreen frameborder="0" marginwidth="0" marginheight="0" scrolling="no" allowtransparency="true"></iframe>`
                  )}
                </div>
            }
        </div>
  )
}

export default PostersDetails
