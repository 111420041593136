import React, { useState } from 'react'
import { TextField } from '@material-ui/core'
import { useLocation, useNavigate } from 'react-router-dom'
import setAuthToken from '../../auth/SetAuthToken'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import InputAdornment from '@mui/material/InputAdornment'
import { toast, ToastContainer } from 'react-toastify'
import Alert from '@mui/material/Alert'
import { FormattedMessage } from 'react-intl'

// src
import './SignInPage.scss'
import { SignIn } from './action'
import { GradiantBtn } from '../../assets/muiStyles/Button-style'

export default function SignInPage () {
  // const [eventId,setEventId] = useContext(EventIdContext)

  const evId = localStorage.getItem('evId')
  const evSlug = localStorage.getItem('slug')

  const navigate = useNavigate()
  const location = useLocation()

  const [credientials, setCredientials] = useState({
    email: '',
    password: ''
  })

  // const [rememberMe, setRememberMe] = useState('false')
  const [error, setError] = useState('')
  const [loader, setLoader] = useState(false)
  const [passwordEye, setPasswordEye] = useState(false)
  const locationEdit = location.pathname.split('/')

  const access_token_expiration = process.env.REACT_APP_ACCESS_TOKEN_EXPIRATION
  const refresh_token_expiration = process.env.REACT_APP_REFRESH_TOKEN_EXPIRATION

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (credientials.email.length <= 0 || credientials.password.length <= 0) {
      return toast.error(<FormattedMessage id="toast-error-required" defaultMessage="Fields are required."/>, {
        position: 'top-center'
      })
    }
    setLoader(true)

    const response = await SignIn(credientials)

    if (response && response.access_token) {
      localStorage.setItem('token', response.access_token)
      localStorage.setItem('rf_token', response.refresh_token)
      localStorage.setItem('userDetail', JSON.stringify(response))
      localStorage.setItem('access_token_expiration', +new Date() + +access_token_expiration)
      localStorage.setItem('refresh_token_expiration', +new Date() + +refresh_token_expiration)

      setAuthToken(response.access_token)
      if (locationEdit[2] === 'editing-profile') {
        return window.location.reload()
      }
      evId !== null
        ? navigate('/events/' + evId + '/' + evSlug + '/agenda/')
        : navigate('/')

      window.location.reload()
    } else {
      toast.error(<FormattedMessage id="toast-error-invalid-credentials"
                                          defaultMessage="invalid credentials!"/>, {
        position: 'top-center'
      })
    }
    setLoader(false)
  }
  const handleChange = (e) => {
    setCredientials({ ...credientials, [e.target.name]: e.target.value })
  }

  return (
        <>
            <ToastContainer/>
            <div className="sign-in-main">
                <form onSubmit={handleSubmit} noValidate>
                    <div className="sign-in-form">
                        <p className="welcome-msg"><FormattedMessage id="welcome-back" defaultMessage="Welcome Back"/>
                        </p>
                        <p className="desc">
                            <FormattedMessage id="sign-in-enter-msg"
                                              defaultMessage="Enter your credentials to access your account"/>
                        </p>
                        <FormattedMessage id="placeholder-enter-email" defaultMessage="Enter your email address">
                            {
                                placeholder =>
                                    <TextField
                                        style={{ marginTop: '50px' }}
                                        error={!!error}
                                        // helperText= 'Please Enter Your Email'
                                        value={credientials.email}
                                        onFocus={() => setError('')}
                                        onChange={(e) => handleChange(e)}
                                        placeholder={`${placeholder}`}
                                        type="email"
                                        name="email"
                                        variant="outlined"
                                        required
                                    />
                            }
                        </FormattedMessage>

                        <FormattedMessage id="placeholder-enter-password" defaultMessage="Enter your Password">
                            {
                                placeholder =>
                                    <TextField
                                        style={{ marginTop: '24px', borderRadius: '8px' }}
                                        error={!!error}
                                        onFocus={() => setError('')}
                                        // helperText= 'Please Enter Your Email'
                                        value={credientials.password}
                                        onChange={(e) => handleChange(e)}
                                        name="password"
                                        // inputProps={{ pattern: "^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$"}}
                                        placeholder={`${placeholder}`}
                                        type={!passwordEye ? 'password' : 'text'}
                                        variant="outlined"
                                        InputProps={{
                                          endAdornment: (
                                                <InputAdornment position="end">
                                                    {passwordEye
                                                      ? (
                                                        <VisibilityIcon
                                                            onClick={() => setPasswordEye(!passwordEye)}
                                                        />
                                                        )
                                                      : (
                                                        <VisibilityOffIcon
                                                            onClick={() => setPasswordEye(!passwordEye)}
                                                        />
                                                        )}
                                                </InputAdornment>
                                          )
                                        }}
                                        required
                                    />
                            }
                        </FormattedMessage>

                        <br/>
                        {error
                          ? (
                            <Alert
                                className="error-message"
                                style={{}}
                                variant="outlined"
                                severity="error"
                            >
                                {error}
                            </Alert>
                            )
                          : (
                              ''
                            )}
                        <div className="sign-in-option">
                            <p
                                className="forgot-pass"
                                onClick={() => navigate('/user/register')}
                            >
                                <FormattedMessage id="register-me" defaultMessage="Not yet registered"/>?

                            </p>

                            <p
                                className="forgot-pass"
                                onClick={() => navigate('/password/forgot')}
                            >
                                <FormattedMessage id="forgot-password" defaultMessage="Forgot Password"/>?

                            </p>
                        </div>
                        <GradiantBtn
                            type="submit"
                            loading={loader}
                            variant="contained"
                        >
                            <FormattedMessage id="sign-in" defaultMessage="Sign In"/>
                        </GradiantBtn>
                    </div>
                    <br/>
                </form>
            </div>
        </>
  )
}
