import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getPostersDetails } from '../action'
import Folder from '../../../SVG/Folder'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { AsqBtn, GradiantBtn } from '../../../assets/muiStyles/Button-style'
import { FormattedMessage } from 'react-intl'
import parse from 'html-react-parser'

const PosterDetailMobile = () => {
  const posterId = window.location.href.split('/').slice(-3)[0].toString()
  const url = window.location.pathname.split('/')

  const eventId = url[2]
  const slug = url[3]

  const [attachments, setAttachments] = useState('')
  const handleChange = (event) => {
    setAttachments(event?.target?.value)
  }

  const navigate = useNavigate()
  const [posterDetail, setPosterDetail] = useState(null)
  useEffect(() => {
    if (eventId && posterId) {
      getPostersDetails(eventId, posterId).then(data => {
        setPosterDetail(data)
      })
    }
  }, [posterId, eventId])
  return (
        <div className="mobile-desc">
            {posterDetail?.body && <div className="mobile-desc-p">
                {posterDetail?.body && posterDetail?.body.map((desc, index) => {
                  return (
                        <p key={index}>{parse(desc?.value)}</p>
                  )
                })}
            </div>}
            <div>
                {!!posterDetail?.attachments.length > 0 &&
                    <div className="download-pdf-container-mobile">
                        <div className="pdf-name-mobile ">

                            <Folder/>
                            {posterDetail?.attachments.length > 1
                              ? <FormControl variant="standard">
                                    <InputLabel id="demo-simple-select-standard-label">Attachments</InputLabel>
                                    <Select
                                        sx={{ mb: 2, minWidth: '120px', width: 'auto' }}
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={attachments}
                                        onChange={(event) => {
                                          handleChange(event)
                                        }}
                                        label="Attachments"
                                    >
                                        {posterDetail?.attachments.map((attachment) => {
                                          return (
                                                <MenuItem key={attachment.id}
                                                          value={attachment.meta.download_url}>{attachment.title}</MenuItem>
                                          )
                                        })}

                                    </Select>

                                </FormControl>
                              : <MenuItem
                                    value={posterDetail?.attachments[0].meta.download_url}>{posterDetail?.attachments[0].title}</MenuItem>
                            }

                        </div>

                        <GradiantBtn
                            fullWidth
                            disabled={posterDetail?.attachments.length === 1 ? !posterDetail?.attachments[0].meta.download_url : !attachments }
                            onClick={() => {
                              if (posterDetail?.attachments.length) {
                                const element = document.createElement('a')
                                if (posterDetail?.attachments.length === 1) element.href = posterDetail?.attachments[0].meta.download_url
                                if (attachments) element.href = attachments
                                element.setAttribute('target', '_blank')
                                document.body.appendChild(element)
                                element.click()
                              }
                            }}>
                            <FormattedMessage id="download"
                                              defaultMessage="Download"/>
                        </GradiantBtn>
                    </div>}
                <div className="qsk-container-mobile">
                    <p><span className="span-poster-desc"><strong><FormattedMessage
                        defaultMessage="Post a question to the author "
                        id="post-question-text"/></strong></span>
                        <br/>
                        <br/>
                        <FormattedMessage
                            id="question-text"
                            defaultMessage="Do you want to ask an author a question? Fell free to pose your question"/>
                    </p>
                    <AsqBtn
                        fullWidth
                        onClick={() =>
                          navigate(`/events/${eventId}/${slug}/questions/`, {
                            state: { id: posterDetail.id, referrer: 'poster' }
                          })
                                        }
                    >
                        <FormattedMessage id="ask-a-question" defaultMessage="Ask a Question"/>
                    </AsqBtn>
                </div>
            </div>

        </div>
  )
}
export default PosterDetailMobile
