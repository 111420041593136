import { useLocation, useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'

// src
import './Chats.scss'
import noAvatar from '../../../images/noAvatar.png'
import { userIsOnline } from '../SendBird/action'

export default function Chats ({ user, sendBird, userMsg }) {
  const navigate = useNavigate()
  const location = useLocation()
  const token = localStorage.getItem('token')
  const [isOnline, setIsOnline] = useState([])
  const [senderId, setSenderId] = useState([])

  const BASE_URL = process.env.REACT_APP_BASE_URL
  const BASE_URI = process.env.REACT_APP_BASE_URI

  useEffect(() => {
    userMsg?.map((userChat) => {
      const member = userChat.members.filter((member) => {
        return member?.userId !== user?.sendbird_user_id
      })
      if (!senderId.includes(member[0]?.userId)) setSenderId(prevArray => [...prevArray, member[0]?.userId])
    })
  }, [userMsg])
  useEffect(() => {
    const ids = senderId.filter(value => value !== undefined)

    if (!ids.includes(undefined)) {
      userIsOnline(sendBird, ids).then(value => {
        setIsOnline(value)
      })
    }
  }, [senderId])

  const navigateToChat = location.pathname.slice(0, location.pathname.length - 6)

  return (
        <div className='private-chat-main'>

            {userMsg && userMsg?.map((userChat) => {
              let imageUrl = ''
              const member = userChat.members.filter((member) => {
                if (member.userId !== user?.sendbird_user_id && member?.plainProfileUrl.includes('http')) {
                  imageUrl = `${BASE_URL + BASE_URI}/user/${member.metaData.django_id}/avatar/?auth_token=${token}`
                } else {
                  imageUrl = noAvatar
                }
                return member.userId !== user?.sendbird_user_id
              })

              return (
                    <div
                        className='private-chat-card'
                        onClick={() => {
                          if (member[0]?.metaData?.django_id) {
                            navigate(`${navigateToChat}participants/chat/${member[0]?.metaData?.django_id}`)
                          } else {
                            navigate(`${navigateToChat}participants/chat/${user?.id}`)
                          }
                        }}
                        key={userChat?.name}>
                        {userChat?.memberCount > 1
                          ? userChat?.members.map((chatUser) => {
                            if (chatUser?.nickname !== user?.full_name && chatUser?.plainProfileUrl.includes('http')) {
                              imageUrl = `${BASE_URL + BASE_URI}/user/${chatUser?.metaData?.django_id}/avatar/?auth_token=${token}`
                              if (!imageUrl) {
                                imageUrl = noAvatar
                              }
                            }
                          })
                          : null
                        }
                        <div className="chat-header-avatar">

                            {isOnline[0] !== 'false'
                              ? isOnline.map((value, index) => {
                                return (
                                  value?.userId === member[0]?.userId
                                    ? <p key={index} className="online-circle" />
                                    : <p key={index} className="offline-circle"/>
                                )
                              })
                              : <p className="offline-circle" />}

                            <img
                                src={imageUrl}
                                alt={ member[0]?.nickname ? member[0]?.nickname : userChat?.members[0].nickname}/>
                        </div>
                        <div className='private-chat-detail'>
                            <div className='private-chat-name'>
                                {userChat?.memberCount > 1

                                  ? userChat.members.map((userName, index) => {
                                    if (userName?.nickname !== user?.full_name) {
                                      return (
                                                <p key={index} className='name'>{
                                                    userName?.nickname
                                                }</p>
                                      )
                                    }
                                  })
                                  : userChat?.members[0].nickname
                                }

                                <p className='time'>{new Date(userChat?.createdAt).getHours()}:{new Date(userChat?.createdAt).getMinutes() < 10
                                  ? '0' + new Date(userChat?.createdAt).getMinutes()
                                  : new Date(userChat?.createdAt).getMinutes()}</p>
                            </div>
                            <div className='private-chat-msg'>
                                <p className='msg' style={{ lineBreak: 'anywhere' }}>{

                                    userChat?.lastMessage?.type
                                      ? userChat?.lastMessage?.type === 'image/jpeg' || userChat?.lastMessage?.type === 'image/png' ? 'Photo' : 'File'
                                      : userChat?.lastMessage?.message.length > 50 ? userChat?.lastMessage?.message.slice(0, 50) + '...' : userChat?.lastMessage?.message
                                }</p>
                                {userChat.unreadMessageCount > 0 &&
                                    <p className='msg-numbers'>{userChat?.unreadMessageCount}</p>}
                            </div>
                        </div>
                    </div>
              )
            })}
        </div>
  )
}
