import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Popover from '@mui/material/Popover'
import Avatar from '@mui/material/Avatar'
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined'
import CloseIcon from '@mui/icons-material/Close'
import PublicIcon from '@mui/icons-material/Public'
import { toast } from 'react-toastify'
import { FormattedMessage } from 'react-intl'

// src
import './Header.scss'
import HeadLogo from './HeadLogo'
import { getAttendeeProfile } from '../../Pages/ParticipantsPage/action'
import { connectToSB, disconnect } from '../../Pages/InboxPage/SendBird/action'
import { refreshAfterTime } from './actions'
import { GradiantBtn, LoginBtn } from '../../assets/muiStyles/Button-style'
import ChevroneRight from '../../SVG/Chevrone-right'
import Briefcase from '../../SVG/Briefcase'
import Vector from '../../SVG/Vector'
import colors from '../../Colors.scss'

const Header = ({ setSendBird }) => {
  const navigate = useNavigate()
  const userDetail = JSON.parse(localStorage.getItem('userDetail'))
  const rf_token = localStorage.getItem('rf_token')
  const access_token_expiration = localStorage.getItem('access_token_expiration')
  const refresh_token_expiration = localStorage.getItem('refresh_token_expiration')

  const [profileDetails, setProfileDetails] = useState()
  const [userName, setUserName] = useState('')
  const [sendBirdHeader, setSendBirdHeader] = useState()
  const [userEmail, setUserEmail] = useState('')
  const [avatar, setAvatar] = useState('')
  const [anchorEl, setAnchorEl] = useState(null)
  const [openHeader, setOpenHeader] = useState(false)
  const [fullName, setFullName] = useState()
  const [refreshLoader, setRefreshLoader] = useState(true)

  const userId = userDetail !== null && userDetail?.user?.pk
  const href = document.location.href.split('/')
  const chatHref = href[href.length - 2]

  useEffect(() => {
    if (!!userDetail && userId) {
      setUserName(
        userDetail !== null &&
                userDetail.user.first_name + ' ' + userDetail.user.last_name)
      setUserEmail(userDetail !== null && userDetail.user.email)
    }
  }, [userDetail, userId])

  if (!!access_token_expiration && refresh_token_expiration > new Date() && access_token_expiration <= new Date() && refreshLoader) {
    setRefreshLoader(false)
    refreshAfterTime(rf_token).then(() => setRefreshLoader(true))
  }

  if (!!refresh_token_expiration && refresh_token_expiration <= new Date() && href[href.length - 1] !== 'sign-in') {
    localStorage.clear()
    localStorage.setItem('refresh_token_expiration', 0)
  }
  if (window.location.href.includes('/sign-in') && refresh_token_expiration === '0') {
    toast(<FormattedMessage id="session-expired-message"
                                defaultMessage="Your session has expired, please login again"/>, {
      toastId: 'session-expired-message'
    })
  }

  const getDetailsAPI = async () => {
    try {
      const response = await getAttendeeProfile(userDetail?.user.pk)
      if (response) {
        setProfileDetails(response.data)
        setAvatar(response?.data?.avatar?.meta?.download_url)
        setFullName(response?.data.full_name.split(' ').join(' '))
      }
    } catch (e) {
      console.log(e + ' error')
    }
  }
  useEffect(() => {
    if (userDetail?.user.pk) {
      getDetailsAPI()
    }
  }, [userDetail?.user.pk])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = async () => {
    try {
      const sb = await connectToSB()
      await disconnect(sb)
    } catch (e) {
      console.log(e)
    }

    localStorage.clear()
    window.location.href = '/'
  }

  useEffect(() => {
    if (chatHref === 'chat' || chatHref === 'inbox' || href.includes('hotline')) {
      if (profileDetails) {
        connectToSB().then(async value => {
          setSendBirdHeader(value)
          setSendBird(value)
          await getAttendeeProfile(userId)
        }).then(async () => {
          const connected = await sendBirdHeader?.connect(profileDetails?.sendbird_user_id, profileDetails?.sendbird_access_token)
          localStorage.setItem('userSb', JSON.stringify(connected))
        })
      }
    } else {
      localStorage.removeItem('userSb')
      disconnect(sendBirdHeader)
    }
  }, [sendBirdHeader, profileDetails, chatHref])

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  return (
        <div className="header-page-container">
            <div className="header-page-main">
                <HeadLogo/>
                <div className="desktop-head">
                    <div className="header-page-actions">
                         <p><a href={'mailto: hotline@ecc-app.com'}><FormattedMessage id="support-us" defaultMessage="Support"/></a></p>
                         <p><a href={'https://ecc-app.com/Complete_Guide_ECC-App.pdf'} target={'_blank'} rel="noreferrer"><FormattedMessage id="guide-us" defaultMessage="Guide"/></a></p>
                         <p><a href={'https://www.colorectalsurgery.eu/frontend/index.php?page_id=2691'} target={'_blank'} rel="noreferrer"><FormattedMessage id="privacy-us" defaultMessage="Privacy Policy"/></a></p>
                        {userId
                          ? (
                            <>
                                <div className="header-login">
                                    <Avatar alt="dp" style={{ width: '64px', height: '64px' }}
                                            src={avatar || ''} onClick={handleClick}/>
                                    <div onClick={handleClick} style={{
                                      display: 'flex',
                                      justifyContent: 'start',
                                      flexDirection: 'column',
                                      paddingTop: '6px'
                                    }}>
                                        <h4 className='user-name-near-avatar'>{fullName}</h4>
                                        <p className='userEmail'>{userEmail}</p>

                                    </div>

                                    <div>
                                        <Popover
                                            id={id}
                                            open={open}
                                            anchorEl={anchorEl}
                                            onClose={handleClose}
                                            anchorOrigin={{
                                              vertical: 'bottom',
                                              horizontal: 'left'
                                            }}
                                        >
                                            <Avatar src={avatar === null ? '' : avatar}
                                                    sx={{ width: 100, height: 100, margin: '13px 0 13px 30px' }}/>
                                            <div className='login-context'>
                                                <div className='context-title'>
                                                    {fullName?.length > 0 && (
                                                        <div className='userName'>
                                                            {fullName}
                                                        </div>
                                                    )}
                                                    {userEmail.length > 0 && (
                                                        <div className='userEmail'>
                                                            {userEmail}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className='context-list'>
                                                    {profileDetails?.job_title?.length > 0 && (
                                                        <div className='list-container'>
                                                            <Briefcase height={24} width={24} color={colors.color_blue}/>
                                                            <p id='job-title-inner'>{profileDetails?.job_title}</p>
                                                        </div>
                                                    )}
                                                    {profileDetails?.institution?.length > 0 && (
                                                        <div className='list-container'>
                                                            <Vector height={24} width={24}/>
                                                            <p>{profileDetails?.institution}</p>
                                                        </div>
                                                    )}
                                                    {profileDetails?.country_code?.length === 0
                                                      ? null
                                                      : <div className='list-container'>
                                                            <PublicIcon/>
                                                            <p>{profileDetails?.country_code}</p>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className='context-out'>
                                                <button
                                                    style={{
                                                      margin: '8px 30px 0',
                                                      borderRadius: '8px',
                                                      padding: '10px, 16px, 10px, 16px'

                                                    }}
                                                    onClick={() => {
                                                      navigate('/user/editing-profile/')
                                                      setAnchorEl(null)
                                                      setOpenHeader(false)
                                                    }}
                                                    className="edit-profile"><FormattedMessage id="edit-profile"
                                                                                               defaultMessage="Edit profile"/>
                                                </button>
                                                <GradiantBtn
                                                  className="context-out-btn"
                                                  sx={{
                                                    margin: '10px 30px',
                                                    padding: '10px 15px',
                                                    borderRadius: '8px'
                                                  }}
                                                             onClick={handleLogout}

                                                > <FormattedMessage id="logout" defaultMessage="Logout"/></GradiantBtn>
                                            </div>
                                        </Popover>
                                    </div>
                                </div>
                            </>
                            )
                          : (
                            <>
                                <LoginBtn
                                    onClick={() => navigate('/sign-in')}
                                >
                                    <FormattedMessage id="login" defaultMessage="Login"/>
                                </LoginBtn>
                            </>
                            )}
                    </div>
                </div>

                <div className="mobile-head">
                    <div className="mobile-header-side">
                        {openHeader
                          ? (
                            <CloseIcon
                                onClick={() => setOpenHeader(false)}
                                fontSize="large"
                            />
                            )
                          : (
                            <MenuOutlinedIcon
                                onClick={() => setOpenHeader(true)}
                                fontSize="large"
                            />
                            )}
                    </div>

                </div>

            </div>
            <div className="mobile-head2">
                {openHeader ? (
                    <div className="header-page-actions">

                        {userId ? (
                            <>
                                <div className="header-login">
                                    <div className="header-login-container">
                                    <Avatar alt="dp" src={avatar === null ? '' : avatar}/>
                                    <div className="header-popover">
                                        <div className="header-popover-text">
                                            <p className="userName">{userName}</p>
                                            <p className="userEmail">{userEmail}</p>
                                        </div>
                                        <div onClick={() => {
                                          setOpenHeader(false)
                                          navigate('/user/editing-profile/')
                                        }}>
                                            <ChevroneRight height={24} width={24}/>
                                        </div>
                                    </div>

                                </div>

                                {/* <p><FormattedMessage id="privacy-policy" defaultMessage="Privacy Policy"/></p> */}
                                {/* <p><FormattedMessage id="about-us" defaultMessage="About Us"/></p> */}
                                {/* <p><FormattedMessage id="contact-us" defaultMessage="Contact Us"/></p> */}
                                {/* <p><FormattedMessage id="action-link" defaultMessage="Action Link"/></p> */}
                                  <p><a href={'mailto: hotline@ecc-app.com'}><FormattedMessage id="support-us" defaultMessage="Support"/></a></p>
                         <p><a href={'https://ecc-app.com/Complete_Guide_ECC-App.pdf'} target={'_blank'} rel="noreferrer"><FormattedMessage id="guide-us" defaultMessage="Guide"/></a></p>
                         <p><a href={'https://www.colorectalsurgery.eu/frontend/index.php?page_id=2691'} target={'_blank'} rel="noreferrer"><FormattedMessage id="privacy-us" defaultMessage="Privacy Policy"/></a></p>
                                </div>

                                <GradiantBtn
                                    fullWidth
                                    onClick={handleLogout}
                                >
                                    <FormattedMessage id="logout" defaultMessage="Logout"/>
                                </GradiantBtn>
                            </>
                        ) : (
                            <div className="header-logout-container">
                                <div>
                                    {/* <p><FormattedMessage id="privacy-policy" defaultMessage="Privacy Policy"/></p> */}
                                    {/* <p><FormattedMessage id="about-us" defaultMessage="About Us"/></p> */}
                                    {/* <p><FormattedMessage id="contact-us" defaultMessage="Contact Us"/></p> */}
                                    {/* <p><FormattedMessage id="action-link" defaultMessage="Action Link"/></p> */}
                                  <p><a href={'mailto: hotline@ecc-app.com'}><FormattedMessage id="support-us" defaultMessage="Support"/></a></p>
                         <p><a href={'https://ecc-app.com/Complete_Guide_ECC-App.pdf'} target={'_blank'} rel="noreferrer"><FormattedMessage id="guide-us" defaultMessage="Guide"/></a></p>
                         <p><a href={'https://www.colorectalsurgery.eu/frontend/index.php?page_id=2691'} target={'_blank'} rel="noreferrer"><FormattedMessage id="privacy-us" defaultMessage="Privacy Policy"/></a></p>

                                </div>
                                <div>

                                {/* <AsqBtn */}
                                {/*        sx={{ marginBottom: '20px' }} */}
                                {/*    fullWidth */}

                                {/*    // onClick={() => { */}
                                {/*    //     navigate("/sign-in") */}
                                {/*    //     setOpenHeader(false) */}
                                {/*    // }} */}
                                {/* > */}
                                {/*    <FormattedMessage id="sign-up" defaultMessage="Sign up"/> */}
                                {/* </AsqBtn> */}
                                 <GradiantBtn
                                    fullWidth
                                    onClick={() => {
                                      navigate('/sign-in')
                                      setOpenHeader(false)
                                    }}
                                >
                                    <FormattedMessage id="login" defaultMessage="Login"/>
                                </GradiantBtn>
                                </div>

                            </div>
                        )}
                    </div>
                ) : (
                  ''
                )}
            </div>
        </div>
  )
}
export default Header
