import React, { useEffect, useMemo, useState } from 'react'
// import { SocialShimmer } from 'react-content-shimmer'
import { CircularProgress } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import Paper from '@mui/material/Paper'

// src
import './EventAgenda.scss'
import { GetAgendaFilter, GetAgendDays, GetEventAgenda } from './action'
import Days, { isToDay } from '../../components/Days/Days'
import ArrowPlayer from '../../SVG/ArrowPlayer'
import EventAgendaDetail from './EventAgendaDetail/EventAgendaDetail'
import UserPlaceholder from '../../SVG/UserPlaceholder'
import colors from '../../Colors.scss'
import AgendaSvg from '../../SVG/AgendaSvg'
import Questions from '../../SVG/Questions'
import ContextHelp from '../../components/ContextHelp/ContextHelp'

export default function EventAgenda ({ setRefreshEvent, setRefreshNearEvent, refreshNearEvent }) {
  const navigate = useNavigate()

  const IdEventDesc = localStorage.getItem('IdEventDesc')

  const eventIdDesc = window.location.href.split('/').slice(-2)[0]
  const isEventAgendaDetail = window.location.href.includes('agenda') && (IdEventDesc === eventIdDesc)
  const eventId = window.location.pathname.split('/')[2]

  const updateAgenda = process.env.REACT_APP_TIME_FOR_UPDATE_AGENDA
  const speakerView = process.env.REACT_APP_SPEAKER_VIEW
  const chair = process.env.REACT_APP_CHAIRS_VIEW

  const checkSelect = localStorage.getItem('checkSelect')
  const slug = localStorage.getItem('slug')
  const onceScrollAgenda = localStorage.getItem('onceScrollAgenda')

  const [agendaEvents, setAgendaEvents] = useState([])
  const [loader, setLoader] = useState(false)
  const [loading, setLoading] = useState(false)
  const [agendaDays, setAgendaDays] = useState([])
  const [selectedDate, setSelectedDate] = useState(false)

  const GetEventAgendaAPI = async () => {
    const response1 = await GetAgendDays(eventId)
    if (response1 !== undefined) {
      setAgendaDays(response1.data.items)
    }
  }

  useEffect(() => {
    GetEventAgendaAPI()
  }, [eventId])

  const GetAgendaFilterAPI = async (day_id) => {
    setLoading(true)
    const response = await GetAgendaFilter(eventId, day_id)
    if (JSON.stringify(response.data.items) !== JSON.stringify(agendaEvents)) {
      setAgendaEvents(response.data.items)
    }

    for (const nextDay of agendaDays) {
      if (+nextDay.date.split('-').slice(-1)[0] === +new Date().getDate() && +nextDay.id === +day_id && !refreshNearEvent) {
        const value = response.data.items
        const event = value?.find((event) => {
          const year = +event.day.split('-')[0]
          const month = +event.day.split('-')[1]
          const day = +event.day.split('-')[2]
          const starHour = +event.start.split(':')[0]
          const starMinutes = +event.start.split(':')[1]
          const nowTimeSeconds = new Date(new Date())
          const startTimeSeconds = new Date(year, month - 1, day, starHour, starMinutes)

          if (startTimeSeconds - nowTimeSeconds > 0 && day === +new Date().getDate()) {
            return event
          }
        })
        if (!event) {
          const response = await GetAgendaFilter(eventId, nextDay.id + 1)
          const value = response.data.items

          if (response.data.items.length === 0) {
            setRefreshNearEvent(false)
          } else {
            setRefreshNearEvent(value[0])
          }
        } else {
          setRefreshNearEvent(event)
        }
      }
    }
    setLoading(false)

    return response.data.items
  }

  useEffect(() => {
    if (!selectedDate && !!agendaDays.length > 0) {
      setLoader(true)
      isToDay(agendaDays).then(value => {
        setSelectedDate(value?.toString())
        if (!value) {
          localStorage.setItem('checkSelect', false)
          const day = agendaDays.find(dayValue => {
            if (dayValue.date.split('-').slice(-1)[0] > new Date().getDate() && dayValue.date.split('-').slice(-2)[0] === new Date().getMonth()) {
              return dayValue
            } else {
              if (dayValue.date.split('-').slice(-2)[0] > new Date().getMonth()) {
                return dayValue
              } else return false
            }
          })
          if (day.date.split('-').slice(-1)[0] >= new Date().getDate()) {
            GetAgendaFilterAPI(+day.id).then(value => {
              setRefreshNearEvent(value[0])
            })
          }
        }
      }).then(() => setLoader(false))
    }
  }, [agendaDays])

  useMemo(() => {
    if (!loader && !!selectedDate && !loading) {
      GetAgendaFilterAPI(selectedDate.toString())
      localStorage.setItem('checkSelect', true)
    }

    if (agendaEvents.length === 0 && !selectedDate && checkSelect === 'false' && !loading) {
      setLoading(true)
      GetEventAgenda(eventId).then(value => {
        setAgendaEvents(value.data.items)
      }).then(() => setLoading(false))
    }
    localStorage.removeItem('onceScrollAgenda')
  }, [selectedDate, checkSelect])

  useEffect(() => {
    const intervalId = setInterval(async () => {
      await GetEventAgendaAPI().then(async () => {
        if (!!selectedDate && !loading) {
          GetAgendaFilterAPI(selectedDate.toString())
        }
      })
    }, updateAgenda)
    return () => {
      clearInterval(intervalId)
    }
  }, [selectedDate, loading])
  const [showModalHelper, setShowModalHelper] = useState({
    open: false,
    event: {}
  })

  return (
        <div className="event-agenda-main">
            <div className="event-agenda-inside">
                <div className="title-main">
                    <div className="title relative">
                      <AgendaSvg width='30' height='30'/><p><FormattedMessage id="event-agenda" defaultMessage="Event Agenda"/>&nbsp;
                            <span
                        onClick={(e) => {
                          setShowModalHelper({
                            open: !showModalHelper.open,
                            event: e.currentTarget
                          })
                        }}>
                    <Questions />
                    {showModalHelper && <ContextHelp helpText={{ id: 3, what: 'An interactive event calendar with Speaker Profiles, Download Buttons, Ask Questions, Vote & Poll and much more.' }} open={showModalHelper.open} anchorEl={showModalHelper.event} setShowModalHelper={setShowModalHelper}/>}
                  </span>
                    </p>
                    </div>
                </div>
                <Days setSelectedDate={setSelectedDate} eventsDay={agendaDays}
                />
              <span className='event-agenda-helper-mobile'
                  onClick={(e) => {
                    setShowModalHelper({
                      open: !showModalHelper.open,
                      event: e.currentTarget
                    })
                  }}>
                    <Questions width='25' height='25'/>
                {showModalHelper && <ContextHelp helpText={{ id: 3, what: 'An interactive event calendar with Speaker Profiles, Download Buttons, Ask Questions, Vote & Poll and much more.' }} open={showModalHelper.open} anchorEl={showModalHelper.event} setShowModalHelper={setShowModalHelper}/>}
                  </span>
                <div className={`event-agenda-cards-main ${isEventAgendaDetail && window.screen.width < 1024 && 'agenda-main-desc-mobile'}`}>
                    {loading && <CircularProgress className="circularProgress"/>}
                    {!loading &&
                        agendaEvents.map((event) => {
                          const nameEvent = event.title.replace(/ /g, '-')
                          let agendaActive
                          let firstElement = ''
                          const year = +event.day.split('-')[0]
                          const month = +event.day.split('-')[1]
                          const day = +event.day.split('-')[2]
                          const starHour = +event.start.split(':')[0]
                          const endHour = +event.end.split(':')[0]
                          const starMinutes = +event.start.split(':')[1]
                          const endMinutes = +event.end.split(':')[1]
                          const nowTimeSeconds = new Date(new Date())
                          const startTimeSeconds = new Date(year, month - 1, day, starHour, starMinutes)
                          const endTimeSeconds = new Date(year, month - 1, day, endHour, endMinutes)
                          if (nowTimeSeconds >= startTimeSeconds && nowTimeSeconds <= endTimeSeconds) {
                            agendaActive = true
                            setRefreshEvent(event)
                            localStorage.setItem('IdEventDescName', event.title)
                            localStorage.setItem('IdEventDesc', event.id)
                            localStorage.setItem('speakerMain', JSON.stringify(event?.speakers[0]))
                            firstElement = 'scroll-first-element-agenda'
                          } else {
                            if (+IdEventDesc === +event.id) {
                              localStorage.removeItem('IdEventDesc')
                              localStorage.removeItem('IdEventDescName')
                              setRefreshEvent(false)
                            }
                            agendaActive = false
                          }
                          if (!!firstElement && !onceScrollAgenda) {
                            setTimeout(() => {
                              const element = document.getElementsByClassName('scroll-first-element-agenda')[0]
                              const mainDiv = document.getElementsByClassName('event-agenda-cards-main')[0]
                              mainDiv?.scrollTo(0, element.offsetTop - element.offsetHeight - 40)
                              localStorage.setItem('onceScrollAgenda', true)
                            })
                            setTimeout(() => {
                              localStorage.removeItem('onceScrollAgenda')
                            }, 1000)
                          }
                          return (
                                <Paper
                                    key={event.id}
                                    elevation={0}
                                    sx={{
                                      backgroundColor: agendaActive ? colors.highlighted_agenda : colors.color_cards_background,
                                      color: agendaActive ? colors.color_white : colors.color_black,
                                      border: `3px solid ${agendaActive ? colors.highlighted_agenda : colors.color_cards_background}`,
                                      width: '100%',
                                      borderRadius: event?.sponsor ? '20px 20px 5px 5px' : '20px'
                                    }}
                                    className={`agenda-cards-main wight-percent-100 ${firstElement}`}

                                >
                                    <div className={`event-agenda-card ${isEventAgendaDetail && window.screen.width < 1024 && 'agenda-card'}`}>

                                        <div className={`agenda-time  ${agendaActive ? 'border-white' : 'border-silver'}`} >
                                            {event.start.split(':')[0] +
                                                ':' +
                                                event.start.split(':')[1]}
                                            {agendaActive && <div className="stream-icon-link-agenda cursor-pointer"
                                                                  onClick={() => {
                                                                    navigate(`/events/${event?.id}/${nameEvent}/agenda/`)
                                                                  }}>
                                            </div>}
                                          {event?.video && event.video.status !== 'NOT_AUTHORIZED' && <div className='cursor-pointer'
                                              onClick={() => {
                                                localStorage.setItem('videoUrlHls', event?.video?.hls_url)
                                                localStorage.setItem('videoUrlEmbed', event?.video?.embed_code)
                                                localStorage.setItem('videoTitle', event?.title)
                                                localStorage.setItem('navigateToAgenda', 1)

                                                if (event?.speakers.length === 1) {
                                                  localStorage.setItem('speaker', JSON.stringify(event?.speakers[0]))
                                                } else {
                                                  localStorage.removeItem('speaker')
                                                }
                                                navigate(`/events/${eventId}/${slug}/${event?.id}/video/`)
                                              }
                                          }>
                                            <ArrowPlayer color={!agendaActive ? colors.color_play_button : colors.color_white}
                                                         colorArrow={agendaActive ? colors.color_white : colors.color_black}/>
                                          </div>}

                                        </div>

                                        <div className="event-agenda"
                                             onClick={() => {
                                               localStorage.setItem('agendaActive', agendaActive)
                                               navigate(`/events/${eventId}/${slug}/agenda/${event.id}/${nameEvent}/`, {
                                                 state: { id: event.id }
                                               })
                                             }}>
                                            <p className="title">{event.title}</p>
                                            <p className="para-text">
                                                {event.description}
                                            </p>
                                          {
                                              chair === 'text' && <div className='chair-agenda'>
                                                { !!event.chairs.length > 0 && <FormattedMessage id='chair' defaultMessage='Chair'/>}
                                                <div
                                                    className={agendaActive ? 'color-text-highlighted_agenda_text_color' : 'color-text-color_silver_icons'}
                                                >
                                                  <div className='event-agenda-speakers-text-wrap'>
                                                  {!!event.chairs.length && event.chairs.slice(0, 4).map((sp, index) => {
                                                    return <span key={sp?.id}>{ sp?.full_name} {index !== event.chairs.slice(0, 4).length - 1 && ', '} </span>
                                                  })}
                                                  {event.chairs.length > 4 && <span className={`${agendaActive ? 'span-white' : 'span-black'}`}> + {event.chairs.length - 4 } more</span> }
                                                </div>
                                                </div>

                                              </div>

                                          }
                                          {
                                              chair === 'avatar_text' && <div className='chair-agenda'>
                                                { !!event.chairs.length > 0 && <FormattedMessage id='chair' defaultMessage='Chair'/> }
                                                <div className="event-agenda-speakers-avatar_text-container">
                                                  <div className='event-agenda-speakers-text-wrap'>
                                                  {!!event.chairs.length && event.chairs.slice(0, 4).map((sp) => {
                                                    return <div key={sp?.id} className="event-agenda-speakers-avatar_text-half">
                                                      <div className="event-agenda-speakers-avatar_text">
                                                        {sp?.avatar?.download_url ? <img
                                                            src={sp?.avatar?.download_url}
                                                            alt="speaker"
                                                            title={sp?.full_name}
                                                            width="36px"
                                                            height="36px"/> : <UserPlaceholder width='28' height="28"/>}
                                                        <p>{sp?.full_name}</p>

                                                      </div>
                                                    </div>
                                                  })}
                                                  </div>
                                                  {event.chairs.length > 4 && <span className={`${agendaActive ? 'span-white' : 'span-black'}`}> + {event.chairs.length - 4 } more</span> }
                                                </div>
                                              </div>

                                          }
                                          {
                                              chair === 'avatar' && <div className='chair-agenda' >
                                                {!!event.chairs.length > 0 && <FormattedMessage id='chair' defaultMessage='Chair'/> }
                                                <div className="event-agenda-speakers  size-event-agenda-speakers-avatar" >
                                                {!!event.chairs.length && event.chairs.slice(0, 8).map((sp) => {
                                                  return sp?.avatar?.download_url ? <img
                                                      key={sp.id}
                                                      src={sp?.avatar?.download_url}
                                                      alt="speaker"
                                                      title={sp?.full_name}
                                                      width="36px"
                                                      height="36px"
                                                  /> : <UserPlaceholder width='28' height="28"/>
                                                })}
                                                {event.chairs.length > 8 && <span className={`${agendaActive ? 'span-white' : 'span-black'}`}> + {event.chairs.length - 8 } more</span> }
                                              </div>
                                              </div>

                                          }
                                          { !!event.chairs.length > 0 && chair === 'placeholder_text' && <FormattedMessage id='chair' defaultMessage='Chair'/>}
                                          {event?.chairs?.length > 0 &&
                                              chair === 'placeholder_text' && <div className="chair-card-container">

                                                {event?.chairs?.length > 0 && event?.chairs.slice(0, 2).map(
                                                  (sp) =>
                                                            <div className="chair-card cursor-pointer"
                                                                 key={sp.id}
                                                                 onClick={() =>
                                                                   navigate(
                                                                         `/events/${eventId}/${slug}/participants/profile/`,
                                                                         {
                                                                           state: sp.id
                                                                         }
                                                                   )
                                                                 }
                                                            >
                                                              <div className='placeholder_text-event-agenda'>
                                                                {sp?.avatar?.download_url ? <img
                                                                    key={sp.id}
                                                                    src={sp?.avatar?.download_url}
                                                                    alt="speaker"
                                                                    title={sp?.full_name}
                                                                    width="36px"
                                                                    height="36px"
                                                                /> : <UserPlaceholder width='28' height="28"/>}
                                                                <p>{sp?.full_name}</p>
                                                              </div>
                                                            </div>
                                                )}
                                                {event.chairs.length > 4 && <span className={`${agendaActive ? 'span-white' : 'span-black'}`}> + {event.chairs.length - 4 } more</span> }

                                              </div>
                                          }
                                          {
                                              speakerView === 'avatar' &&
                                              <div className="chair-agenda">

                                                {!!event.speakers.length > 0 && <FormattedMessage id='speakers' defaultMessage='Speakers'/>}
                                                <div className="event-agenda-speakers size-event-agenda-speakers-avatar">
                                                {!!event.speakers.length && event.speakers.slice(0, 8).map((sp) => {
                                                  return sp?.avatar?.download_url ? <img
                                                      key={sp.id}
                                                      src={sp?.avatar?.download_url}
                                                      alt="speaker"
                                                      title={sp?.full_name}
                                                      width="36px"
                                                      height="36px"
                                                  /> : <UserPlaceholder width='28' height="28"/>
                                                })}
                                                {event.speakers.length > 8 && <span className={`${agendaActive ? 'span-white' : 'span-black'}`}> + {event.speakers.length - 8 } more</span> }
                                              </div>
                                              </div>

                                          }
                                          {
                                              speakerView === 'avatar_text' &&
                                              <div className='chair-agenda'>

                                                {!!event.speakers.length > 0 && <FormattedMessage id='speakers' defaultMessage='Speakers'/>}
                                                <div className="event-agenda-speakers-avatar_text-container">
                                                  <div className='event-agenda-speakers-text-wrap event-agenda-speakers-placeholder_text-wrap'>

                                                {!!event.speakers.length && event.speakers.slice(0, 4).map((sp) => {
                                                  return <div key={sp?.id} className="event-agenda-speakers-avatar_text-half">
                                                  <div className="event-agenda-speakers-avatar_text">
                                                    {sp?.avatar?.download_url ? <img
                                                        src={sp?.avatar?.download_url}
                                                        alt="speaker"
                                                        title={sp?.full_name}
                                                        width="36px"
                                                        height="36px"/> : <UserPlaceholder width='28' height="28"/>}
                                                  <p>{sp?.full_name}</p>

                                                 </div>
                                                  </div>
                                                })}
                                                  </div>
                                                {event.speakers.length > 4 && <span className={`${agendaActive ? 'span-white' : 'span-black'}`}> + {event.speakers.length - 4 } more</span> }
                                              </div>
                                              </div>

                                          }
                                          {
                                              speakerView === 'placeholder_text' &&
                                              <div className='chair-agenda'
                                              >

                                                {!!event.speakers.length > 0 && <FormattedMessage id='speakers' defaultMessage='Speakers'/>}
                                                <div className="event-agenda-speakers-avatar_text-container">
                                                  <div className='event-agenda-speakers-text-wrap event-agenda-speakers-placeholder_text-wrap '>

                                                {!!event.speakers.length && event.speakers.slice(0, 4).map((sp) => {
                                                  return <div key={sp?.id} className="event-agenda-speakers-avatar_text-half">
                                                    <div className="event-agenda-speakers-placeholder_text">
                                                      {sp?.avatar?.download_url ? <img
                                                          key={sp.id}
                                                          src={sp?.avatar?.download_url}
                                                          alt="speaker"
                                                          title={sp?.full_name}
                                                          width="36px"
                                                          height="36px"
                                                      /> : <UserPlaceholder width='28' height="28"/>}
                                                      <p>{sp?.full_name}</p>

                                                    </div>
                                                  </div>
                                                })}
                                                  </div>

                                                  {event.speakers.length > 4 && <span className={`${agendaActive ? 'span-white' : 'span-black'}`}> + {event.speakers.length - 4 } more</span> }
                                              </div>
                                              </div>

                                          }
                                          {
                                              speakerView === 'text' &&
                                              <div className="chair-agenda">
                                                {!!event.speakers.length > 0 && <FormattedMessage id='speakers' defaultMessage='Speakers'/>}
                                                <div
                                                    className={agendaActive ? 'color-text-highlighted_agenda_text_color' : 'color-text-color_silver_icons'}
                                                >
                                                <div className='event-agenda-speakers-text-wrap'>

                                                {!!event.speakers.length && event.speakers.slice(0, 4).map((sp, index) => {
                                                  return <span key={sp?.id}>{ sp?.full_name} {index !== event.speakers.slice(0, 4).length - 1 && ', '} </span>
                                                })}
                                                {event.speakers.length > 4 && <span className={`${agendaActive ? 'span-white' : 'span-black'}`}> + {event.speakers.length - 4 } more</span> }
                                              </div>
                                                </div>

                                              </div>

                                          }
                                        </div>
                                    </div>
                                  {event?.sponsor &&
                                      <div className="sponsor-img-div sponsors-agenda ">
                                        <img className="pointer"
                                             onClick={() => {
                                               const element = document.createElement('a')
                                               element.href = event?.sponsor?.url
                                               element.setAttribute('_blank')
                                               document.body.appendChild(element)
                                               element.click()
                                             }}
                                             src={event?.sponsor?.download_url}
                                             alt={event?.sponsor?.title}/>
                                      </div>
                                  }
                                </Paper>
                          )
                        })}
                </div>
            </div>
            {
                isEventAgendaDetail && window.screen.width < 1024 && <EventAgendaDetail/>
            }
        </div>
  )
}
