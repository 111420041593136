import Axios from '../../auth'
import setAuthToken from '../../auth/SetAuthToken'

const BASE_URL = process.env.REACT_APP_BASE_URL
const BASE_URI = process.env.REACT_APP_BASE_URI
const eightHour = process.env.REACT_APP_EIGHT_HOUR

export const refreshAfterTime = async (refreshToken) => {
  await Axios.post(`${BASE_URL + BASE_URI}/auth/token/refresh/`, {
    refresh: refreshToken
  })
    .then((response) => {
      localStorage.setItem('token', response.data.access)
      localStorage.setItem('access_token_expiration', +new Date() + +eightHour)
      setAuthToken(response.data.access)
    })
    .catch((error) => {
      console.log('errrr', error)
    })
}
