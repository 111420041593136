import React, { useEffect, useState } from 'react'

import './Exhibition.scss'
import { getSponsor } from '../Posters/action'

const Exhibition = () => {
  const [sponsors, setSponsors] = useState([])
  const eventId = location.pathname.split('/')[2]

  useEffect(() => {
    getSponsor(eventId).then(value => {
      setSponsors(value.data.items)
    })
  }, [eventId])

  return (
        <div className="exhibition-main">

            {sponsors.length > 0 && sponsors.map((exhibition) => {
              return (
                    <div key={exhibition.id}>
                        <a target="_blank" href={exhibition?.url} rel="noreferrer">
                            <img src={exhibition?.image?.download_url
                            } alt={exhibition?.title}/></a>

                    </div>
              )
            })}
        </div>
  )
}

export default Exhibition
