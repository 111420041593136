import React from 'react'
import Global from '../../SVG/Global'
import OnSiteStatus from '../../SVG/OnSiteStatus'

const Presence = ({ status }) => {
  return (
        <div className="presence-main">
            <div>{status !== 'IN_PERSON' ? <Global color="#12B76A" width="14" height="14"/> : <OnSiteStatus width={12} height={12} color={'#4C6FFF'}/>}</div>
            <div><p>{status !== 'IN_PERSON' ? 'Remote' : 'On-site'}</p></div>
        </div>
  )
}

export default Presence
