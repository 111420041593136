import React from 'react'
import colors from '../Colors.scss'

const PollSVG = ({ width = '24', height = '24' }) => {
  return (
     <svg
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.863 0H16.1359C19.877 0 21.989 2.1219 22 5.86301V16.137C22 19.877 19.877 22 16.1359 22H5.863C2.1219 22 0 19.877 0 16.137V5.86301C0 2.1219 2.1219 0 5.863 0ZM11.0539 17.446C11.528 17.446 11.9229 17.094 11.9669 16.621V5.41201C12.0109 5.07101 11.847 4.72891 11.55 4.54301C11.2409 4.35601 10.8669 4.35601 10.571 4.54301C10.2729 4.72891 10.109 5.07101 10.1409 5.41201V16.621C10.197 17.094 10.5919 17.446 11.0539 17.446ZM16.115 17.446C16.577 17.446 16.9719 17.094 17.028 16.621V13.013C17.0599 12.6599 16.896 12.331 16.5979 12.144C16.302 11.957 15.928 11.957 15.62 12.144C15.3219 12.331 15.158 12.6599 15.202 13.013V16.621C15.246 17.094 15.6409 17.446 16.115 17.446ZM6.8409 16.621C6.7969 17.094 6.402 17.446 5.9279 17.446C5.4549 17.446 5.0589 17.094 5.016 16.621V9.01998C4.983 8.67791 5.1469 8.33801 5.445 8.15101C5.7409 7.96401 6.116 7.96401 6.413 8.15101C6.7089 8.33801 6.875 8.67791 6.8409 9.01998V16.621Z"
          fill={colors.color_cards_background}
        />
        </svg>

  )
}

export default PollSVG
