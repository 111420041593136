import React from 'react'

const Folder = () => {
  return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M29.3346 14.6665V22.6665C29.3346 27.9998 28.0013 29.3332 22.668 29.3332H9.33464C4.0013 29.3332 2.66797 27.9998 2.66797 22.6665V9.33317C2.66797 3.99984 4.0013 2.6665 9.33464 2.6665H11.3346C13.3346 2.6665 13.7746 3.25317 14.5346 4.2665L16.5346 6.93317C17.0413 7.59984 17.3346 7.99984 18.668 7.99984H22.668C28.0013 7.99984 29.3346 9.33317 29.3346 14.6665Z" stroke="#282828" strokeOpacity="0.75" strokeWidth="2" strokeMiterlimit="10"/>
        </svg>

  )
}

export default Folder
