import React, { useEffect, useState } from 'react'
import Chats from './Chats/Chats'
import PublicChat from './PublicChat/PublicChat'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { GroupChannelHandler } from '@sendbird/chat/groupChannel'
import { FormattedMessage, useIntl } from 'react-intl'

// src
import './InboxPage.scss'
import { getAttendeeProfile } from '../ParticipantsPage/action'
import { connectToSB, ConnectUserToSendBird, getAllChannels } from './SendBird/action'
import ChatIcon from '../../SVG/ChatIcon'
import colors from '../../Colors.scss'
import Questions from '../../SVG/Questions'
import ContextHelp from '../../components/ContextHelp/ContextHelp'

export default function InboxPage () {
  const intl = useIntl()

  const [selectedTab, setSelectedTab] = useState('pr-chat')
  const [showMenu] = useState(true)
  const [value, setValue] = useState('pr-chat')
  const userDetail = JSON.parse(localStorage.getItem('userDetail'))
  const [profileDetails, setProfileDetails] = useState()
  const [countChannel, setCountChannel] = useState(0)
  const [connectedUserToSendBird, setConnectedUserToSendBird] = useState()
  const [refreshMessage, setRefreshMessage] = useState(false)
  const [userMsg, setUserMsg] = useState()
  const [sendBird, setSendBird] = useState()

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue)
    setValue(newValue)
  }

  const channelHandler = new GroupChannelHandler({
    onMessageUpdated: (channel, message) => {
      setRefreshMessage(message)
    },

    onMessageReceived: (channel, message) => {
      setRefreshMessage(message)
    },
    onMessageDeleted: (channel, message) => {
      setRefreshMessage(message)
    }

  })
  sendBird?.groupChannel.addGroupChannelHandler(profileDetails?.userId + '', channelHandler)

  const getDetailsAPI = async () => {
    try {
      const response = await getAttendeeProfile(userDetail?.user.pk)
      if (!response) {
        // await RefreshToken()
        // await getDetailsAPI()
      } else {
        setProfileDetails(response.data)
      }
    } catch (e) {
      console.log(e + ' error')
    }
  }

  useEffect(() => {
    if (!sendBird) {
      connectToSB().then(value => setSendBird(value))
    }
  }, [sendBird])

  useEffect(() => {
    if (userDetail?.user.pk) {
      getDetailsAPI()
    }
  }, [userDetail?.user.pk])

  useEffect(() => {
    if (!connectedUserToSendBird && profileDetails && sendBird) {
      ConnectUserToSendBird(profileDetails, sendBird).then(value => setConnectedUserToSendBird(value))
    }
  }, [profileDetails, sendBird])

  // eslint-disable-next-line no-unused-vars
  const prChat = () => {
    return (
            <>
                <FormattedMessage id="public-chat" defaultMessage="Public Chat" />
                <FormattedMessage id="private-chat" defaultMessage="Private Chat" />
            </>
    )
  }

  useEffect(() => {
    setTimeout(() => {
      if (sendBird) {
        try {
          getAllChannels(sendBird).then(value => {
            setCountChannel(value?.length)
            setUserMsg(value)
          })
        } catch (e) {
          console.log(e)
        }
      }
    }, 1000)
  }, [sendBird, refreshMessage])

  const [showModalHelper, setShowModalHelper] = useState({
    open: false,
    event: {}
  })

  return (

        <>
            {/* <ToastContainer /> */}

            <div className="inbox-main">
                {showMenu && (
                    <div className="conversation-main">
                        <div className="title-main">
                            <div className="title">
                              <ChatIcon color={colors.color_cards_background} width='30' height='30'/><p><FormattedMessage
                                    id="conversations" defaultMessage="Conversations"/>&nbsp;
                            <span
                        onClick={(e) => {
                          setShowModalHelper({
                            open: !showModalHelper.open,
                            event: e.currentTarget
                          })
                        }}>
                    <Questions/>
                    {showModalHelper && <ContextHelp helpText={{ id: 3, what: 'You can switch between a Public Chat where all participants and speakers write, comment and discuss topics, and you can privately chat to one of the participants.' }} open={showModalHelper.open} anchorEl={showModalHelper.event} setShowModalHelper={setShowModalHelper}/>}
                  </span>
                            </p>
                            </div>
                        </div>
                        <div className="chat-tabs">
                            <Tabs
                                TabIndicatorProps={{ style: { background: '#4C6FFF' } }}
                                onChange={handleChangeTab}
                                textColor="inherit"
                                indicatorColor="primary"
                                value={`${value}`}
                                sx={{
                                  width: '100%'
                                }}
                            >
                              <span className='inbox-helper-mobile'
                                  onClick={(e) => {
                                    setShowModalHelper({
                                      open: !showModalHelper.open,
                                      event: e.currentTarget
                                    })
                                  }}>
                    <Questions width='25' height='25'/>
                                {showModalHelper && <ContextHelp helpText={{ id: 3, what: 'You can switch between a Public Chat where all participants and speakers write, comment and discuss topics, and you can privately chat to one of the participants.' }} open={showModalHelper.open} anchorEl={showModalHelper.event} setShowModalHelper={setShowModalHelper}/>}
                  </span>

                                <Tab value="pub-chat"
                                     className="pub-chat"
                                     label={intl.formatMessage({
                                       defaultMessage: 'Public Chat',
                                       id: 'public-chat'
                                     })}
                                     sx={{
                                       width: '50%',
                                       maxWidth: '50%'
                                     }}
                                />
                                <Tab className='pr-chat'
                                    value="pr-chat" label={`${intl.formatMessage({
                                    defaultMessage: 'Private Chat',
                                    id: 'private-chat'
                                })} ${countChannel ? `(${countChannel})` : ''}`}
                                     sx={{
                                       width: '50%',
                                       maxWidth: '50%'
                                     }}/>
                            </Tabs>
                        </div>
                        {selectedTab === 'pr-chat'
                          ? <Chats sendBird={sendBird} userMsg={userMsg} user={profileDetails}
                                                            setCountChannel={setCountChannel}/>
                          : <PublicChat sb={sendBird} userInbox={connectedUserToSendBird}/>}
                    </div>
                )}
                {/* <SideBar onChange={handleChange} selectedprop={"inbox"} /> */}
            </div>

        </>
  )
}
