import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField'
import Popover from '@mui/material/Popover'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { CircularProgress } from '@mui/material'
import Modal from '@mui/material/Modal'
import { useLocation } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import { FormattedMessage } from 'react-intl'
import 'react-toastify/dist/ReactToastify.css'

// src
import './AskQuestion.scss'
import { DeleteQuestion, GetQuestionsList, POSTQuestion, POSTQuestionWithPoster, POSTQuestionWithSlot } from './action'
import DeleteSvg from './DeleteSvg'
import { deleteBtn, inputStyle } from './mui-askQuestion'
import { GradiantBtn } from '../../assets/muiStyles/Button-style'
import colors from '../../Colors.scss'
import QuestionSVG from '../../SVG/QuestionSVG'
import Questions from '../../SVG/Questions'
import ContextHelp from '../../components/ContextHelp/ContextHelp'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 250,
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4
}

const AskQuestion = () => {
  const location = useLocation()

  const slot_id = location.state !== null ? location.state.id : 0
  const ref = location.state !== null ? location.state.referrer : ''
  const [referrer, setReferrer] = useState(ref)
  const [slotId, setSlotId] = useState(slot_id !== null ? slot_id : 0)
  const eventId = window.location.pathname.split('/')[2]
  const [questions, setQuestions] = useState([])
  const [deleteId, setDeleteId] = useState(0)
  const [loader, setLoader] = useState(true)
  const [anchorEl, setAnchorEl] = useState(null)
  const [hover, setHover] = useState(false)
  const [postQuestion, setPostQuestion] = useState('')
  const [loadbtn, setLoadBtn] = useState(true)
  const [modal, setModal] = useState(false)
  const postQuestionBoolean = postQuestion.length > 999

  const GetQuestionsAPI = async () => {
    setLoader(true)
    if (eventId !== null) {
      const response = await GetQuestionsList(eventId)
      if (response === undefined) {
        // await RefreshToken();
        GetQuestionsAPI()
      } else setQuestions(response.data.items)
    }
    setLoader(false)
  }
  useEffect(() => {
    GetQuestionsAPI()
  }, [])

  const handleDeleteQuestion = async () => {
    setModal(false)
    setAnchorEl(null)

    const response = await DeleteQuestion(eventId, deleteId)
    if (response === undefined) {
      // await RefreshToken();
      handleDeleteQuestion()
    } else {
      GetQuestionsAPI()
      toast(
                <div style={{ display: 'flex', gap: '10px' }}>
                    <DeleteSvg/>
                    <p><FormattedMessage id="toast-question-deleted" defaultMessage="Question was successfully deleted"/></p>
                </div>,
                {
                  position: 'top-right',
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined
                }
      )
    }
    setLoader(false)
  }

  const PostQuestionAPI = async () => {
    setLoader(true)
    // console.log('ref' + referrer)
    // console.log('slot_id' + slot_id)
    if (referrer === 'agenda') {
      const response = await POSTQuestionWithSlot(
        eventId,
        postQuestion,
        slotId
      )
      if (response === undefined) {
        // RefreshToken();
        PostQuestionAPI()
      } else {
        setPostQuestion('')
        GetQuestionsAPI()
        setSlotId(0)
        setReferrer('')
      }
    } else if (ref === 'poster') {
      const response = await POSTQuestionWithPoster(
        eventId,
        postQuestion,
        slotId
      )
      if (response === undefined) {
        // RefreshToken();
        PostQuestionAPI()
      } else {
        setPostQuestion('')
        GetQuestionsAPI()
        setSlotId(0)
        setReferrer('')
      }
    } else {
      const response = await POSTQuestion(eventId, postQuestion)
      if (response === undefined) {
        // RefreshToken();
        PostQuestionAPI()
      } else {
        setPostQuestion('')
        GetQuestionsAPI()
      }
    }
    setLoader(false)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    PostQuestionAPI()
  }

  useEffect(() => {
    if (loadbtn) {
      if (postQuestion.length > 0) setLoadBtn(false)
    } else {
      if (postQuestion.length === 0) setLoadBtn(true)
    }
  }, [postQuestion])

  const scrollToBottom = (node) => {
    node[0].scrollTop = node[0].scrollHeight
  }

  useEffect(() => {
    const element = document.getElementsByClassName('question-cards-main')
    scrollToBottom(element)
  }, [questions])

  const classesInput = inputStyle()
  const paperPopupDelete = deleteBtn()

  const [showModalHelper, setShowModalHelper] = useState({
    open: false,
    event: {}
  })

  return (
        <>
            <div className="question-main">
                <div className="question-inside">
                    <div className="title-main">
                        <div className="title">
                          <QuestionSVG width='30' height='30'/><p><FormattedMessage id="ask-questions" defaultMessage="Ask Questions"/>&nbsp;
                            <span
                        onClick={(e) => {
                          setShowModalHelper({
                            open: !showModalHelper.open,
                            event: e.currentTarget
                          })
                        }}>
                    <Questions />
                    {showModalHelper && <ContextHelp helpText={{ id: 3, what: 'Question box at different sections. Directly address speaker, chair, author or ECC-App moderator.' }} open={showModalHelper.open} anchorEl={showModalHelper.event} setShowModalHelper={setShowModalHelper}/>}
                  </span></p>
                        </div>
                    </div>
                    <div className="question-cards-main">
                        {loader && <CircularProgress className={'circularProgress'}/>}
                        {!loader &&
                            questions.map((question) => {
                              return (
                                    < div key={question.id} >
                                        <div
                                            onMouseEnter={() => setHover(true)}
                                            onMouseLeave={() => setHover(false)}
                                            className="question"

                                        >
                                            <Popover
                                                style={{ boxShadow: '2px 3px 6px rgba(0, 0, 0, 0.1)' }}
                                                anchorOrigin={{
                                                  vertical: 'top',
                                                  horizontal: 'right'
                                                }}
                                                transformOrigin={{
                                                  vertical: 'top',
                                                  horizontal: 'right'
                                                }}
                                                id={anchorEl ? 'simple-popover' : undefined}
                                                className={`${paperPopupDelete.border} delete-popover`}
                                                open={Boolean(anchorEl)}
                                                anchorEl={anchorEl}

                                                onClose={() => setAnchorEl(null)}
                                                sx={{ borderRadius: '16px' }}
                                            >
                                                <div

                                                    className="question-delete-main delete-popover-popup"
                                                    onClick={() => setModal(true)}
                                                >
                                                    <p className="question-delete-text "><FormattedMessage id="delete"
                                                                                                           defaultMessage="Delete"/>
                                                    </p>
                                                    <DeleteSvg/>
                                                </div>
                                            </Popover>
                                            {question.writable
                                              ? (
                                                <p
                                                    className="dot"
                                                    // questionId={question.id}
                                                    onClick={(e) => {
                                                      setDeleteId(
                                                        e.currentTarget.getAttribute('questionId')
                                                      )
                                                      setAnchorEl(e.currentTarget)
                                                    }}
                                                >
                                                    {hover
                                                      ? (
                                                        <svg
                                                            width="15"
                                                            height="3"
                                                            viewBox="0 0 15 3"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <ellipse
                                                                cx="13.5"
                                                                cy="1.5"
                                                                rx="1.5"
                                                                ry="1.5"
                                                                transform="rotate(-180 13.5 1.5)"
                                                                fill="#6B6F7A"
                                                            />
                                                            <ellipse
                                                                cx="7.5"
                                                                cy="1.5"
                                                                rx="1.5"
                                                                ry="1.5"
                                                                transform="rotate(-180 7.5 1.5)"
                                                                fill="#6B6F7A"
                                                            />
                                                            <ellipse
                                                                cx="1.5"
                                                                cy="1.5"
                                                                rx="1.5"
                                                                ry="1.5"
                                                                transform="rotate(-180 1.5 1.5)"
                                                                fill="#6B6F7A"
                                                            />
                                                        </svg>
                                                        )
                                                      : (
                                                        <span
                                                            style={{
                                                              cursor: 'none',
                                                              color: colors.color_cards_background,
                                                              width: '10px'
                                                            }}
                                                        >
                            .
                          </span>
                                                        )}
                                                </p>
                                                ) : (
                                                  ''
                                                )}
                                            <p className="question-text">{question?.question}</p>
                                            <div className="answered-time">
                                                {question.answered && (
                                                    <p className="answered"><FormattedMessage id="answered-uppercase"
                                                                                              defaultMessage="ANSWERED"/>
                                                    </p>
                                                )}{' '}
                                                <p>
                                                    <FormattedMessage id="sent"
                                                                      defaultMessage="sent"/> @ {question.created_at.split('.')[0].split('T')[1]}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                              )
                            })}
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="ask-question-field">
                            <FormattedMessage id="placeholder-write-question"
                                              defaultMessage="Write your question here..."
                                              description="Write your question here...">
                                {
                                    placeholder =>
                                        <TextField
                                            style={{ width: '100%' }}
                                            // className="input-ask-question"
                                            multiline
                                            className={classesInput.border}
                                            rows={window.screen.width <= 1024 ? 2 : 6}
                                            maxRows={window.screen.width <= 1024 ? 2 : 6}
                                            value={postQuestion}
                                            onChange={(e) => setPostQuestion(e.target.value)}
                                            variant="outlined"
                                            error={postQuestionBoolean}
                                            helperText={
                                                postQuestion.length > 999
                                                  ? 'Characters Limit exceeds 1000'
                                                  : '                             '
                                            }
                                            placeholder={`${placeholder}` }
                                            inputProps={{ maxLength: 1000 }}
                                            required
                                        />
                                }
                            </FormattedMessage>
                             <span className='asq-helper-absolute'
                                   onClick={(e) => {
                                     setShowModalHelper({
                                       open: !showModalHelper.open,
                                       event: e.currentTarget
                                     })
                                   }}>
                    <Questions color={colors.color_grey_shade}/>
                                {showModalHelper && <ContextHelp helpText={{
                                  id: 3,
                                  what: 'Question box at different sections. Directly address speaker, chair, author or ECC-App moderator.'
                                }} open={showModalHelper.open} anchorEl={showModalHelper.event}
                                                                 setShowModalHelper={setShowModalHelper}/>}
                  </span>
                        </div>
                        {/* <p className="guideline"> */}
                        {/*    <FormattedMessage id="ask-question-guideline" defaultMessage="Please make sure to follow community guidelines while posting a */}
                        {/*    question, Thanks!"/> */}

                        {/* </p> */}
                        <GradiantBtn
                            type="submit"
                            className={loadbtn && 'disb'}
                            loading={loader}
                            variant="contained"
                            size="large"
                            fullWidth
                            disabled={loadbtn}
                        >
                            <FormattedMessage id="send-question" defaultMessage="Send Question"/>
                        </GradiantBtn>
                    </form>
                </div>
            </div>
            <Modal
                open={modal}
                onClose={() => setModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="modal-box">
                    <DeleteSvg/>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        <FormattedMessage id="delete-selected-question" defaultMessage="Delete selected question?"/>
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <FormattedMessage id="ask-question-sure-delete-question"
                                          defaultMessage="Are you sure you want to delete this question."/>
                    </Typography>
                    <hr/>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography
                            onClick={() => {
                              setAnchorEl(null)
                              setModal(false)
                            }}
                            color="primary"
                            className="modal-cancel-btn"
                        >
                            {' '}
                            <FormattedMessage id="cancel" defaultMessage="Cancel"/>
                        </Typography>
                        <Typography
                            onClick={() => handleDeleteQuestion()}
                            style={{ color: 'red' }}
                            className="modal-delete-btn"
                        >
                            <FormattedMessage id="delete" defaultMessage="Delete"/>
                        </Typography>
                    </div>
                </Box>
            </Modal>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                theme="colored"
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>
  )
}
export default AskQuestion
