import React, { useEffect, useState } from 'react'
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined'
import CircularProgress from '@mui/material/CircularProgress'
import { FormattedMessage } from 'react-intl'
import { useLocation, useNavigate } from 'react-router-dom'
import { Avatar } from '@mui/material'

// src
import './ParticipantProfile.scss'
import { getAttendeeProfileEvent } from '../action'
import { GradiantBtn } from '../../../assets/muiStyles/Button-style'
import AddressSVG from '../../../SVG/AddressSVG'
import colors from '../../../Colors.scss'
import InstitutionSVG from '../../../SVG/InstitutionSVG'
import MarkerSVG from '../../../SVG/MarkerSVG'

export default function ParticipantProfile () {
  const navigate = useNavigate()
  const location = useLocation()

  const userDetail = JSON.parse(localStorage.getItem('userDetail'))

  const url = window.location.pathname.split('/')
  const eventId = url[2]
  const slug = url[3]
  const profileId = location.state
  const [profileData, setProfileData] = useState()
  const [loader, setLoader] = useState(true)

  const getFilterAttendeesAPI = async () => {
    setLoader(true)
    const response = await getAttendeeProfileEvent(profileId, eventId)
    if (response === undefined) {
      // RefreshToken();
      getFilterAttendeesAPI()
    } else setProfileData(response.data)

    setLoader(false)
  }
  useEffect(() => {
    getFilterAttendeesAPI()
  }, [])

  return (
        <>
            <div className="participant-profile-main">
                {loader && <CircularProgress className={'circularProgress'}/>}
                {!loader && (
                    <div className="participant-profile-inside">
                        <div className="participants-title-main-profile">
                            <div className="participants-title-profile">
                                <ArrowBackOutlinedIcon className="cursor-pointer arrow" onClick={() => navigate(-1)}/>

                            </div>
                            {/* <div> */}
                            {/*    <MoreVertOutlinedIcon onClick={() => console.log("side")} /> */}
                            {/* </div> */}
                        </div>
                        <div className="participant-profile-detail">
                            <Avatar
                                className="participants-profile-img"
                                alt="dp"
                                src={
                                    profileData !== null && profileData?.avatar?.download_url
                                }
                                sx={{ width: 100, height: 100 }}
                            />
                            {/* <img
              height="156px"
              width="156px"
              src={profileData !== null && profileData.avatar?.meta.download_url}
              alt="dp"
            /> */}
                            <p className="user-name">{profileData?.full_name}</p>
                            <p className="user-email">{profileData?.email}</p>

                        </div>
                        <div className="participant-address-detail">
                            {profileData?.job_title !== '' && (
                                <>
                                    <div className='participant-detail'>
                                      <AddressSVG width={25} height={24} color={colors.color_blue}></AddressSVG>
                                        <p className="mT2px">{profileData?.job_title}</p>
                                    </div>
                                    <br/>
                                </>
                            )}
                            {profileData?.institution !== '' && (
                                <>
                                    <div className='participant-detail'>
                                        <InstitutionSVG width={25} height={24} color={colors.color_blue}/>
                                        <p className="mT2px">{profileData?.institution}</p>
                                    </div>
                                    <br/>
                                </>
                            )}
                            {!!profileData?.city && !!profileData?.country && (
                                <div className='participant-detail'>
                                    <MarkerSVG width={25} height={24} color={colors.color_blue}></MarkerSVG>
                                    <p className="mT2px">{profileData?.city}, {profileData?.country}</p>
                                </div>
                            )}
                            {!!profileData?.city && !profileData?.country && (
                                <div className='participant-detail'>
                                    <MarkerSVG width={25} height={24} color={colors.color_blue}></MarkerSVG>
                                    <p className="mT2px">{profileData?.city}</p></div>
                            )}
                            {!profileData?.city && !!profileData?.country && (
                                <div className='participant-detail'>
                                    <MarkerSVG width={25} height={24} color={colors.color_blue}></MarkerSVG>
                                    <p className="mT2px"> {profileData?.country}</p></div>
                            )}

                        </div>
                        <div className="participant-profile-detail">
                            <p className="user-desc">{profileData.biography}</p>
                        </div>
                        <div className="participant-option">
                            <GradiantBtn
                                // className="center-btn"
                                style={{ padding: 12 }}
                                variant="contained"
                                onClick={() => {
                                  if (profileData?.id !== userDetail?.user.pk) {
                                    navigate(`/events/${eventId}/${slug}/participants/chat/${profileData.id}`)
                                  } else {
                                    navigate(`/events/${eventId}/${slug}/inbox/`)
                                  }
                                }}
                            >
                                <p className="mT2px"><FormattedMessage id="start-chat" defaultMessage="Start Chat"/></p>
                            </GradiantBtn>

                        </div>
                    </div>
                )}
            </div>
        </>
  )
}
