import { makeStyles } from '@material-ui/core'

export const inputStyle = makeStyles({
  border: {
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: 'black',
      borderRadius: ' 8px'
    }
  }
})
export const deleteBtn = makeStyles({
  border: {
    '& .MuiPaper-root ': {
      border: '1px solid black',
      borderRadius: '8px'
    },
    '& .MuiPaper-root :hover ': {
      color: 'red'
    }

  }
})
