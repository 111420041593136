import React from 'react'
import logo from '../../images/logo.png'
import { useNavigate } from 'react-router-dom'

export default function HeadLogo () {
  const navigate = useNavigate()
  return (
      <div className='logo-container'
           onClick={() => {
             localStorage.removeItem('evId')
             navigate('/')
           }}

      >
        <img src={logo} alt="logo" height={56} width={56}/>
        <h2>European Colorectal Congress 2022</h2>
      </div>
  )
}
