import React from 'react'
import colors from '../Colors.scss'

const ParticipantsSvg = ({ width = '30', height = '30' }) => {
  return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.6549 6.34988C14.6549 9.87493 12.0587 12.6997 8.81921 12.6997C5.58085 12.6997 2.98358 9.87493 2.98358 6.34988C2.98358 2.82487 5.58085 0 8.81921 0C12.0587 0 14.6549 2.82487 14.6549 6.34988ZM0 19.8899C0 16.6267 4.06264 15.8103 8.81921 15.8103C13.6017 15.8103 17.6385 16.6548 17.6385 19.9205C17.6385 23.1836 13.5757 24 8.81921 24C4.0368 24 0 23.1555 0 19.8899ZM17.0081 6.465C17.0081 8.26008 16.5126 9.93507 15.6437 11.3264C15.5533 11.4695 15.6331 11.6624 15.7905 11.6931C16.0089 11.7327 16.2331 11.7569 16.4621 11.7621C18.7401 11.8272 20.7843 10.2315 21.349 7.82824C22.1862 4.26235 19.7298 1.06057 16.6007 1.06057C16.2613 1.06057 15.9361 1.09891 15.6191 1.16917C15.5757 1.17939 15.5286 1.20239 15.5052 1.24328C15.4746 1.29565 15.4969 1.36337 15.5275 1.40809C16.468 2.84288 17.0081 4.58941 17.0081 6.465ZM20.7808 14.2697C22.3119 14.5955 23.3181 15.2587 23.7349 16.2259C24.0884 17.018 24.0884 17.9379 23.7349 18.73C23.0974 20.2273 21.0402 20.7091 20.2407 20.8329C20.0751 20.8585 19.9423 20.7052 19.96 20.5237C20.3686 16.374 17.1197 14.4064 16.279 13.9541C16.2437 13.9324 16.2355 13.9017 16.239 13.8813C16.2414 13.8685 16.2567 13.8481 16.2837 13.8443C18.1025 13.806 20.0586 14.0781 20.7808 14.2697Z"
            fill={colors.color_cards_background}
        />
        </svg>
  )
}

export default ParticipantsSvg
