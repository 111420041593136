import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.scss'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import { IntlProvider } from 'react-intl'
// import { EventId } from "./ContextAPI/EventId";
// import { EventIdContext } from "./ContextAPI/EventId";

const language = 'en'

const loadJSON = (file, callback) => {
  const xobj = new XMLHttpRequest()
  xobj.overrideMimeType('application/json')

  xobj.open('GET', file, true)

  xobj.onreadystatechange = () => {
    if (xobj.readyState === 4 && xobj.status === 200) {
      callback(JSON.parse(xobj.responseText))
    }
  }
  xobj.send(null)
}

const root = ReactDOM.createRoot(document.getElementById('root'))

loadJSON('/translations.json', data => {
  if (language === 'en ') {
    localStorage.setItem('en', 'en')
  } else {
    localStorage.removeItem('en')
  }
  root.render(
    // <EventId>

        <IntlProvider locale="en" messages={data[language]}>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </IntlProvider>
        // </EventId>
  )
})
