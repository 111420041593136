import axios from 'axios'

const BASE_URL = process.env.REACT_APP_BASE_URL
const BASE_URI = process.env.REACT_APP_BASE_URI
const token = localStorage.getItem('token')

export const getPosters = async (eventId, searchValue = '', sortBy = '', order = '', limit = '', offset = '') => {
  try {
    if (!searchValue?.length > 0) {
      limit = 100
      const { data } = await axios.get(`${BASE_URL + BASE_URI}/base/events/${eventId}/posters/${sortBy && `?order=${order}`}${sortBy}${+limit !== 0 ? `&limit=${limit}` : ''}${offset && `&offset=${offset}`}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return data
    } else {
      const { data } = await axios.get(`${BASE_URL + BASE_URI}/base/events/${eventId}/posters/?search=${searchValue}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return data
    }
  } catch (e) {
    console.log(e.message)
  }
}
export const getPostersDetails = async (eventId, posterId) => {
  try {
    const { data } = await axios.get(`${BASE_URL + BASE_URI}/base/events/${eventId}/posters/${posterId}/`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    return data
  } catch (e) {
    console.log(e.message)
  }
}

export const getSponsor = async (eventId) => {
  try {
    const result = await axios.get(`${BASE_URL + BASE_URI}/base/events/${eventId}/exhibitors/`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    return result
  } catch (e) {
    console.log(e.message)
  }
}
export const getSponsorAdvertisement = async (eventId) => {
  try {
    const result = await axios.get(`${BASE_URL + BASE_URI}/base/events/${eventId}/sponsors/`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    return result
  } catch (e) {
    console.log(e.message)
  }
}
