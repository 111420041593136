import React, { useEffect, useState } from 'react'
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { CardActionArea, CardMedia, Select, Tab, Tabs, TextareaAutosize, TextField } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import { toast, ToastContainer } from 'react-toastify'

// rsc
import { addAvatarUser, changeAvatarUser, getCountries, getDataUser, putDataUser } from './action'
import { GradiantBtn } from '../../assets/muiStyles/Button-style'
import ChangePassword from '../../components/ChangePassword'
import SignInPage from '../SignInPage'
import './EditingProfilePage.scss'
import Edit from '../../SVG/Edit'
import noAvatar from '../../images/noAvatar.png'
import Global from '../../SVG/Global'
import OnSiteStatus from '../../SVG/OnSiteStatus'

const EditingProfilePage = () => {
  const token = localStorage.getItem('token')

  const [credentials, setCredentials] = useState()
  const [countries, setCountries] = useState()
  const [countryValue, setCountryValue] = useState('country')
  const [deleteAvatar, setDeleteAvatar] = useState(false)
  const [avatar, setAvatar] = useState(false)
  const [userDataFromDb, setUserDataFromDb] = useState()
  const [refreshData, setRefreshData] = useState()
  const [loader, setLoader] = useState(false)
  const [tabsValue, setTabsValue] = useState('')
  const navigate = useNavigate()
  const intl = useIntl()

  // Change status
  const handleChangeTabs = (e, newValue) => {
    setTabsValue(newValue)
    setCredentials({ ...credentials, attendee_type: newValue })
  }

  // Set persons info
  const handleChange = async (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value })
  }

  // Get Country
  useEffect(() => {
    getDataUser().then(value => {
      setUserDataFromDb(value)
      setCredentials(value)
      setCountryValue(value?.country ? value?.country : 'country')
      setTabsValue(value?.attendee_type)
    })
    getCountries().then(value => setCountries(value?.items))
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (JSON.stringify(userDataFromDb) === JSON.stringify(credentials) && !avatar?.avatar_file && !deleteAvatar) {
      toast.info(<FormattedMessage id="toast-info-edit-data"
                                         defaultMessage="Please edit your data."/>,
      { position: 'top-center' }
      )
      setLoader(false)
    }

    if (!credentials.avatar && !deleteAvatar && avatar.avatar_file) {
      setLoader(true)

      await addAvatarUser(avatar?.avatar_file).then(value => {
        if (value?.id) {
          const userData = { ...credentials, avatar_id: value?.id }
          putDataUser(userData).then(value => {
            if (value) {
              toast.success(<FormattedMessage id="toast-info-change-success"
                                                            defaultMessage="Your changes were successfully saved."/>, {
                position: 'top-center',
                onClose: () => window.location.reload(true)
              })
              setAvatar({ ...avatar, avatar_file: null })
              setRefreshData(!refreshData)
            }
          })
        }
      })
      setLoader(false)
    }

    if ((JSON.stringify(userDataFromDb) === JSON.stringify(credentials)) && deleteAvatar && !avatar.avatar_file) {
      setLoader(true)

      const userData = { ...credentials, avatar_id: null }
      putDataUser(userData).then(value => {
        if (value) {
          toast.success(<FormattedMessage id="toast-info-change-success"
                                                    defaultMessage="Your changes were successfully saved."/>, {
            position: 'top-center',
            onClose: () => window.location.reload(true)
          })
          setRefreshData(!refreshData)
          setDeleteAvatar(false)
        }
      })
      setLoader(false)
    }

    if (credentials?.avatar && avatar?.avatar_file) {
      setLoader(true)

      if (avatar?.avatar_file) {
        await changeAvatarUser(avatar?.avatar_file).then(value => {
          const userData = { ...credentials, avatar_id: value?.id }
          putDataUser(userData).then(value => {
            if (value) {
              toast.success(<FormattedMessage id="toast-info-change-success"
                                                            defaultMessage="Your changes were successfully saved."/>, {
                position: 'top-center',
                onClose: () => window.location.reload(true)
              })
              setAvatar({ ...avatar, avatar_file: null })
              setRefreshData(!refreshData)
            }
          })
        })
      }
      setLoader(false)
    }

    if (!avatar?.avatar_file && !deleteAvatar && !(JSON.stringify(userDataFromDb) === JSON.stringify(credentials))) {
      setLoader(true)

      putDataUser(credentials).then(value => {
        if (value) {
          toast.success(<FormattedMessage id="toast-info-change-success"
                                                    defaultMessage="Your changes were successfully saved."/>, {
            position: 'top-center',
            onClose: () => window.location.reload(false)
          })
          setRefreshData(!refreshData)
        }
      })
      setLoader(false)
    }

    const rf_token = localStorage.getItem('rf_token')
    const token = localStorage.getItem('token')

    localStorage.setItem('userDetail', JSON.stringify({
      access_token: token,
      refresh_token: rf_token,
      user: { ...credentials, pk: credentials.id }
    }))
    setLoader(false)
  }

  if (token) {
    return (

            <div className="editing-profile-main-container">
                <ToastContainer/>
                <div className="edit-profile-header">
                    <div className="arrow-back cursor-pointer" onClick={() => navigate(-1)}>
                        <ArrowBackOutlinedIcon/><span> <FormattedMessage
                        id="Back" defaultMessage="Back"/></span>
                    </div>
                    <h1><FormattedMessage id="edit-your-profile" defaultMessage="Edit your profile"/></h1>
                </div>

                <div className="edit-profile-wraper">
                    <div className="container-header">
                        <div className="container-introduce">
                            <p>
                                <FormattedMessage id="introduce-yourself" defaultMessage="Introduce yourself"/>
                            </p>
                            <span>
                                <FormattedMessage id="description-introduce"
                                                  defaultMessage="Build your networking among the members of our platform"/>
                            </span>
                        </div>

                        <hr/>
                        <form
                            onSubmit={handleSubmit}
                        >
                            <div className="editing-fields-container">
                                <div className="add-avatar-container">
                                    <div className="avatar-action-container">
                                        <div className="add-avatar">
                                            <FormControl
                                                sx={{
                                                  width: '100%',
                                                  height: '100%'
                                                }}
                                            >
                                                {((!credentials?.avatar && !avatar?.avatar_file) || deleteAvatar) &&
                                                    <div><img className="avatar"
                                                              src={noAvatar} alt="Avatar"/></div>
                                                }
                                                {deleteAvatar
                                                  ? <img src={noAvatar} alt="No avatar"/>
                                                  : credentials?.avatar?.download_url
                                                    ? <CardActionArea
                                                            sx={{
                                                              width: '100%',
                                                              height: '100%'
                                                            }}
                                                        >
                                                            <CardMedia
                                                                className="avatar-editing-profile"
                                                                accept="image/*"
                                                                component="img"
                                                                alt="avatar"
                                                                image={avatar?.avatar_url
                                                                  ? avatar?.avatar_url
                                                                  : credentials?.avatar?.download_url}
                                                                title="comp"
                                                            />
                                                        </CardActionArea>
                                                    : avatar?.avatar_url
                                                      ? <CardActionArea
                                                                sx={{
                                                                  width: '100%',
                                                                  height: '100%'
                                                                }}>
                                                                <CardMedia
                                                                    className="avatar-editing-profile"
                                                                    accept="image/*"
                                                                    component="img"
                                                                    alt="avatar"
                                                                    image={avatar?.avatar_url
                                                                      ? avatar?.avatar_url
                                                                      : credentials?.avatar?.download_url}
                                                                    title="comp"
                                                                />
                                                            </CardActionArea>
                                                      : ''}

                                            </FormControl>
                                            <div className="avatar-action">
                                                <div className="cursor-pointer"
                                                >
                                                    <div onClick={() => {
                                                      const input = document.createElement('input')
                                                      input.type = 'file'
                                                      input.accept = 'image/png, image/jpeg'
                                                      input.click()
                                                      input.addEventListener('change', (e) => {
                                                        const avatar = e.target.files[0]
                                                        setAvatar({
                                                          ...avatar,
                                                          avatar_file: e.target.files[0],
                                                          avatar_name: avatar.name,
                                                          avatar_url: URL.createObjectURL(e.target.files[0])
                                                        })
                                                        setDeleteAvatar(false)
                                                      })
                                                    }}>
                                                        <Edit width={21} height={21}/>
                                                    </div>
                                                </div>
                                                {/* <div className="cursor-pointer" */}
                                                {/*     onClick={() => { */}
                                                {/*       setDeleteAvatar(true) */}
                                                {/*     }} */}
                                                {/* > */}
                                                {/*    <Trash width={21} height={21}/> */}
                                                {/* </div> */}

                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="fields-container">
                                    <div className="field-tabs-container">
                                        <p><FormattedMessage id="participant-type"
                                                             defaultMessage="What is your participant type?"/></p>
                                        <Tabs
                                            value={tabsValue}
                                            onChange={handleChangeTabs}

                                            sx={{
                                              '& .MuiTabs-indicator': { backgroundColor: '#4C6FFF' },
                                              '& .MuiTab-root': { color: '#6B6F7ABF' },
                                              '& .Mui-selected': { color: 'black' }
                                            }}
                                        >
                                            <Tab value="IN_PERSON" label={<p style={{ color: tabsValue === 'IN_PERSON' ? 'black' : '#6B6F7ABF' }}><OnSiteStatus width={12} height={12} color={tabsValue === 'IN_PERSON' ? '#4C6FFF' : '#6B6F7ABF'}/> {intl.formatMessage({
                                              id: 'on-site',
                                              defaultMessage: 'On-site'
                                            })}</p>}

                                            sx={{ width: '50%' }}/>
                                            <Tab value="ONLINE" label={<p style={{ color: tabsValue === 'ONLINE' ? 'black' : '#6B6F7ABF' }}><Global width={12} height={12} color={tabsValue === 'ONLINE' ? '#12B76A' : '#6B6F7ABF'}/> {
                                                 intl.formatMessage({
                                                   id: 'remote',
                                                   defaultMessage: 'Remote'
                                                 })
                                            }</p>}
                                                 sx={{ width: '50%' }}
                                            />
                                        </Tabs>
                                    </div>
                                    <div className="first-last-name-container ">
                                        <FormattedMessage id="first-name"
                                                          defaultMessage="First Name">
                                            {
                                                placeholder =>
                                                    <FormControl sx={{ width: '49%' }}>
                                                        <TextField
                                                            sx={{
                                                              '& .MuiInputBase-input': {
                                                                height: 'auto'
                                                              }
                                                            }}
                                                            onChange={handleChange}
                                                            placeholder={`${placeholder}`}
                                                            value={credentials?.first_name}
                                                            type="text   "
                                                            name="first_name"
                                                        />
                                                    </FormControl>
                                            }
                                        </FormattedMessage>
                                        <FormattedMessage id="last-name" defaultMessage="Last Name">
                                            {
                                                placeholder =>
                                                    <FormControl
                                                    sx={{ width: '49%' }}>
                                                        <TextField
                                                            sx={{
                                                              '&.MuiInput-input': {
                                                                color: 'red'
                                                              }
                                                            }}
                                                            placeholder={`${placeholder}`}
                                                            onChange={handleChange}
                                                            value={credentials?.last_name}
                                                            type="text"
                                                            name="last_name"
                                                        />
                                                    </FormControl>
                                            }
                                        </FormattedMessage>
                                    </div>
                                    <hr/>
                                    <div className="fields-2-container">
                                        <FormattedMessage id="title" defaultMessage="Title">
                                            {
                                                placeholder =>
                                                    <FormControl>
                                                        <TextField
                                                            fullWidth
                                                            placeholder={`${placeholder}`}
                                                            onChange={handleChange}
                                                            value={credentials?.title}
                                                            type="text"
                                                            name="title"
                                                        />
                                                    </FormControl>
                                            }
                                        </FormattedMessage>
                                        <FormattedMessage id="job_title" defaultMessage="Job title">
                                            {
                                                placeholder =>
                                                    <FormControl>
                                                        <TextField
                                                            fullWidth
                                                            placeholder={`${placeholder}`}
                                                            onChange={handleChange}
                                                            value={credentials?.job_title}
                                                            type="text"
                                                            name="job_title"
                                                        />
                                                    </FormControl>
                                            }
                                        </FormattedMessage>
                                        <FormattedMessage id="city" defaultMessage='City'>
                                            {
                                                placeholder =>
                                                    <FormControl>
                                                        <TextField
                                                            fullWidth
                                                            placeholder={`${placeholder}`}
                                                            onChange={handleChange}
                                                            value={credentials?.city}
                                                            type="text"
                                                            name="city"
                                                        />
                                                    </FormControl>
                                            }
                                        </FormattedMessage>
                                        <FormattedMessage id="company" defaultMessage="Company">
                                            {
                                                placeholder =>
                                                    <FormControl>
                                                        <TextField
                                                            fullWidth
                                                            placeholder={`${placeholder}`}
                                                            onChange={handleChange}
                                                            value={credentials?.institution}
                                                            type="text"
                                                            name="institution"
                                                            variant="outlined"
                                                        />
                                                    </FormControl>
                                            }
                                        </FormattedMessage>

                                        <FormControl>
                                            <Select
                                                defaultValue="none"
                                                fullWidth
                                                onChange={handleChange}
                                                displayEmpty
                                                name="country"
                                                inputProps={true}
                                                value={countryValue}
                                            >
                                                <MenuItem value='country' disabled>
                                                    <FormattedMessage id="country" defaultMessage="Country"/>
                                                </MenuItem>
                                                {countries && countries?.map((countryValueOne) => (
                                                    <MenuItem
                                                        onClick={() => {
                                                          setCountryValue(countryValueOne.value)
                                                        }}
                                                        key={countryValueOne.label}
                                                        value={countryValueOne.value}
                                                    >{countryValueOne.label}</MenuItem>))}
                                            </Select>
                                        </FormControl>

                                    </div>
                                </div>
                                <div className="biography-container">
                                    <FormattedMessage id="your-biography"
                                                      defaultMessage="Your biography">
                                        {
                                            placeholder =>
                                                <FormControl
                                             sx={{ height: '100%' }}
                                                    fullWidth>
                                                    <TextareaAutosize
                                                        fullWidth
                                                        style={{ height: '100%' }}
                                                        placeholder={`${placeholder}`}
                                                        onChange={handleChange}
                                                        aria-hidden="false"
                                                        multiline={'true'}
                                                        minRows={17}
                                                        maxRows={17}
                                                        value={credentials?.biography}
                                                        type="text"
                                                        name="biography"
                                                        variant="standard"
                                                    />
                                                </FormControl>
                                        }

                                    </FormattedMessage>

                                    <GradiantBtn
                                        fullWidth
                                        sx={{
                                          padding: '10px, 16px,  8px, 16px'
                                        }}
                                        type="submit"
                                        variant="contained"
                                    >
                                        {loader
                                          ? <><FormattedMessage id="saving" defaultMessage="Saving"/> ...</>
                                          : <FormattedMessage id="save-changes" defaultMessage="Save changes"/>}
                                    </GradiantBtn>
                                </div>

                            </div>

                            <hr/>
                        </form>
                        <div className="change-password-editing-container">
                            <ChangePassword/>
                        </div>
                    </div>
                </div>
            </div>
    )
  } else {
    return (
            <>
                <SignInPage/>
            </>
    )
  }
}

export default EditingProfilePage
