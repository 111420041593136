import Axios from '../auth'
import setAuthToken from './SetAuthToken'

const BASE_URL = process.env.REACT_APP_BASE_URL
const BASE_URI = process.env.REACT_APP_BASE_URI

export const RefreshToken = () => {
  const refreshToken = localStorage.getItem('rf_token')

  refreshToken !== undefined && Axios.post(`${BASE_URL + BASE_URI}/auth/token/refresh/`, {
    refresh: refreshToken
  })
    .then((response) => {
      localStorage.setItem('token', response.data.access)
      setAuthToken(response.data.access)
    })
    .catch((error) => console.log('errrr', error))
}
