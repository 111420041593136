import React from 'react'

const UserPlaceholder = ({ width = '20', height = '21' }) => {
  return (
        <svg width={width} height={height} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.0001 10.0833C11.841 10.0833 13.3334 8.59094 13.3334 6.74999C13.3334 4.90904 11.841 3.41666 10.0001 3.41666C8.15913 3.41666 6.66675 4.90904 6.66675 6.74999C6.66675 8.59094 8.15913 10.0833 10.0001 10.0833Z" stroke="#A1A9BF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M15.8334 17.5833C15.8334 14.8191 13.2189 12.5833 10.0001 12.5833C6.78122 12.5833 4.16675 14.8191 4.16675 17.5833" stroke="#A1A9BF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

  )
}

export default UserPlaceholder
