import React, { useCallback, useEffect, useState } from 'react'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import { useNavigate } from 'react-router-dom'
import FilterListIcon from '@mui/icons-material/FilterList'
import Popover from '@mui/material/Popover'
import CircularProgress from '@mui/material/CircularProgress'
import { styled } from '@mui/material/styles'
import Badge from '@mui/material/Badge'
import Avatar from '@mui/material/Avatar'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow
} from '@mui/material'

// src
import './Participants.scss'
import { getEventAttendees, getSortedAttendees } from './action'
import SearchIcon from '../../SVG/SearchIcon'
import ChatIcon from '../../SVG/ChatIcon'
import fullScreen from '../../images/fullScreen.svg'
import noAvatar from '../../images/noAvatar.png'
import { FilterMenu } from '../../components/FillterMui/FillterMenu'
import { debounce } from '../../helper/Debounce'
import Presence from '../../components/Presence/Presence'
import { getCountries } from '../EditingProfilePage/action'
import colors from '../../Colors.scss'
import ParticipantsSvg from '../../SVG/ParcipantsSvg'
import Questions from '../../SVG/Questions'
import ContextHelp from '../../components/ContextHelp/ContextHelp'

const fillterMenu = [
  {
    id: 1,
    label: 'first_name',
    name: <FormattedMessage id="first-name" defaultMessage="First name"/>
  },
  {
    id: 2,
    label: 'last_name',
    name: <FormattedMessage id="last-name" defaultMessage="Last name"/>
  },
  {
    id: 3,
    label: 'institution',
    name: <FormattedMessage id="company" defaultMessage="Company"/>
  },
  {
    id: 4,
    label: 'job_title',
    name: <FormattedMessage id="job-title" defaultMessage="Job Title"/>
  }
]

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    top: '25px',
    width: '15px',
    height: '15px',
    borderRadius: '50%',
    boxShadow: `0 0 0 3px ${theme.palette.background.paper}`,
    zIndex: 0
  }
}))

const StyledBadgeOffline = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#C0C0C0FF',
    color: '#44b700',
    top: '25px',
    width: '15px',
    height: '15px',
    borderRadius: '50%',
    boxShadow: `0 0 0 3px ${theme.palette.background.paper}`,
    zIndex: 0
  }
}))

export default function Participants () {
  const navigate = useNavigate()
  const intl = useIntl()

  const url = window.location.pathname.split('/')
  const eventId = url[2]
  const slug = url[3]

  const pages = [10, 50, 100]
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(50)

  const [showMenu] = useState(true)
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(rowsPerPage)
  const [participantsTable, setParticipantsTable] = useState([])
  const [participants, setParticipants] = useState([])
  const [participantsCount, setParticipantsCount] = useState(0)
  const [loader, setLoader] = useState(false)
  const [loaderTable, setLoaderTable] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [anchorElTable, setAnchorElTable] = useState(null)
  const [order, setOrder] = useState('')
  const [orderTable, setOrderTable] = useState('')
  const [orderBy, setOrderBy] = useState('last_name')
  const [show, setShow] = useState(false)
  const [tags, setTags] = useState()
  const [char, setChar] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [searchValueTable, setSearchValueTable] = useState('')
  const [countries, setCountries] = useState([])
  const [scrollParticipant, setScrollParticipant] = useState(false)
  const getEventAttendeesApi = async () => {
    setLoaderTable(true)
    const { items } = await getCountries()
    setCountries(items)
    const response = await getEventAttendees(eventId, offset, limit)
    if (response === undefined) {
      // await RefreshToken();
      getEventAttendeesApi()
    } else {
      setParticipantsCount(response?.data.meta.total_count)
      const notDublicatePerson = Array.from(new Set([...participants, ...response.data.items]))
      setParticipants(notDublicatePerson)
    }
    ;
    setLoaderTable(false)
  }
  const [showModalHelper, setShowModalHelper] = useState({
    open: false,
    event: {}
  })
  useEffect(() => {
    getEventAttendeesApi()
  }, [])

  const handleScroll = (e) => {
    const height = e.target.scrollHeight
    const top = e.target.scrollTop
    const offset_height = e.target.offsetHeight
    const scroll_bar_height = height - (height - offset_height)
    const scroll_bottom = Math.floor(height - (scroll_bar_height + top))
    if (scroll_bottom <= 2 && !(participants.length >= participantsCount) && !loader) {
      setTimeout(() => {
        setOffset(offset + 50)
        setScrollParticipant(!scrollParticipant)
      }, 500)
    }
  }

  const getSortedAttendeesAPI = async (sortBy, refresh = false, offsetSearch = offset) => {
    if (refresh) {
      setLoaderTable(true)
    }
    const response = await getSortedAttendees(eventId, sortBy, order, searchValue, limit, offsetSearch)
    if (response === undefined) {
      getSortedAttendeesAPI(sortBy)
    } else {
      setParticipantsCount(response?.data?.meta?.total_count)
      const notDublicatePerson = Array.from(new Set([...participants, ...response.data.items]))
      const uniqueObjArray = [
        ...new Map(notDublicatePerson.map((item) => [item.people.id, item])).values()
      ]
      if (refresh) {
        setParticipants(response.data.items)
        setOffset(0)
        setLoaderTable(false)
      } else {
        setParticipants(uniqueObjArray)
      }
    }
  }
  const getSortedAttendeesAPITable = async (sortBy) => {
    setLoaderTable(true)
    const response = await getSortedAttendees(eventId, sortBy, orderTable, searchValueTable, limit, offset)
    if (response === undefined) {
      // await RefreshToken();
      getSortedAttendeesAPITable(sortBy)
    } else {
      setParticipantsCount(response?.data?.meta?.total_count)
      const notDublicatePerson = Array.from(new Set([...response.data.items]))
      setParticipantsTable(notDublicatePerson)
    }
    setLoaderTable(false)
  }

  const handleFilterMenu = (e) => {
    handleSortRequest(e.label)
  }
  const handleFilterMenuTable = (e) => {
    handleSortRequestTable(e.label)
  }

  const updateSearchValue = useCallback(
    debounce((value) => {
      if (value.type === 'table') {
        setSearchValueTable(value.value)
        setOffset(0)
        setPage(0)
      } else {
        setSearchValue(value.value)
        setParticipants([])
        setOffset(0)
        setPage(0)
      }
    }, 1000)
    , [])

  const handleSearch = async (e) => {
    setChar(e.target.value)
    updateSearchValue({ value: e.target.value, type: 'notTable' })
  }
  const handleSearchTable = async (e) => {
    setChar(e.target.value)
    updateSearchValue({ value: e.target.value, type: 'table' })
  }

  const handleSortRequest = (cellId) => {
    const isAsc = orderBy === cellId && order === ''
    setOrder(isAsc ? '-' : '')
    setOrderBy(cellId)
    setOrderTable(true)
    getSortedAttendeesAPI(cellId, true, 0).finally(setOrderTable(false))
  }
  const handleSortRequestTable = (cellId) => {
    const isAsc = orderBy === cellId && orderTable === ''
    setOrderTable(isAsc ? '-' : '')
    setOrderBy(cellId)
    getSortedAttendeesAPITable(cellId)
  }

  useEffect(() => {
    setLoader(true)
    getSortedAttendeesAPI(orderBy).finally(() => {
      setLoader(false)
    })
  }, [searchValue, scrollParticipant])

  useEffect(() => {
    getSortedAttendeesAPI(orderBy, true, 0)
  }, [order])

  useEffect(() => {
    getSortedAttendeesAPITable(orderBy)
  }, [page, limit, searchValueTable, orderTable])

  const headCellFull = [
    {
      id: 'avatar',
      label: ''
    },
    {
      id: 'first_name',
      label: <FormattedMessage id="first-name" defaultMessage="First name"/>
    },
    {
      id: 'last_name',
      label: <FormattedMessage id="last-name" defaultMessage="Last name"/>
    },
    {
      id: 'company',
      label: <FormattedMessage id="company" defaultMessage="Company"/>

    },
    {
      id: 'title',
      label: <FormattedMessage id="job-title" defaultMessage="Job Title"/>
    },
    {
      id: 'attendee_type',
      label: <FormattedMessage id="attendee_type" defaultMessage="Teilnahme"/>
    },
    {
      id: 'chat',
      label: <FormattedMessage id="chat" defaultMessage="Chat"/>

    }
    // {
    //     id: "videoChat",
    //     label: <FormattedMessage id="video-chat" defaultMessage="Video chat"/>
    //     intl.formatMessage({
    //                 defaultMessage:"Video chat",
    //                 id:"video-chat"
    //             })
    // },
  ]
  const handleChangePage = (event, newPage) => {
    const arrow = event.target.dataset.testid
    if (arrow === 'KeyboardArrowRightIcon') {
      setLimit(rowsPerPage)
      setPage(newPage)

      setOffset(offset + rowsPerPage)
    } else {
      if (arrow && arrow === 'KeyboardArrowLeftIcon') {
        setLimit(rowsPerPage)
        setOffset(offset - rowsPerPage)
        setPage(newPage)
      }
    }
  }
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setLimit(parseInt(event.target.value, 10))
    setOffset(0)
    setPage(0)
  }
  const recordsAfterPagingAndSorting = () => {
    return participantsTable
  }
  const TableRender = ({ headCells, show }) => {
    return (
            <TableContainer component={Paper}
                            align='center'
                            sx={{
                              border: 'none',
                              minHeight: '582px',
                              height: 'auto',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'space-between'
                            }}>
                {
                    !loaderTable

                      ? <Table

                        >
                            <TableBody
                            >
                                {recordsAfterPagingAndSorting().map((row) => {
                                  const countryUser = countries?.find((country) => {
                                    if (country?.value === row?.people?.country) {
                                      return country
                                    }
                                  })
                                  return (
                                        <TableRow
                                            key={row?.people?.sendbird_user_id}
                                            sx={{
                                              '&:last-child td, &:last-child th': { border: 0 }
                                            }}
                                        >
                                            <TableCell
                                                sx={{
                                                  width: 'fit-content',
                                                  minWidth: '350px',
                                                  display: 'flex',
                                                  alignItems: 'center',
                                                  gap: '8px'
                                                }}
                                            >
                                                <div

                                                    onClick={() =>
                                                      navigate(
                                                            `/events/${eventId}/${slug}/participants/profile/`,
                                                            {
                                                              state: row?.people?.id
                                                            }
                                                      )
                                                    }
                                                    className='avatar-participants cursor-pointer'
                                                >
                                                    <img
                                                        style={{
                                                          width: '60px',
                                                          height: '60px',
                                                          borderRadius: '50%',
                                                          position: 'relative'
                                                        }}
                                                        src={row?.people?.avatar?.download_url ? row?.people?.avatar?.download_url : noAvatar}
                                                        alt={row?.people?.full_name}/>
                                                    {row?.people?.status_online === 'ONLINE'
                                                      ? <p className="online-circle"
                                                        />
                                                      : <p className="offline-circle"
                                                        />}
                                                </div>
                                                <div className="table-participant-full-name-presence-container">

                                                <div className="table-participant-full-name">
                                                    <div>
                                                        {row?.people?.first_name}{' '}
                                                        {row?.people?.last_name}
                                                    </div>
                                                    <div>
                                                         <span>{countryUser?.label && countryUser?.label}</span>
                                                    </div>
                                                </div>
                                                <div className="table-participant-presence">
                                                    <Presence status={row?.people?.attendee_type}/>
                                                </div>
                                                </div>

                                            </TableCell>
                                            {/* <TableCell> */}
                                            {/*    {row?.people?.institution ? row?.people?.institution : ''} */}
                                            {/* </TableCell> */}
                                            {show && (
                                                <>
                                                    <TableCell
                                                    sx={{
                                                      width: '100%'
                                                    }}
                                                    >
                                                        <div className="table-participant-job">
                                                        <span style={{ color: ' rgba(40, 40, 40, 0.5)' }} >{row?.people?.job_title ? row?.people?.job_title : ''}</span>
                                                        <span style={{ color: ' rgba(40, 40, 40, 0.5)' }} >{row?.people?.institution && row?.people?.job_title ? ' at' : ''}</span>
                                                            <strong> {row?.people?.institution && row?.people?.institution }</strong>
                                                    </div>
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{
                                                          paddingRight: '45px'
                                                        }}>
                                                        <div
                                                            onClick={() =>
                                                              navigate(`/events/${eventId}/${slug}/participants/chat/${row?.people?.id}`)
                                                            }
                                                            className="pointer"

                                                        >
                                                            <ChatIcon/>
                                                        </div>
                                                    </TableCell>
                                                    {/* <TableCell> */}
                                                    {/*    <div */}
                                                    {/*        className="pointer"> */}

                                                    {/*        <Camera/> */}
                                                    {/*    </div> */}
                                                    {/* </TableCell> */}
                                                </>

                                            )}
                                        </TableRow>
                                  )
                                })}
                            </TableBody>

                        </Table>
                      : <CircularProgress className="circularProgress"/>
                }
                <TablePagination
                    page={page}
                    rowsPerPageOptions={pages}
                    rowsPerPage={rowsPerPage}
                    count={participantsCount}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>
    )
  }
  return (

        <>
            <Dialog
                open={show}
                onClose={() => {
                  setShow(!show)
                }}
                maxWidth="80%"
                sx={{
                  height: '80vh',
                  margin: 'auto',
                  width: '100vw'

                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title"
                             style={{
                               display: 'flex',
                               justifyContent: 'left',
                               alignItems: 'center',
                               background: ' rgba(40, 40, 40, 0.08)'
                             }}
                >
                    <p style={{ paddingTop: '8px' }}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M9.16055 10.87C9.06055 10.86 8.94055 10.86 8.83055 10.87C6.45055 10.79 4.56055 8.84 4.56055 6.44C4.56055 3.99 6.54055 2 9.00055 2C11.4505 2 13.4405 3.99 13.4405 6.44C13.4305 8.84 11.5405 10.79 9.16055 10.87Z"
                                stroke="#4C6FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            <path
                                d="M16.4093 4C18.3493 4 19.9093 5.57 19.9093 7.5C19.9093 9.39 18.4093 10.93 16.5393 11C16.4593 10.99 16.3693 10.99 16.2793 11"
                                stroke="#4C6FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            <path
                                d="M4.1607 14.56C1.7407 16.18 1.7407 18.82 4.1607 20.43C6.9107 22.27 11.4207 22.27 14.1707 20.43C16.5907 18.81 16.5907 16.17 14.1707 14.56C11.4307 12.73 6.9207 12.73 4.1607 14.56Z"
                                stroke="#4C6FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            <path
                                d="M18.3398 20C19.0598 19.85 19.7398 19.56 20.2998 19.13C21.8598 17.96 21.8598 16.03 20.2998 14.86C19.7498 14.44 19.0798 14.16 18.3698 14"
                                stroke="#4C6FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>

                    </p>
                    <p className="participant-title-table">
                        <FormattedMessage id="participants-list" defaultMessage="Participants list"/></p>

                    {!loaderTable &&
                        <>
                            {participantsCount > 0 && <div className="people-count-participant">
                                {participantsCount > 0 ? participantsCount + ' people' : ''}
                            </div>}
                            <TextField

                                sx={{
                                  '& label': { paddingLeft: (theme) => theme.spacing(1) },
                                  '& input': { paddingLeft: (theme) => theme.spacing(1) },
                                  '& .MuiInputBase-input': {
                                    height: 'auto'
                                  },
                                  '& fieldset': {
                                    paddingLeft: (theme) => theme.spacing(2),
                                    borderRadius: '8px'

                                  }
                                }}
                                style={{
                                  width: '300px',
                                  background: colors.color_cards_background,
                                  borderRadius: '8px'
                                }}
                                size="small"
                                // margin="normal"
                                type="text"
                                value={char}
                                placeholder={`${intl.formatMessage({
                                    defaultMessage: 'Search for',
                                    id: 'search-for'
                                })}`}
                                onChange={(e) => {
                                  handleSearchTable(e)
                                }}

                                InputProps={{
                                  startAdornment: (
                                        <InputAdornment position="end"
                                        >
                                            <SearchIcon/>
                                        </InputAdornment>
                                  )

                                }}
                                variant="outlined"
                            />
                            <FilterMenu anchor={anchorElTable} setAnchor={setAnchorElTable} fillterMenu={fillterMenu}
                                        order={orderTable}
                                        anchorName="simple-popover-table"
                                        setOrder={setOrderTable} handleFilterMenu={handleFilterMenuTable}
                            />
                        </>
                    }

                </DialogTitle>
                <DialogContent

                    sx={{
                      boxSizing: 'border-box',
                      width: '1000px',
                      padding: '0'
                    }}
                >
                    <TableRender headCells={headCellFull} show={true}/>
                </DialogContent>
                <Button sx={{
                  position: 'absolute',
                  top: '16px',
                  right: '30px',
                  ' &:hover': {
                    background: 'none'
                  }

                }}
                        onClick={() => {
                          setShow(!show)
                          setChar('')
                        }}>
                    <svg className="cross-btn" width="36" height="36" viewBox="0 0 36 36" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <circle cx="18" cy="18" r="18" fill={colors.color_black} fillOpacity="0.08"></circle>
                        <path
                            d="M25 12.415L23.585 11L18 16.585L12.415 11L11 12.415L16.585 18L11 23.585L12.415 25L18 19.415L23.585 25L25 23.585L19.415 18L25 12.415Z"
                            fill={colors.color_black}></path>
                    </svg>
                </Button>
            </Dialog>
            <div className="participants-main">
                {showMenu && (
                    <div className="participants-inside">
                        <div className="title-main">
                            <div className="title">
                              <ParticipantsSvg width='30' height='30'/><p><FormattedMessage id="participants" defaultMessage="Participants"/>&nbsp;
                            <span
                        onClick={(e) => {
                          setShowModalHelper({
                            open: !showModalHelper.open,
                            event: e.currentTarget
                          })
                        }}>
                    <Questions/>
                    {showModalHelper && <ContextHelp helpText={{ id: 2, what: 'All participants of the congress are listed with information on their status (on-site or online). This list allows you to contact anyone directly.' }} open={showModalHelper.open} anchorEl={showModalHelper.event} setShowModalHelper={setShowModalHelper}/>}
                  </span>
                            </p>
                            </div>
                        </div>
                        {
                            !loader &&
                            <div className="search">

                                <FormattedMessage id="search-participants" defaultMessage="Search Participants">
                                    {
                                        placeholder =>
                                            <TextField
                                                sx={{
                                                  '& label': { paddingLeft: (theme) => theme.spacing(1) },
                                                  '& input': {
                                                    paddingLeft: (theme) => theme.spacing(1),
                                                    zIndex: '2',
                                                    fontFamily: 'Inter',
                                                    fontWeight: '500',
                                                    fontSize: '14px',
                                                    lineHeight: '17px'

                                                  },
                                                  '& fieldset': {
                                                    paddingLeft: (theme) => theme.spacing(2),
                                                    borderRadius: '30px',
                                                    bgcolor: colors.color_cards_background

                                                  },
                                                  width: '100%'
                                                }}
                                                size="small"
                                                margin="normal"
                                                type="text"
                                                value={char}
                                                placeholder={`${placeholder}`}
                                                onChange={(e) => {
                                                  handleSearch(e)
                                                }}

                                                InputProps={{
                                                  startAdornment: (
                                                        <InputAdornment position="end" sx={{ zIndex: '2' }}>
                                                            <SearchIcon/>
                                                        </InputAdornment>
                                                  ),
                                                  endAdornment: (
                                                      <InputAdornment position="end" sx={{ zIndex: '2' }}>
                                                      <span className='participants-helper-mobile'
                                                            onClick={(e) => {
                                                              setShowModalHelper({
                                                                open: !showModalHelper.open,
                                                                event: e.currentTarget
                                                              })
                                                            }}>
                                                       <Questions height='25' width='25' color={colors.color_grey_shade}/>
                                                        {showModalHelper && <ContextHelp helpText={{ id: 2, what: 'All participants of the congress are listed with information on their status (on-site or online). This list allows you to contact anyone directly.' }} open={showModalHelper.open} anchorEl={showModalHelper.event} setShowModalHelper={setShowModalHelper}/>}
                                                       </span>
                                                      </InputAdornment>

                                                  )

                                                }}
                                                variant="outlined"
                                            />
                                    }
                                </FormattedMessage>

                                <Popover
                                    id={anchorEl ? 'simple-popover' : undefined}
                                    open={Boolean(anchorEl)}
                                    anchorEl={anchorEl}
                                    onClose={() => setAnchorEl(null)}
                                    anchorOrigin={{
                                      vertical: 'bottom',
                                      horizontal: 'left'
                                    }}
                                >

                                    <p className="filter-menu-head"><FormattedMessage id="sort-by" defaultMessage="Sort by"/></p>
                                    {fillterMenu.map((menu, index) => (
                                        <p key={index}
                                           className="filter-menu"
                                           onClick={() => {
                                             handleFilterMenu(menu)
                                             setAnchorEl(null)
                                           }}
                                        >
                                            {menu.name}
                                        </p>
                                    ))}

                                </Popover>

                                <div className="participants-filter"
                                     style={{
                                       marginTop: '6px'
                                     }}>
                                    <FilterListIcon
                                        onClick={(e) => setAnchorEl(e.currentTarget)}
                                        fontSize="medium"
                                    />
                                    {
                                        order === ''
                                          ? <ArrowDownwardIcon
                                                onClick={() => {
                                                  setOrder('-')
                                                }}/>
                                          : <ArrowUpwardIcon
                                                onClick={() => {
                                                  setOrder('')
                                                }}/>
                                    }
                                </div>

                                <img
                                    src={fullScreen}
                                    className="fullScreen-icon"
                                    onClick={() => {
                                      setShow(!show)
                                    }}
                                />

                            </div>
                        }
                      { loaderTable ? <CircularProgress className='circularProgress'/> : <div
                          className="participants-cards-main"
                          onScroll={(e) => handleScroll(e)}
                      >

                        <Paper elevation={0}>
                          <div className="participant-profile-tag">
                            {tags &&
                                tags.map((tag) => (
                                    <div className="tag" key={tag.id}>
                                      <span>{tag.name}</span>
                                      <p
                                          fontSize="small"
                                          onClick={() =>
                                            setTags(tags?.filter((item) => item.id !== tag.id))
                                          }
                                      >close</p>
                                    </div>
                                ))}
                          </div>
                        </Paper>
                        {/* {loader && <CircularProgress className='circularProgress'/>} */}
                        { !show &&
                            participants !== undefined &&
                            participants.length !== 0 && (
                        // <TableRender headCells={headCells} show={false}/>
                          participants.map((participant) => (
                                    <Paper elevation={0}
                                           key={participant?.people?.id}>
                                      <div className="participants-card">
                                        <div
                                            onClick={() =>
                                              navigate(
                                                    `/events/${eventId}/${slug}/participants/profile/`,
                                                    {
                                                      state: participant.people.id
                                                    }
                                              )
                                            }
                                            style={{ cursor: 'pointer' }}
                                        >

                                          <div className="participants-dp">
                                            {participant?.people?.status_online === 'ONLINE'
                                              ? <StyledBadge
                                                    overlap="circular"
                                                    anchorOrigin={{
                                                      vertical: 'bottom',
                                                      horizontal: 'right'
                                                    }}
                                                    variant="dot"

                                                >
                                                  <Avatar
                                                      sx={{
                                                        height: '48px',
                                                        width: '48px'
                                                      }}
                                                      alt="dp"
                                                      src={
                                                        participant?.people?.avatar !== null
                                                          ? participant?.people?.avatar?.download_url
                                                          : ''
                                                      }
                                                  />
                                                </StyledBadge>
                                              : <StyledBadgeOffline
                                                    overlap="circular"
                                                    anchorOrigin={{
                                                      vertical: 'bottom',
                                                      horizontal: 'right'
                                                    }}
                                                    variant="dot"
                                                >
                                                  <Avatar
                                                      sx={{
                                                        height: '48px',
                                                        width: '48px'
                                                      }}
                                                      alt="dp"
                                                      src={
                                                        participant?.people?.avatar !== null
                                                          ? participant?.people?.avatar?.download_url
                                                          : ''
                                                      }
                                                  />
                                                </StyledBadgeOffline>}
                                          </div>
                                          <div className="participants-detail"
                                          >
                                            <p className="user-name">
                                              {participant?.people?.first_name + ' ' + participant?.people?.last_name ?? ''}
                                              <Presence status={participant?.people?.attendee_type}/>
                                            </p>
                                            <p className="user-email">
                                              {/* {participant?.people?.email} */}
                                              {participant?.people?.job_title.trim() !== '' && participant?.people?.institution.trim() !== ''
                                                ? participant?.people?.institution + ', ' + participant?.people?.job_title
                                                : participant?.people?.job_title.trim() !== ''
                                                  ? participant?.people?.job_title
                                                  : participant?.people?.institution.trim() !== ''
                                                    ? participant?.people?.institution
                                                    : ''
                                              }
                                            </p>
                                          </div>
                                        </div>
                                        <div style={{ cursor: 'pointer' }}
                                             onClick={() =>
                                               navigate(`/events/${eventId}/${slug}/participants/chat/${participant.people.id}`)
                                             }
                                        >
                                          <svg
                                              width="24"
                                              height="24"
                                              viewBox="0 0 24 24"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M0 12.018C0 5.69654 5.052 0 12.024 0C18.84 0 24 5.58839 24 11.982C24 19.397 17.952 24 12 24C10.032 24 7.848 23.4712 6.096 22.4376C5.484 22.0651 4.968 21.7886 4.308 22.005L1.884 22.7261C1.272 22.9183 0.72 22.4376 0.9 21.7886L1.704 19.0967C1.836 18.7241 1.812 18.3275 1.62 18.015C0.588 16.1161 0 14.037 0 12.018ZM10.44 12.018C10.44 12.8713 11.124 13.5563 11.976 13.5684C12.828 13.5684 13.512 12.8713 13.512 12.03C13.512 11.1768 12.828 10.4917 11.976 10.4917C11.136 10.4797 10.44 11.1768 10.44 12.018ZM15.972 12.03C15.972 12.8713 16.656 13.5684 17.508 13.5684C18.36 13.5684 19.044 12.8713 19.044 12.03C19.044 11.1768 18.36 10.4917 17.508 10.4917C16.656 10.4917 15.972 11.1768 15.972 12.03ZM6.444 13.5684C5.604 13.5684 4.908 12.8713 4.908 12.03C4.908 11.1768 5.592 10.4917 6.444 10.4917C7.296 10.4917 7.98 11.1768 7.98 12.03C7.98 12.8713 7.296 13.5563 6.444 13.5684Z"
                                                fill={colors.icon_color_sidebar}
                                            />
                                          </svg>
                                        </div>
                                      </div>
                                    </Paper>

                          )))}
                        {loader ? <CircularProgress /> : ''}
                      </div>}

                    </div>
                )}
            </div>
        </>
  )
}
