import React, { useEffect, useMemo, useRef, useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { FormattedMessage } from 'react-intl'
import parse from 'html-react-parser'
import ReactPlayer from 'react-player'
/* eslint-disable  no-unused-vars */
// src
import './Layout.scss'
import { EventStream } from './action'
import GetLivePoll from './GetLivePoll'
import SideBar from '../SideBar'
import Header from '../Header'
import Sponsor from '../Sponsor/Sponsor'
import PostersDetails from '../../Pages/Posters/PostersDetails/PostersDetails'
import IsOnlineAgenda from '../../SVG/isOnlineAgenda'
import colors from '../../Colors.scss'
import EventTitle from './EventTitle/EventTitle'
import { GetAgendaFilter, GetAgendDays, GetEventAgenda } from '../../Pages/EventAgendaPage/action'
import { isToDay } from '../Days/Days'

const Layout = ({ children, refreshEvent, setSendBird, refreshNearEvent, setRefreshEvent }) => {
  const urlHref = window.location.pathname.split('/')
  const eventId = urlHref[2]
  const slug = urlHref[3]
  const login = localStorage.getItem('token') !== null
  const IdEventDescName = localStorage.getItem('IdEventDescName')
  const IdEventDesc = localStorage.getItem('IdEventDesc')

  const url1 = window.location.pathname.split('/')

  const speakerView = process.env.REACT_APP_SPEAKER_VIEW

  const nameAgenda = url1[url1.length - 2]
  const [url, setUrl] = useState('/')
  const [stream, setStream] = useState([])
  const [loader, setLoader] = useState(true)
  const [showMenu, SetShowMenu] = useState(true)

  const [orientation, setOrientation] = useState(window?.orientation)
  const [openModal, setOpenModal] = useState(false)
  const [speakers, setSpeakers] = useState(null)
  const [videoUrlHls, setVideoUrlHls] = useState('')
  const [videoUrlEmbed, setVideoUrlEmbed] = useState('')
  const [videoTitle, setVideoTitle] = useState('')
  const eventIdDesc = url1.slice(-3)[0]

  const EventStreamAPI = async () => {
    setLoader(true)
    if (Number.isInteger(parseInt(eventId))) {
      const response = await EventStream(eventId)
      if (response === undefined) {
        EventStreamAPI()
      } else setStream(response)
      setLoader(false)
    }
  }

  setInterval(() => {
    setUrl(window.location.pathname)
    clearInterval()
  }, 500)

  function handleChange (newValue) {
    SetShowMenu(newValue)
  }
  useEffect(() => {
    window.addEventListener('orientationchange', () => {
      if (window.outerHeight > window.outerWidth) {
        return setTimeout(() => {
          setOrientation(90)
          setOpenModal(true)
        })
      } else {
        return setTimeout(() => {
          setOrientation(0)
          setOpenModal(false)
        })
      }
    })
  }, [])

  const [height, setHeight] = useState(0)
  const aspectRatioRef = useRef()

  window.addEventListener('resize', () => {
    setTimeout(() => {
      setHeight(aspectRatioRef?.current.clientWidth / (320 / 103))
    }, 1000)
  })

  useEffect(() => {
    setTimeout(() => {
      setHeight(aspectRatioRef?.current?.clientWidth / (320 / 103))
    }, 500)
  }, [showMenu])

  useEffect(() => {
    // const speakerMain = localStorage.getItem('speakerMain')
    // console.log({ speakerMain })
    if (url.includes('video')) {
      setVideoUrlHls(localStorage.getItem('videoUrlHls'))
      setVideoUrlEmbed(localStorage.getItem('videoUrlEmbed'))
      setVideoTitle(localStorage.getItem('videoTitle'))
      setSpeakers(JSON.parse(localStorage.getItem('speaker')))
    } else {
      setVideoTitle('')
      setVideoUrlHls('')
      setVideoUrlEmbed('')
      setSpeakers('')
      // if (refreshEvent?.speakers?.length <= 1) {
      //   setSpeakers(refreshEvent?.speakers[0])
      // }
      // else { setSpeakers(speakerMain) }
    }

    if (url.includes('stream')) { SetShowMenu(false) }
  }, [url])

  const [agendaEvents, setAgendaEvents] = useState([])
  const [event, setEvent] = useState(false)
  const [agendaDays, setAgendaDays] = useState([])
  const [nearEvent, setNearEvent] = useState(false)
  const [selectedDate, setSelectedDate] = useState(false)
  const [loaderAgenda, setLoaderAgenda] = useState(false)
  const [loadingAgenda, setLoadingAgenda] = useState(false)
  const checkSelect = localStorage.getItem('checkSelect')
  const updateAgenda = process.env.REACT_APP_TIME_FOR_UPDATE_AGENDA
  const GetAgendaFilterAPI = async (day_id) => {
    const response = await GetAgendaFilter(eventId, day_id)
    if (JSON.stringify(response.data.items) !== JSON.stringify(agendaEvents)) {
      setAgendaEvents(response.data.items)
    }
    for (const nextDay of agendaDays) {
      if (+nextDay.date.split('-').slice(-1)[0] === +new Date().getDate() && +nextDay.id === +day_id && !refreshNearEvent) {
        const value = response.data.items
        const event = value?.find((event) => {
          const year = +event.day.split('-')[0]
          const month = +event.day.split('-')[1]
          const day = +event.day.split('-')[2]
          const starHour = +event.start.split(':')[0]
          const starMinutes = +event.start.split(':')[1]
          const nowTimeSeconds = new Date(new Date())
          const endMinutes = +event.end.split(':')[1]
          const endHour = +event.end.split(':')[0]
          const startTimeSeconds = new Date(year, month - 1, day, starHour, starMinutes)
          const endTimeSeconds = new Date(year, month - 1, day, endHour, endMinutes)

          if (startTimeSeconds - nowTimeSeconds > 0 && day === +new Date().getDate()) {
            return event
          }
          if (nowTimeSeconds >= startTimeSeconds && nowTimeSeconds <= endTimeSeconds) {
            setEvent(event)
            localStorage.setItem('IdEventDescName', event.title)
            localStorage.setItem('IdEventDesc', event.id)
            localStorage.setItem('speakerMain', JSON.stringify(event?.speakers[0]))
          } else {
            if (+IdEventDesc === +event.id) {
              setEvent(false)
              setRefreshEvent(false)
              localStorage.removeItem('IdEventDesc')
              localStorage.removeItem('IdEventDescName')
            }
          }
        })
        if (!event) {
          const response = await GetAgendaFilter(eventId, nextDay.id + 1)
          const value = response.data.items

          if (response.data.items.length === 0) {
            setNearEvent(false)
          } else {
            setNearEvent(value[0])
          }
        } else {
          setNearEvent(event)
        }
      }
    }

    return response.data.items
  }

  useEffect(() => {
    if (!selectedDate && !!agendaDays.length > 0) {
      setLoaderAgenda(true)
      isToDay(agendaDays).then(value => {
        setSelectedDate(value?.toString())
        if (!value) {
          localStorage.setItem('checkSelect', false)
          const day = agendaDays.find(dayValue => {
            if (dayValue.date.split('-').slice(-1)[0] > new Date().getDate() && dayValue.date.split('-').slice(-2)[0] === new Date().getMonth()) {
              return dayValue
            } else {
              if (dayValue.date.split('-').slice(-2)[0] > new Date().getMonth()) {
                return dayValue
              } else return false
            }
          })
          if (day.date.split('-').slice(-1)[0] >= new Date().getDate()) {
            GetAgendaFilterAPI(+day.id).then(value => {
              setNearEvent(value[0])
            })
          }
        }
      }).then(() => setLoaderAgenda(false))
    }
  }, [agendaDays])

  const GetEventAgendaAPI = async () => {
    const response1 = await GetAgendDays(eventId)
    if (response1 !== undefined) {
      setAgendaDays(response1.data.items)
    }
  }

  useEffect(() => {
    if (eventId !== null && login) {
      EventStreamAPI()
      GetEventAgendaAPI()
    }
  }, [eventId])

  useMemo(() => {
    if (!loaderAgenda && !!selectedDate && !loadingAgenda) {
      GetAgendaFilterAPI(selectedDate.toString())
      localStorage.setItem('checkSelect', true)
    }

    if (agendaEvents.length === 0 && !selectedDate && checkSelect === 'false' && !loadingAgenda) {
      setLoadingAgenda(true)
      GetEventAgenda(eventId).then(value => {
        setAgendaEvents(value.data.items)
      }).then(() => setLoadingAgenda(false))
    }
  }, [selectedDate, checkSelect])

  useEffect(() => {
    const intervalId = setInterval(async () => {
      await GetEventAgendaAPI().then(async () => {
        if (!!selectedDate && !loadingAgenda) {
          GetAgendaFilterAPI(selectedDate.toString())
        }
      })
    }, updateAgenda)
    return () => {
      clearInterval(intervalId)
    }
  }, [selectedDate, loadingAgenda])
  if (
    url === '/' ||
        url.includes('password') ||
        url.includes('/sign-in') ||
        url.includes('/sign-up') ||
        url.includes('/user')
  ) {
    return (
            <>
                <Header/>
                <div className='wight-percent-100'>{children}</div>
                {/* <Footer/> */}
            </>
    )
  } else {
    return (
            <>
                <Header setSendBird={setSendBird}/>
                 <div className="layout-main">

                    {loader && <CircularProgress className="circularProgress"/>}
                    {!(url.includes('posters') && url.includes('details')) && !url.includes('/exhibition') && !loader && login && (
                        <div className="stream-container ">
                            <div className="layout-event-stream">
                                {stream?.meta?.total_count !== 0 &&
                                stream?.items[0] !== undefined
                                  ? (
                                    <div className={`container ${stream?.items[0]?.status !== 'PUBLISHED' ? 'bg-color-color_white' : 'bg-color-color_black'}`}
                                         style={{
                                           margin: stream?.items[0]?.status !== 'PUBLISHED' && 0
                                         }}>
                                        <div className="layout-header">
                                            <div className="layout-header-main">
                                                <div className="layout-header-main-left">

                                                    {/* <div className="layout-header-img"> */}
                                                    {/*    <img src={event?.image?.meta?.download_url} */}
                                                    {/*         alt={event?.image?.title}/> */}
                                                    {/*    <div className="layout-header-img-border"/> */}
                                                    {/* </div> */}
                                                    <div className="layout-header-title">
                                                        {/* <h2>{ event.title }</h2> */}
                                                        <div className="speakers-header">
                                                          { !event && refreshNearEvent && !refreshEvent && !url.includes('video') ? <>
                                                            <p>Coming up next : {refreshNearEvent?.start}</p>
                                                            <EventTitle speakers={refreshNearEvent?.speakers?.length === 1 ? refreshNearEvent?.speakers[0] : false} speakerView={speakerView} />
                                                             {' '} <p>{refreshNearEvent?.title}</p>
                                                          </> : !url.includes('video') && nearEvent && !refreshEvent && !event ? <>
                                                            <p>Coming up next : {nearEvent?.start}</p>
                                                            <EventTitle speakers={nearEvent?.speakers?.length === 1 ? nearEvent?.speakers[0] : false} speakerView={speakerView} />
                                                            {' '} <p>{nearEvent?.title}</p>
                                                          </> : ''}
                                                          { !url.includes('video') && refreshEvent ? <><IsOnlineAgenda width='100'/>
                                                            <EventTitle speakers={refreshEvent?.speakers?.length === 1 ? refreshEvent?.speakers[0] : false} speakerView={speakerView}/>
                                                             <p>{refreshEvent.title}</p>
                                                              </>
                                                            : event && !url.includes('video')
                                                              ? <>
                                                                    <IsOnlineAgenda width='100'/>
                                                                    <EventTitle speakers={event?.speakers?.length === 1 ? event?.speakers[0] : false} speakerView={speakerView}/>
                                                                    <p>{videoTitle || (IdEventDescName !== 'false' ? IdEventDescName : '')}</p>
                                                          </> : ''}

                                                           {url.includes('video') && <><IsOnlineAgenda width='100'/><EventTitle speakers={speakers} speakerView={speakerView}/>
                                                            <p>{videoTitle || (IdEventDescName !== 'false' ? IdEventDescName : '')}</p></>}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="layout-header-main-right">
                                                   {/* <div className="layout-header-time"> <Clock timeZone={event?.event_timezone_offset}/></div> */}

                                                    {/* <div className="location-container"> */}
                                                    {/*    <p className="layout-header-location"><Global color={colors.color_background} height="25" width="25"/> {event?.location && event?.location} */}
                                                    {/*        </p> */}
                                                    {/*   <p className="text-color-gray-shade">{ differenceTime < 0 ? differenceTime.toString().split('-')[1] + ' hours ahead of your time' : differenceTime + ' hours behind of your time'} </p> */}
                                                    {/* </div> */}
                                                </div>

                                            </div>

                                        </div>
                                        {
                                          (!url.includes('video') || videoUrlEmbed === 'false' && videoUrlHls === 'false') ? stream?.items[0]?.status === 'PUBLISHED'

                                            ? stream?.items[0].embed_code
                                              ? <div ref={aspectRatioRef} className={`${orientation === 90 && openModal ? 'full-screen' : 'responsive-iframe'}`}>
                                                                    {parse(
                                                                        `<div style="width: 100%; height: ${height}px; aspect-ratio: ${height ? 0 : '320 / 103'};   " ><iframe height="100%" width="100%" className=${orientation !== 90 && !openModal && 'responsive-iframe'} src=${stream?.items[0].embed_code}  allowfullscreen frameborder="0" marginwidth="0" marginheight="0" scrolling="no" allowtransparency="true"></iframe></div>`
                                                                    )}
                                                                </div>

                                              : <div ref={aspectRatioRef} className={` ${orientation === 90 && openModal ? 'full-screen' : 'responsive-iframe'}`}>
                                                                  <ReactPlayer
                                                                      style={{ aspectRatio: height ? 0 : '320 / 103' }}
                                                                      url={stream?.items[0]?.hls_url}
                                                                      controls={true}
                                                                      width="100%"
                                                                      height={window.outerWidth < 1024 ? height : '100%'}
                                                                  />
                                                                </div>

                                            : <div className="responsive-iframe">
                                                    {/* '320/103' */}
                                                     <div ref={aspectRatioRef} style={{ width: '100%', height: `${height}px`, aspectRatio: height ? 0 : '320 / 103' }} >
                                                        <ReactPlayer
                                                                  url={'https://vod.upstream-cloud.ch/51cae53c-fc43-4321-9faa-6c1115d03672/hls/63806ecad645094bd8c42899/63806ecad645094bd8c42899.m3u8'}
                                                                  controls={true}
                                                                  width="100%"
                                                                  height="100%"
                                                                  light={'/poster_001.png'}
                                                                  playing={true}
                                                              />
                                                       {/* </span> */}</div>
                                              <span className="layout-not-authorized">
                                                {parse(stream?.items[0]?.status_message)}</span>
                                                    </div>

                                            : videoUrlEmbed

                                              ? <div ref={aspectRatioRef} className={` ${orientation === 90 && openModal ? 'full-screen' : 'responsive-iframe'}`} >
                                        {parse(
                                          `<div style="width: 100%; height: ${height}px; aspect-ratio: ${height ? 0 : '320 / 103'};   " ><iframe height="100%"  width="100%" className=${orientation !== 90 && !openModal && 'responsive-iframe'} src=${videoUrlEmbed} allowfullscreen frameborder="0" marginwidth="0" marginheight="0" scrolling="no" allowtransparency="true"></iframe></div>`
                                        )}
                                          </div>

                                              : <div ref={aspectRatioRef} className={` ${orientation === 90 && openModal ? 'full-screen' : 'responsive-iframe'}`}>
                                                    <ReactPlayer
                                                        style={{ aspectRatio: '320 / 103', width: '100%' }}
                                                        url={videoUrlHls}
                                                        controls={true}
                                                        width="100%"
                                                        height={height}
                                                    />
                                                  </div>
                                        }
                                    </div>
                                    )
                                  : (
                                    <div className="no-video-container">
                                        <div className="content">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 32 32"
                                            >
                                                <g data-name="Layer 57">
                                                    <path
                                                        d="M16,31A15,15,0,1,1,31,16,15,15,0,0,1,16,31ZM16,3A13,13,0,1,0,29,16,13,13,0,0,0,16,3Z"/>
                                                    <path
                                                        d="M16 24a2 2 0 1 1 2-2A2 2 0 0 1 16 24zm0-2zM16 18a1 1 0 0 1-1-1V8a1 1 0 0 1 2 0v9A1 1 0 0 1 16 18z"/>
                                                </g>
                                            </svg>
                                            <p><FormattedMessage id="video-not-available"
                                                                 defaultMessage="This video is currently not available."/>
                                            </p>
                                        </div>
                                    </div>
                                    )}
                            </div>
                        </div>
                    )}
                    {(url.includes('posters') && url.includes('details')) && (
                        <PostersDetails/>
                    )}
                    <div
                        className={
                      `mobile-layout
                       ${showMenu
                          ? url.includes('/exhibition')
                              ? 'wight-percent-100'
                              : 'wight-percent-30'
                          : 'wight-percent-3'} ${showMenu ? 'minWight-30' : 'minWight-fitContent'}`}
                    >
                        {showMenu && (
                            <>
                                <div className="wrap-add-with-child">
                                    <div className={`layout-children ${url.includes('/exhibition') ? 'h100' : ''}`}

                                    >
                                        <GetLivePoll/>
                                        {url === `/events/${eventId}/${slug}/agenda/${nameAgenda}/` || parseInt(eventIdDesc) || url.includes('/exhibition')
                                          ? ''
                                          // : <CrossButtonSVG width='30' height='30' onClick={() => SetShowMenu(false)}/>
                                          : <svg
                                                className="cross-btn"
                                                onClick={() => SetShowMenu(false)}
                                                width="30"
                                                height="30"
                                                viewBox="0 0 36 36"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <circle
                                                    cx="18"
                                                    cy="18"
                                                    r="18"
                                                    fill={colors.color_white}
                                                    // fillOpacity="0.08"
                                                />
                                                <path
                                                    d="M25 12.415L23.585 11L18 16.585L12.415 11L11 12.415L16.585 18L11 23.585L12.415 25L18 19.415L23.585 25L25 23.585L19.415 18L25 12.415Z"
                                                    fill={colors.color_blue}
                                                />
                                            </svg>
                                        }

                                        {children}

                                    </div>
                                    {
                                        !url.includes('/exhibition') && <Sponsor refreshEvent={refreshEvent}/>
                                    }
                                </div>
                            </>
                        )}

                        {login && (
                            <SideBar
                                selectedprop={localStorage.getItem('menu')}
                                onChange={handleChange}
                                showMenu={showMenu}
                            />
                        )}
                    </div>
                </div>
                {/* <div className="description-container"> */}
                {/*    <StreamDescription desp={eventDesp}/> */}
                {/* </div> */}
                {/* <Footer/> */}
            </>
    )
  }
}

export default Layout
